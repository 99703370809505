import { styled } from "styled-components";
import { FONT_WHITE } from "../../../App.style";

export const DreamDetailedContainer = styled.div`
  padding-top: 80px;
  width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;

  padding: 80px 15px;

  @media (min-width: 350px) {
    padding: 80px 20px;
  }

  @media (min-width: 768px) {
    padding: 80px 30px;
  }
`;

export const DreamDetailedTitleContainer = styled.div`
  text-align: center;

  max-width: 500px;

  margin-top: 30px;
  @media (min-width: 768px) {
    max-width: 800px;
    margin-top: 50px;
  }

  font-family: "Roboto", sans-serif;
  font-size: clamp(25px, 4vw + 1rem, 38px);
  font-style: normal;
  font-weight: 500;

  letter-spacing: 2px;

  color: ${FONT_WHITE};
`;

export const DreamDetailedSubtitleContainer = styled.div`
  text-align: center;
  max-width: 800px;

  margin-top: 30px;
  @media (min-width: 768px) {
    margin-top: 20px;
  }

  font-family: "Roboto", sans-serif;
  font-size: clamp(18px, 4vw - 1rem, 24px);
  font-style: normal;
  font-weight: 300;

  letter-spacing: 2.16px;

  color: ${FONT_WHITE};

  letter-spacing: 2px;

  color: ${FONT_WHITE};
`;

export const DreamDetailedDescriptionContainer = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: clamp(25px, 4vw + 1rem, 38px);
  font-style: normal;
  font-weight: 500;
`;

export const DreamDetailedContentContainer = styled.div`
  background: black;
  border-radius: 20px;
  opacity: 0.8;
  text-align: center;
  position: relative;
  z-index: 1;

  margin-top: 15px;
  margin-bottom: 20px;

  padding: 20px 0px;

  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: 300px) {
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 20px 0px;
    max-width: 500px;
  }

  @media (min-width: 768px) {
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 30px 0px;

    max-width: 800px;
  }

  @media (min-width: 1000px) {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 40px 30px;
    gap: 50px;
  }

  span {
    font-family: "Noto Sans", cursive;
    font-style: italic;

    font-weight: 300;

    color: #fff;

    letter-spacing: 1.5px;
    line-height: 20px;

    font-size: 15px;

    text-shadow: 0px 8px 24px #000;

    @media (min-width: 768px) {
      font-size: 17px;
    }
  }

  p {
    font-size: clamp(18px, 4vw - 1rem, 22px);

    font-weight: 300;

    text-shadow: 0px 8px 24px #000;
    color: #f7f7f7;

    @media (min-width: 768px) {
      /* font-size: 23px; */
    }
  }
`;

export const DreamDetailedButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`;
