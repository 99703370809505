import { useEffect, useState } from "react";
import { db } from "../../../../config/firebase";
import { doc, getDoc } from "@firebase/firestore";

export interface ServiceInfo {
  running: boolean;
  text: string;
  time: string;
}

function useCheckService() {
  const [service, setSerivce] = useState<ServiceInfo | null>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    async function fetchService() {
      setLoading(true);
      setError(false);

      try {
        const fortuneRef = doc(db, "Mysticer", "SERVICE");
        const docSnapshot = await getDoc(fortuneRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data() as ServiceInfo;

          setSerivce(data);
        } else {
          setSerivce(null);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    fetchService();
  }, []);

  return { service, loading, error };
}

export default useCheckService;
