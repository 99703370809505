import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { H1 } from "../../../../App.style";
import { useTranslation } from "react-i18next";

const backgroundPan = keyframes`
  from {
    background-position: 0% center;
  }
  to {
    background-position: -200% center;
  }
`;

const scaleUpAndDown = keyframes`
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`;

const MagicSpan = styled.span`
  display: inline-block;
  position: relative;
`;

const MagicStar = styled.span<{
  $size: string;
  $left: number;
  $top: number;
}>`
  animation: ${scaleUpAndDown} 700ms ease infinite;
  position: absolute;
  left: ${(props) => props.$left}%;
  top: ${(props) => props.$top}%;
  width: ${(props) => props.$size};
  height: ${(props) => props.$size};
`;

const SvgStar = styled.svg`
  animation: ${rotate} 1000ms linear infinite;
  display: block;
  opacity: 0.7;
  path {
    fill: rgb(103, 58, 183); // Set your desired color here
  }
`;

const MagicText = styled.span`
  animation: ${backgroundPan} 3s linear infinite;
  background: linear-gradient(
    to right,
    rgb(123, 31, 162),
    rgb(103, 58, 183),
    rgb(244, 143, 177),
    rgb(123, 31, 162)
  );
  background-size: 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
`;

// React Component
const HeroTitle = () => {
  const { t } = useTranslation();
  const [stars, setStars] = useState([
    { left: 0, top: 0 },
    { left: 0, top: 0 },
    { left: 0, top: 0 },
  ]);

  const animateStar = (index: number) => {
    setStars((stars) =>
      stars.map((star, i) =>
        i === index
          ? {
              ...star,
              left: Math.random() * 100,
              top: Math.random() * 100 - 40,
            }
          : star
      )
    );
  };

  useEffect(() => {
    const intervals = stars.map((_, index) =>
      setInterval(() => animateStar(index), 1500)
    );

    return () => intervals.forEach(clearInterval);
  }, []); // eslint-disable-line

  return (
    <H1>
      {t("homepage.hero.title.part1")} <br />
      <MagicSpan>
        {stars.map((star, index) => (
          <MagicStar
            key={index}
            $size="clamp(20px, 1.5vw, 30px)"
            $left={star.left}
            $top={star.top}
          >
            <SvgStar viewBox="0 0 512 512">
              <path d="M512 255.1c0 11.34-7.406 20.86-18.44 23.64l-171.3 42.78l-42.78 171.1C276.7 504.6 267.2 512 255.9 512s-20.84-7.406-23.62-18.44l-42.66-171.2L18.47 279.6C7.406 276.8 0 267.3 0 255.1c0-11.34 7.406-20.83 18.44-23.61l171.2-42.78l42.78-171.1C235.2 7.406 244.7 0 256 0s20.84 7.406 23.62 18.44l-42.78 171.2l171.2 42.78C504.6 235.2 512 244.6 512 255.1z" />
            </SvgStar>
          </MagicStar>
        ))}
        <MagicText>Mysticer</MagicText>
      </MagicSpan>{" "}
      {t("homepage.hero.title.part2")}
    </H1>
  );
};

export default HeroTitle;
