import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCheckPayment } from "./useCheckPayment";

import { Button, H2, H3 } from "../../../App.style";
import { User } from "firebase/auth";
import {
  WrozbaResultContainer,
  WrozbaResultTitleContainer,
  WrozbaResultContentContainer,
  LoadingSentenceContainer,
  LoadingWord,
  WrozbaErrorContainer,
  Moon,
  Canvas,
  ProgressBarContainer,
  ProgressValue,
  LoadingBottomContainer,
} from "./WrozbaResult.style";
import { useTranslation } from "react-i18next";

interface WrozbaResultProps {
  currentUser: User | null;
}

const WrozbaResult: React.FC<WrozbaResultProps> = ({ currentUser }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { docId } = useParams();

  const { checkPayment, resetResponse, response, error, isLoading } =
    useCheckPayment();

  useEffect(() => {
    if (docId) {
      checkPayment(docId);
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = ""; // Standard for most browsers
      return ""; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // This effect runs once on mount

  useEffect(() => {
    if (response) {
      navigate(`/fortune/${response}`);
      resetResponse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (error && error === "redirect") {
      navigate("/");
      resetResponse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  //loading

  const getRandomSentenceIndex = (currentIndex: number, length: number) => {
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * length);
    } while (newIndex === currentIndex);
    return newIndex;
  };

  const sentences: string[] = [
    t("wrozbaResult.loading.sentences.1"),
    t("wrozbaResult.loading.sentences.2"),
    t("wrozbaResult.loading.sentences.3"),
    t("wrozbaResult.loading.sentences.4"),
    t("wrozbaResult.loading.sentences.5"),
    t("wrozbaResult.loading.sentences.6"),
    t("wrozbaResult.loading.sentences.7"),
    t("wrozbaResult.loading.sentences.8"),
    t("wrozbaResult.loading.sentences.9"),
    t("wrozbaResult.loading.sentences.10"),
    t("wrozbaResult.loading.sentences.11"),
    t("wrozbaResult.loading.sentences.12"),
    t("wrozbaResult.loading.sentences.13"),
    t("wrozbaResult.loading.sentences.14"),
  ];

  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = getRandomSentenceIndex(
        currentSentenceIndex,
        sentences.length
      );
      setCurrentSentenceIndex(newIndex);
    }, 4000); // Change sentence every 3 seconds

    return () => clearInterval(interval);
  }, [currentSentenceIndex, sentences.length]);

  const words = sentences[currentSentenceIndex].split(" ");

  // Display loading status
  if (isLoading) {
    return (
      <WrozbaResultContainer>
        <WrozbaResultTitleContainer>
          <H2>{t("wrozbaResult.loading.title")}</H2>
          <H3 style={{ marginTop: 30 }}>
            {t("wrozbaResult.loading.subtitle")}
          </H3>
        </WrozbaResultTitleContainer>
        <WrozbaResultContentContainer>
          <Canvas>
            <Moon />
          </Canvas>

          <LoadingBottomContainer>
            <LoadingSentenceContainer key={currentSentenceIndex}>
              {words.map((word, index) => (
                <LoadingWord
                  key={`word-${index}`}
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  {word}
                </LoadingWord>
              ))}
            </LoadingSentenceContainer>
            <ProgressBarContainer>
              <ProgressValue />
            </ProgressBarContainer>
          </LoadingBottomContainer>
        </WrozbaResultContentContainer>
      </WrozbaResultContainer>
    );
  }

  // Handle error
  if (error) {
    return (
      <WrozbaResultContainer>
        <WrozbaErrorContainer>
          <H2>{t("wrozbaResult.error.title")}</H2>
          <H3 style={{ marginTop: 10 }}>{t("wrozbaResult.error.subLine1")}</H3>
          <H3>{t("wrozbaResult.error.subLine2")}</H3>
          <H3>{t("wrozbaResult.error.subLine3")}</H3>
        </WrozbaErrorContainer>

        <Button
          style={{ marginTop: 50 }}
          onClick={() => {
            resetResponse();
            navigate("/");
          }}
        >
          {t("wrozbaResult.error.homepage")}
        </Button>
      </WrozbaResultContainer>
    );
  }

  return <>processing</>;
};

export default WrozbaResult;
