import styled from "styled-components";

export const FooterContainer = styled.footer`
  position: relative;
  width: 100%;
  padding: 10px 20px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  max-width: 1000px;
`;

export const FooterLanguageSelectorContainer = styled.div``;

export const FooterPrivacyContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

export const P = styled.p`
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: clamp(14px, 2.5vw, 16px);
  opacity: 0.8;
  color: #fff;
  letter-spacing: 1.5px;
  line-height: 20px;
`;

export const FooterText = styled.a`
  text-decoration: none;
  font-family: "Noto Sans";
  text-align: center;
  font-family: Noto Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 3px;
  color: white;
`;

export const FooterTopContainer = styled.div`
  text-align: center;
`;

export const FooterBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;
