import React from "react";
import { TarotCardsListContainer, TarotCardImage } from "../Tarot.style";
import back_image from "../../../assets/tarot_card_images/tarot_card_back.png";

import { UserTarotFormData } from "../../session/api/userApi";

interface TarotCardsSelectorProps {
  tarot: UserTarotFormData;
  selectedCardIndexes: number[];
  setSelectedCardIndexes: React.Dispatch<React.SetStateAction<number[]>>;
}

const TarotCardsSelector: React.FC<TarotCardsSelectorProps> = ({
  tarot,
  selectedCardIndexes,
  setSelectedCardIndexes,
}) => {
  const cards = new Array(8).fill(null).map((_, i) => ({
    id: i,
    image: back_image, // Replace with path to your card images
  }));

  const handleCardClick = (cardIndex: number) => {
    if (selectedCardIndexes.includes(cardIndex)) {
      // Remove the card from selection
      setSelectedCardIndexes(
        selectedCardIndexes.filter((id) => id !== cardIndex)
      );
    } else if (selectedCardIndexes.length < Number(tarot.cardsCount)) {
      setSelectedCardIndexes([...selectedCardIndexes, cardIndex]);
    }
  };

  return (
    <TarotCardsListContainer>
      {cards.map((card, cardIndex) => (
        <TarotCardImage
          key={card.id}
          src={card.image}
          alt={`Card ${card.id}`}
          $selected={selectedCardIndexes.includes(cardIndex)}
          onClick={() => handleCardClick(cardIndex)}
          $index={cardIndex}
        />
      ))}
    </TarotCardsListContainer>
  );
};

export default TarotCardsSelector;
