import { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  FieldValue,
} from "firebase/firestore";
import { db } from "../../../config/firebase";

export interface ContactRequest {
  id?: string;
  email: string;
  error: boolean;
  title: string;
  message: string;
  created_at?: FieldValue;
  status?: "pending" | "resolved";
  lang: "PL" | "EN";
}

export const useAddContactDocument = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const addContactDocument = async (contactData: ContactRequest) => {
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    const contactRef = collection(db, "Contacts");

    const contactDoc = {
      email: contactData.email,
      title: contactData.title,
      message: contactData.message,
      created_at: serverTimestamp(),
      status: "pending",
      lang: contactData.lang,
      error: contactData.error,
    };

    try {
      const docRef = await addDoc(contactRef, contactDoc);
      setSuccess(docRef.id); // Set success to the new document's ID
    } catch (error) {
      console.error("Error adding new help:", error);
      setError("Failed to add new help"); // Set a user-friendly error message
    } finally {
      setIsLoading(false);
    }
  };

  return { addContactDocument, isLoading, error, success };
};
