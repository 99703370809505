import { EuiDatePicker, EuiSelect } from "@elastic/eui";
import React, { useState } from "react";
import { FortuneRequest } from "../../wrozba/result/useCheckPayment";
import { getAdminFortunes } from "../api/adminApi";
import FortunesTable from "./FortunesTable";
import { Button, H2 } from "../../../App.style";
import { Moment } from "moment";
import { FortunesListContainer } from "../AdminPanel.style";

const FortunesList = () => {
  const [fortunes, setFortunes] = useState<FortuneRequest[]>([]);

  const [selectedStatus, setSelectedStatus] = useState<
    "pending" | "success" | "error"
  >();
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);

  const handleButtonClick = async () => {
    try {
      const date = selectedDate ? selectedDate.toDate() : undefined;
      const fetchedFortunes = await getAdminFortunes(selectedStatus, date);
      setFortunes(fetchedFortunes);
    } catch (error) {
      console.log(error);
      alert("cos nie tak mordo");
    }
  };

  const optionsTexts = ["All", "pending", "success", "error"];
  const selectOptions = optionsTexts.map((text) => {
    return { value: text === "All" ? "" : text, text };
  });

  return (
    <FortunesListContainer>
      <div
        style={{
          display: "flex",
          gap: 15,
          marginBottom: 20,
        }}
      >
        <H2>Status:</H2>

        <div style={{ width: 120 }}>
          <EuiSelect
            value={selectedStatus}
            options={selectOptions}
            onChange={(e) =>
              setSelectedStatus(
                e.target.value !== ""
                  ? (e.target.value as "pending" | "success" | "error")
                  : undefined
              )
            }
          />
        </div>

        <H2>Data:</H2>

        <div style={{ width: 140 }}>
          <EuiDatePicker selected={selectedDate} onChange={setSelectedDate} />
        </div>

        <div style={{ marginLeft: 30 }}>
          <Button onClick={handleButtonClick}>Poka</Button>
        </div>
      </div>

      <FortunesTable fortunesList={fortunes} />
    </FortunesListContainer>
  );
};

export default FortunesList;
