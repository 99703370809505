import { EuiSelect } from "@elastic/eui";
import React, { useState } from "react";
import { getAdminContacts } from "../api/adminApi";
import { Button, H2 } from "../../../App.style";
import { FortunesListContainer } from "../AdminPanel.style";
import { ContactRequest } from "../../contact/useAddContactDocument";
import ContactTable from "./ContactTable";

const ContactList = () => {
  const [contacts, setContacts] = useState<ContactRequest[]>([]);

  const [selectedType, setSelectedType] = useState<
    "message" | "error" | undefined
  >("message");
  const [selectedStatus, setSelectedStatus] = useState<
    "pending" | "resolved" | undefined
  >("pending");

  const handleButtonClick = async () => {
    try {
      // const date = selectedDate ? selectedDate.toDate() : undefined;
      const fetchedContacts = await getAdminContacts(
        selectedType,
        selectedStatus
      );
      setContacts(fetchedContacts);
    } catch (error) {
      console.log(error);
      alert("cos nie tak mordo");
    }
  };

  const statusTexts = ["pending", "resolved", "All"];
  const selectStatusOptions = statusTexts.map((text) => {
    return { value: text === "All" ? "" : text, text };
  });

  const typeText = ["error", "message", "All"];
  const selectTypeOptions = typeText.map((text) => {
    return { value: text === "All" ? "" : text, text };
  });

  return (
    <FortunesListContainer>
      <div
        style={{
          display: "flex",
          gap: 10,
          marginBottom: 20,
        }}
      >
        <H2>Type:</H2>
        <div style={{ width: 150 }}>
          <EuiSelect
            value={selectedType}
            options={selectTypeOptions}
            onChange={(e) =>
              setSelectedType(
                e.target.value !== ""
                  ? (e.target.value as "message" | "error")
                  : undefined
              )
            }
          />
        </div>

        <H2>Status:</H2>
        <div style={{ width: 150 }}>
          <EuiSelect
            value={selectedStatus}
            options={selectStatusOptions}
            onChange={(e) =>
              setSelectedStatus(
                e.target.value !== ""
                  ? (e.target.value as "pending" | "resolved")
                  : undefined
              )
            }
          />
        </div>

        <div style={{ marginLeft: 30 }}>
          <Button onClick={handleButtonClick}>Poka</Button>
        </div>
      </div>

      {/* <ContactList  /> */}
      <ContactTable contactsList={contacts} />
    </FortunesListContainer>
  );
};

export default ContactList;
