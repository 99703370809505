import React, { useEffect } from "react";
import HeroTitle from "./HeroTitle";
import {
  HeroContainer,
  TextSection,
  ImageContainer,
  TitleContainer,
  ButtonsContainer,
} from "./Hero.style";
import heroKula from "../../../../assets/hero-kula-2.png";
import { ButtonEmpty, SuperButton, H4 } from "../../../../App.style";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WrozbaForm from "../../../wrozba/WrozbaForm";
import { User } from "firebase/auth";
import { FirestoreUser } from "../../../session/api/userApi";
import TarotForm from "../../../tarot/TarotForm";
import cardBack from "../../../../assets/tarot_card_images/tarot_card_back.png";
import cardWorld from "../../../../assets/tarot_card_images/the_world.png";
import DreamsForm from "../../../dreams/DreamsForm";
import HeroSelector from "./HeroSelector";

interface HeroProps {
  currentUser: User | null;
  firestoreUser: FirestoreUser | null;
  heroState: "hero" | "selector" | "tarot" | "fortune" | "dreams";
  setHeroState: React.Dispatch<
    React.SetStateAction<"hero" | "selector" | "tarot" | "fortune" | "dreams">
  >;
  openLoginModal: () => void;
}

const Hero: React.FC<HeroProps> = ({
  currentUser,
  firestoreUser,
  heroState,
  setHeroState,
  openLoginModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSelectorClick = () => {
    setHeroState("tarot");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlePrzepowiedniaClick = () => {
    setHeroState("fortune");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleTarotClick = () => {
    setHeroState("tarot");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleDreamsClick = () => {
    setHeroState("dreams");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const closeSelector = () => {
    setHeroState("hero");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const preloadImages = () => {
    const urls = [cardBack, cardWorld];
    urls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  // Preload images on component mount
  useEffect(() => {
    preloadImages();
  }, []);

  const componentsMap = {
    hero: (
      <>
        <TitleContainer>
          <HeroTitle />
        </TitleContainer>
        <TextSection>
          <H4>
            {t("homepage.hero.subtitle.part1")}
            <br /> {t("homepage.hero.subtitle.part2")}
          </H4>
        </TextSection>
        <ButtonsContainer>
          <SuperButton type="button" onClick={handleSelectorClick}>
            {t("homepage.hero.buttonText")}
          </SuperButton>
          <ButtonEmpty type="button" onClick={() => navigate("/#how")}>
            {t("homepage.hero.buttonEmptyText")}
          </ButtonEmpty>
        </ButtonsContainer>

        <ImageContainer>
          <img src={heroKula} alt="mystical orb" />
        </ImageContainer>
      </>
    ),
    selector: (
      <HeroSelector
        handleDreamsClick={handleDreamsClick}
        handlePrzepowiedniaClick={handlePrzepowiedniaClick}
        handleTarotClick={handleTarotClick}
        closeSelector={closeSelector}
      />
    ),
    tarot: (
      <TarotForm
        currentUser={currentUser}
        firestoreUser={firestoreUser}
        openLoginModal={openLoginModal}
      />
    ),
    fortune: (
      <WrozbaForm currentUser={currentUser} firestoreUser={firestoreUser} />
    ),
    dreams: (
      <DreamsForm
        currentUser={currentUser}
        firestoreUser={firestoreUser}
        openLoginModal={openLoginModal}
      />
    ),
  };

  return <HeroContainer>{componentsMap[heroState]}</HeroContainer>;
};

export default Hero;
