import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  NavbarContainer,
  Checkbox,
  HamburgerLines,
  Line,
  NavbarLeftContainer,
  NavbarLink,
  NavbarRightContainer,
  NavbarLogoContainer,
  NavbarLogo,
  NavbarDesktopLinks,
  NavbarMobileContainer,
  NavbarExtendedContainer,
  NavbarDesktopContainer,
} from "./Navbar.style";
import useAuth from "../../../config/useAuth";
import logo from "../../assets/logo.png";
import { ButtonSecondary, SuperButton } from "../../App.style";
import AccountPopover from "./accountPopover/AccountPopover";
import { useTranslation } from "react-i18next";

interface NavbarProps {
  openLoginModal: () => void;
  openSettingsModal: () => void;
  openContactModal: () => void;
}

const Navbar: React.FC<NavbarProps> = ({
  openLoginModal,
  openSettingsModal,
  openContactModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [extendNavbar, setExtendNavbar] = useState<boolean>(false);
  const closeNavbar = () => setExtendNavbar(false);

  const handleExtendNavbar = () => {
    setExtendNavbar((curr) => !curr);
  };

  const handleLogoClick = () => {
    navigate("/#hero");
    extendNavbar && closeNavbar();
  };

  const handleNavigation = (hash: string) => {
    navigate(`/#${hash}`);
  };

  const handleMojeWrozbyClick = () => {
    navigate("/fortunes");
    extendNavbar && closeNavbar();
  };

  const handleSettingsClick = () => {
    openSettingsModal();
    extendNavbar && closeNavbar();
  };

  const handleContactClick = () => {
    openContactModal();
    extendNavbar && closeNavbar();
  };

  const handleLoginClick = () => {
    openLoginModal();
    extendNavbar && closeNavbar();
  };

  const handleHelpClick = () => {
    handleNavigation("help");
    extendNavbar && closeNavbar();
  };

  const handleHowItWorksClick = () => {
    handleNavigation("how");
    extendNavbar && closeNavbar();
  };

  const handleWrozbaClick = () => {
    handleNavigation("selector");
    extendNavbar && closeNavbar();
  };

  //wylaczyc navbar w trakcie wrozby

  return (
    <NavbarContainer>
      <NavbarLeftContainer>
        {/* only on mobiles  */}
        <NavbarMobileContainer onClick={handleExtendNavbar}>
          <Checkbox checked={extendNavbar} readOnly />
          <HamburgerLines>
            <Line $top $open={extendNavbar} />
            <Line $middle $open={extendNavbar} />
            <Line $bottom $open={extendNavbar} />
          </HamburgerLines>
        </NavbarMobileContainer>

        <NavbarDesktopLinks>
          {currentUser ? (
            <NavbarLink onClick={handleMojeWrozbyClick}>
              {t("navbar.myFortunes")}
            </NavbarLink>
          ) : (
            <NavbarLink onClick={handleHowItWorksClick}>
              {t("navbar.howItWorks")}
            </NavbarLink>
          )}

          <NavbarLink onClick={handleHelpClick}>{t("navbar.help")}</NavbarLink>

          <NavbarLink onClick={handleContactClick}>
            {t("navbar.contact")}
          </NavbarLink>

          {/* <div
            onClick={() =>
              i18n.changeLanguage(i18n.language === "pl-PL" ? "en-GB" : "pl-PL")
            }
          >
            Lang: {i18n.language}
          </div> */}
        </NavbarDesktopLinks>
      </NavbarLeftContainer>
      <NavbarRightContainer>
        <SuperButton
          onClick={handleWrozbaClick}
          style={{ padding: "8px 10px" }}
        >
          {t("navbar.fortune")}
        </SuperButton>

        <NavbarDesktopContainer>
          {!currentUser ? (
            <NavbarLink onClick={handleLoginClick}>
              {t("navbar.login")}
            </NavbarLink>
          ) : (
            <AccountPopover
              openSettingsModal={handleSettingsClick}
              currentUser={currentUser}
            />
          )}
        </NavbarDesktopContainer>
      </NavbarRightContainer>
      <NavbarLogoContainer onClick={handleLogoClick}>
        <NavbarLogo src={logo} alt={""} />
      </NavbarLogoContainer>

      {/* mobiles */}

      <NavbarExtendedContainer $isOpen={extendNavbar}>
        {currentUser ? (
          <NavbarLink onClick={handleMojeWrozbyClick}>
            {t("navbar.myFortunes")}
          </NavbarLink>
        ) : (
          <NavbarLink onClick={handleHowItWorksClick}>
            {t("navbar.howItWorks")}
          </NavbarLink>
        )}

        <NavbarLink onClick={handleHelpClick}>{t("navbar.help")}</NavbarLink>

        <NavbarLink onClick={handleContactClick}>
          {t("navbar.contact")}
        </NavbarLink>

        {!currentUser ? (
          <ButtonSecondary onClick={handleLoginClick}>
            {t("navbar.login")}
          </ButtonSecondary>
        ) : (
          <AccountPopover
            openSettingsModal={handleSettingsClick}
            currentUser={currentUser}
          />
        )}
      </NavbarExtendedContainer>
    </NavbarContainer>
  );
};

export default Navbar;
