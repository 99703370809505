import styled from "styled-components";
import { FadeInProps, fadeInAnimation } from "../../Homepage.style";

export const HowItWorksContainer = styled.div`
  width: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const HowItWorksContentContainer = styled.div`
  margin-top: 50px;
  max-width: 800px;
  display: flex;
  flex-direction: column;

  gap: 30px;
`;

export const StepContainer = styled.div<FadeInProps>`
  text-align: left;
  opacity: 0;
  transform: translateY(40px);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, visibility 0.6s;
  ${({ $isVisible }) => $isVisible && fadeInAnimation}
`;

export const StepTitleContainer = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
`;
