import styled, { keyframes } from "styled-components";
import { FONT_WHITE, H4 } from "../../../App.style";

export const WrozbaResultContainer = styled.div`
  width: 100%;
  min-height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1000px;
  text-align: center;

  padding: 80px 15px;

  @media (min-width: 350px) {
    padding: 80px 30px;
  }
`;

export const WrozbaResultTitleContainer = styled.div`
  text-align: center;
  width: 100%;

  margin-top: 20px;
  @media (min-width: 768px) {
    margin-top: 50px;
  }

  color: ${FONT_WHITE};
`;

export const WrozbaResultContentContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  position: relative;
  z-index: 1;

  /* margin-top: 20px; */
  margin-bottom: 20px;
  /* gap: 30px; */

  @media (min-width: 768px) {
    gap: 50px;
    margin-top: 50px;
  }

  @media (min-width: 1000px) {
    gap: 80px;
  }
`;

//loading text animation lol
const fadeIn = keyframes`
  from {
    opacity: 0;
    filter: blur(4px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
`;

export const LoadingWord = styled.span`
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
  animation: ${fadeIn} 1.5s forwards;
  animation-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  margin-right: 10px;
`;

export const LoadingSentenceContainer = styled(H4)`
  text-align: center;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
`;

const animation = keyframes`
  to {
    background-position: right;
  }
`;

// Styled component for the canvas
export const Canvas = styled.div`
  height: 290px;
  width: 290px;

  display: grid;
  place-items: center;
  overflow: hidden;
  background-position: left;
  background-repeat: repeat-y;
  animation: ${animation} 40s linear infinite; /* Adjust the duration as needed */

  @media (min-width: 768px) {
    height: 350px;
    width: 350px;
  }
`;

// Styled component for the moon
export const Moon = styled.div`
  background-color: grey;
  height: 150px; // Adjust according to your design
  width: 150px; // Adjust according to your design
  border-radius: 50%;
  box-shadow: 0px 0px 70px 5px white;
  background-image: url("https://svs.gsfc.nasa.gov/vis/a000000/a004700/a004720/lroc_color_poles_1k.jpg");
  background-size: 430px; // Adjust according to your design
  background-position: left;
  background-repeat: no-repeat;
  animation: ${animation} 20s linear infinite;

  @media (min-width: 768px) {
    height: 200px; // Adjust according to your design
    width: 200px; // Adjust according to your design
  }
`;

//error

export const WrozbaErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 30px;
  max-width: 600px;

  @media (min-width: 768px) {
    gap: 40px;
    max-width: 800px;
  }
`;

//progress bar

export const LoadingBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: -20px;

  @media (min-width: 768px) {
    margin-top: -60px;
  }
`;
export const ProgressBarContainer = styled.div`
  background: rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 30px;
  width: 300px;
  margin-top: 15px;

  @media (min-width: 768px) {
    width: 600px;
    height: 40px;
  }
`;

const loadAnimation = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`;

export const ProgressValue = styled.div`
  animation: ${loadAnimation} 30s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #fff;
  height: 20px;
  width: 0;

  @media (min-width: 768px) {
    height: 30px;
  }
`;
