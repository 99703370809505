import React from "react";
import styled from "styled-components";
import { ButtonSecondary, H3 } from "../../../App.style";
import { ParticleComponent } from "../../ParticlesComponent";
import { useTranslation } from "react-i18next";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  background: black;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const Modal = styled.div`
  background-color: #1d1e24;
  min-width: 260px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  z-index: 5;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
`;

interface LanguageFirstSelectorProps {
  handleLanguageChange: (language: "pl-PL" | "en-GB") => void;
}

const LanguageFirstSelector: React.FC<LanguageFirstSelectorProps> = ({
  handleLanguageChange,
}) => {
  const { t } = useTranslation();

  return (
    <Overlay>
      <Modal>
        <H3>{t("languageSelector.title")}</H3>
        <ButtonsContainer>
          <ButtonSecondary onClick={() => handleLanguageChange("pl-PL")}>
            Polski
          </ButtonSecondary>
          <ButtonSecondary onClick={() => handleLanguageChange("en-GB")}>
            English
          </ButtonSecondary>
        </ButtonsContainer>
      </Modal>
      <ParticleComponent />
    </Overlay>
  );
};

export default LanguageFirstSelector;
