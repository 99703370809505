import { EuiModal } from "@elastic/eui";
import styled from "styled-components";

export const CustomSettingsModal = styled(EuiModal)`
  background: #151416;

  @media (min-width: 768px) {
    width: 450px;

    min-height: 450px;
    max-height: 600px;
  }
`;

export const SettingsContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  flex-direction: column;

  display: flex;

  align-items: center;
`;

export const SettingsTitle = styled.div`
  margin: 40px 10px;

  text-align: center;

  @media (max-height: 638px) {
    margin: 40px 10px;
  }

  @media (min-width: 768px) {
    margin: 40px 10px;
    margin-bottom: 0px;
  }
`;

export const SettingsForm = styled.form`
  width: 200px;

  @media (min-width: 330px) {
    width: 300px;
  }

  @media (min-width: 450px) {
    width: 300px;
  }

  @media (min-width: 768px) {
    /* margin: 20px 0px; */

    width: 350px;
  }
`;

export const SettingsButtonsContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
  gap: 16px;
`;

export const PasswordChangeContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;

  width: 200px;

  @media (min-width: 330px) {
    width: 300px;
  }

  @media (min-width: 450px) {
    width: 300px;
  }

  @media (min-width: 768px) {
    /* margin: 20px 0px; */

    width: 350px;
  }
`;

export const AccountDeleteContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 200px;

  @media (min-width: 330px) {
    width: 300px;
  }

  @media (min-width: 450px) {
    width: 300px;
  }

  @media (min-width: 768px) {
    /* margin: 20px 0px; */

    width: 350px;
  }
`;
