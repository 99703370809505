import { useEffect, useState } from "react";

import { getUserDreams } from "../../session/api/userApi";
import { DreamRequest } from "../../dreams/api/useCheckFreeDream";

const useUserDreams = (userMail: string) => {
  const [dreams, setDreams] = useState<DreamRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchDreams = async () => {
      setLoading(true);
      try {
        const fetchedDreams = await getUserDreams(userMail);
        setDreams(fetchedDreams);
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        } else {
          setError(new Error("Unknown error occurred while fetching dreams"));
        }
      } finally {
        setLoading(false);
      }
    };

    if (userMail) {
      fetchDreams();
    }
  }, [userMail]);

  return { dreams, loading, error };
};

export default useUserDreams;
