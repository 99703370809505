import styled, { keyframes } from "styled-components";
import { P } from "../../App.style";

export const WrozbaFormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const WrozbaTitle = styled.div`
  margin: 8px 8px;

  text-align: center;

  @media (min-width: 768px) {
    margin-top: 40px;
    max-width: 600px;
    margin-bottom: 20px;
  }
`;

export const WrozbaInputTitle = styled(P)`
  opacity: 0.6;
  font-size: 14px;
`;

export const WrozbaFormContentContainer = styled.form`
  background: black;
  z-index: 1;
  margin-top: 20px;
  width: 200px;
  text-align: left;

  @media (min-width: 280px) {
    width: 250px;
  }
  @media (min-width: 350px) {
    width: 300px;
  }

  @media (min-width: 768px) {
    width: 400px;
  }
`;

//checkbox

const jelly = keyframes`
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
`;

export const CheckboxContainer = styled.div`
  display: inline-flex;
  vertical-align: middle;
  cursor: pointer;
  align-items: center;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? "black" : "black")};
  border: 1px solid #591d7d;
  border-radius: 4px;
  transition: all 150ms;

  &:after {
    content: "${(props) => (props.checked ? "✔" : "")}";
    color: #591d7d;
    font-size: 10px; // Adjust size as needed
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.checked ? 1 : 0)};
    transition: opacity 0.6s ease;
  }

  ${HiddenCheckbox}:checked + & {
    animation: ${jelly} 0.6s ease;
  }
`;

export const CheckboxLabel = styled.label`
  cursor: pointer;
`;

//summary

export const TermsLink = styled.a`
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
`;

export const WrozbaSummaryContainer = styled.div`
  width: 100%;

  display: flex;

  flex-direction: column;

  margin-top: 10px;

  @media (min-width: 768px) {
    height: 100%;
    width: 300px;
    padding-bottom: 10px;
  }
`;

export const WrozbaSummaryRow = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
  margin-bottom: 10px;
`;
