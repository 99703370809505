import React, { useState } from "react";
import styled from "styled-components";

import { Timestamp } from "@firebase/firestore";
import moment from "moment";
import { Button } from "../../../App.style";
import { deleteFortunesByIds } from "../api/adminApi";
import { ContactRequest } from "../../contact/useAddContactDocument";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  z-index: 10;

  height: 100%;

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  thead {
    position: sticky;
    top: 0;
    background: black;
  }

  tbody {
    display: block;
    overflow: auto;
    height: 100%;
    background: black;
  }
`;

const Th = styled.th`
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  text-align: left;
  color: white;
  padding: 10px 0px;
`;

const Td = styled.td`
  border-bottom: 1px solid white;
  padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: middle;

  color: white;
`;

const ContactTable: React.FC<{ contactsList: ContactRequest[] }> = ({
  contactsList,
}) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const toggleSelectAll = () => {
    if (selectedIds.length === contactsList.length) {
      setSelectedIds([]);
    } else {
      // Filter out items without an id
      setSelectedIds(
        contactsList
          .filter((item) => item.id)
          .map((item) => item.id!.toString())
      );
    }
  };

  const toggleSelectItem = (id: string) => {
    const newSelectedIds = selectedIds.includes(id)
      ? selectedIds.filter((existingId) => existingId !== id)
      : [...selectedIds, id];
    setSelectedIds(newSelectedIds);
  };

  const handleDeleteClick = async () => {
    try {
      deleteFortunesByIds(selectedIds).then(() => {
        alert("done!");
      });
      setSelectedIds([]);
    } catch (error) {
      console.log(error);
      alert("cos nie tak!");
    }
  };
  return (
    <Table>
      {selectedIds.length > 0 && (
        <Button
          style={{ background: "red", marginBottom: 10 }}
          onClick={handleDeleteClick}
        >
          Kasujemy
        </Button>
      )}
      <thead>
        <tr>
          <Th style={{ width: 30 }}>
            <input
              type="checkbox"
              checked={selectedIds.length === contactsList.length}
              onClick={toggleSelectAll}
            />
          </Th>
          <Th>Email</Th>
          <Th>Tytuł</Th>
          <Th>Wiadomość</Th>
          <Th>Status</Th>
          <Th>Data</Th>
          <Th>Error</Th>
        </tr>
      </thead>
      <tbody>
        {contactsList.length === 0 ? (
          <tr>
            <Td>Wyszukaj cos typie</Td>
          </tr>
        ) : (
          contactsList.map((item) => (
            <tr key={item.id} onClick={() => console.log(item)}>
              <Td style={{ width: 30 }}>
                <input
                  type="checkbox"
                  checked={selectedIds.includes(item.id ?? "")}
                  onChange={() => toggleSelectItem(item.id ?? "")}
                />
              </Td>
              <Td>{item.email}</Td>
              <Td>{item.title}</Td>
              <Td>{item.message}</Td>
              <Td>{item.status}</Td>
              <Td>{item.lang}</Td>
              <Td>
                {moment(
                  item.created_at instanceof Timestamp
                    ? item.created_at.toDate()
                    : new Date()
                ).format("DD.MM.YYYY HH:MM")}
              </Td>
              <Td>{item.error}</Td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default ContactTable;
