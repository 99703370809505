import styled, { css, keyframes } from "styled-components";
import { P } from "../../App.style";

export const TarotFormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const TarotTitle = styled.div`
  margin: 8px 8px;

  text-align: center;

  margin-top: 40px;

  @media (min-width: 768px) {
    margin-top: 60px;
    max-width: 600px;
    margin-bottom: 20px;
  }
`;

export const TarotTitleText = styled.div`
  text-decoration: underline;
  margin-top: 16px;
  cursor: pointer;

  ${P} {
    opacity: 0.8;
  }

  transition: all 0.3s ease;
  color: white;

  &:hover {
    ${P} {
      color: #6c3db8; // Change color on hover (gold)
      text-shadow: 0 0 8px #6c3db8; // Add a glowing effect
      transform: scale(1.02); // Slightly increase size
    }
    color: #6c3db8;
  }
`;

export const TarotFormStep1Container = styled.form`
  background: black;
  z-index: 1;
  margin-top: 20px;

  text-align: left;

  width: 200px;
  @media (min-width: 280px) {
    width: 250px;
  }
  @media (min-width: 350px) {
    width: 300px;
  }
  @media (min-width: 768px) {
    width: 400px;
  }
`;

export const TarotFormFortunesCardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
`;

export const TarotFormOptionText = styled.p`
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  opacity: 0.8;

  color: #fff;

  letter-spacing: 1.5px;
  line-height: 20px;
`;

export const TarotStacksContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr); // 4 columns
  gap: 20px;
  padding: 20px;
  position: relative;

  @media (min-width: 350px) {
    grid-template-columns: repeat(2, 1fr); // 4 columns
  }

  @media (min-width: 980px) {
    gap: 40px;
    grid-template-columns: repeat(4, 1fr); // 4 columns
  }
`;

export const TarotStackImage = styled.img<{
  $isNotSelected?: boolean;
}>`
  height: 240px;

  object-fit: contain;
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative; // Default position

  opacity: ${({ $isNotSelected }) => ($isNotSelected ? 0 : 1)};

  @media (min-width: 768px) {
    height: 280px;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

//cards list

export const TarotCardsListContainer = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr); // 4 columns
  gap: 20px;
  padding: 20px;

  @media (min-width: 810px) {
    grid-template-columns: repeat(4, 1fr); // 4 columns
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInAnimation = (index: number) => css`
  opacity: 0;
  animation: ${fadeIn} 0.5s ease forwards;
  animation-delay: ${index * 0.2}s;
`;

export const TarotCardImage = styled.img<{
  $selected: boolean;
  $index: number;
}>`
  height: 240px;
  object-fit: contain;
  box-sizing: border-box;
  cursor: pointer;

  ${({ $index }) => $index !== 0 && fadeInAnimation($index)};

  box-shadow: ${({ $selected }) =>
    $selected ? "0 0 8px 4px rgba(255, 255, 255, 0.6)" : "none"};

  @media (min-width: 768px) {
    height: 280px;

    &:hover {
      transform: scale(1.05);
    }
  }
`;
