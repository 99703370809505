import { User } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonSecondary } from "../../../App.style";
import {
  WrozbaDetaieldLoadingWord,
  WrozbaDetailedButtonsContainer,
  WrozbaDetailedContainer,
  WrozbaDetailedContentContainer,
  WrozbaDetailedSentenceContainer,
  WrozbaDetailedTitleContainer,
} from "./WrozbaDetailed.style";
import useFortuneById from "./useFortuneById";
import { copyToClipboard } from "@elastic/eui";
import { useTranslation } from "react-i18next";

interface WrozbaDetailedProps {
  currentUser: User | null;
}

const WrozbaDetailed: React.FC<WrozbaDetailedProps> = ({ currentUser }) => {
  const { t } = useTranslation();
  const { docId } = useParams();
  const navigate = useNavigate();

  const handleNoDocument = () => {
    navigate("/");
  };

  const { fortune, loading, error } = useFortuneById(
    docId || "",
    handleNoDocument
  );

  const handleButtonClick = () => {
    if (currentUser) {
      navigate("/fortunes");
    } else {
      navigate("/");
    }
  };

  const lines = fortune?.ai_response.split("\n");
  let cumulativeWordCount = 0;

  const [wrozbaCopied, setWrozbaCopied] = useState<boolean>(false);

  const handleCopyWrozba = () => {
    const copyLink = `https://mysticer.com/fortune/${docId}`;
    copyToClipboard(copyLink);

    setWrozbaCopied(true);

    let interval = setInterval(() => {
      setWrozbaCopied(false);
      clearInterval(interval); // Clear the interval here
    }, 3000);
  };

  const [isTextAnimated, setIsTextAnimated] = useState(true);

  if (loading) {
    return <div>{t("fortunesList.loading")}</div>;
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <WrozbaDetailedContainer>
      <WrozbaDetailedTitleContainer>
        {t("fortuneDetailed.title")}
      </WrozbaDetailedTitleContainer>
      <WrozbaDetailedContentContainer onClick={() => setIsTextAnimated(false)}>
        {lines?.map((line, lineIndex) => (
          <WrozbaDetailedSentenceContainer key={`line-${lineIndex}`}>
            {line.split(" ").map((word, wordIndex) => {
              const animationDelay = cumulativeWordCount * 0.1;
              cumulativeWordCount++; // Increment the count for each word
              return (
                <WrozbaDetaieldLoadingWord
                  key={`word-${lineIndex}-${wordIndex}`}
                  style={{
                    animationDelay: isTextAnimated
                      ? `${animationDelay}s`
                      : "0s",
                  }}
                >
                  {word}
                </WrozbaDetaieldLoadingWord>
              );
            })}
          </WrozbaDetailedSentenceContainer>
        ))}
      </WrozbaDetailedContentContainer>

      <WrozbaDetailedButtonsContainer>
        <ButtonSecondary
          onClick={handleCopyWrozba}
          onMouseLeave={() => setWrozbaCopied(false)}
        >
          {wrozbaCopied
            ? t("fortuneDetailed.buttons.copied")
            : t("fortuneDetailed.buttons.copy")}
        </ButtonSecondary>
        <Button onClick={handleButtonClick}>
          {currentUser
            ? t("fortuneDetailed.buttons.myFortunes")
            : t("fortuneDetailed.buttons.homepage")}
        </Button>
      </WrozbaDetailedButtonsContainer>
    </WrozbaDetailedContainer>
  );
};

export default WrozbaDetailed;
