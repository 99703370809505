import React from "react";
import { User } from "firebase/auth";
import { FieldValue, Timestamp } from "firebase/firestore";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Button, H3, H5, P } from "../../../App.style";
import {
  NoFortunesContainer,
  WrozbaContent,
  WrozbaContentIcon,
  WrozbaDate,
  WrozbaListContainer,
  WrozbaListContentContainer,
  WrozbaListRow,
  WrozbaListTitleContainer,
  WrozbaLoadingContainer,
  WrozbaSeeMoreButtonContainer,
} from "./WrozbaList.style";
import useUserFortunes from "./useUserFortunes";
import { useTranslation } from "react-i18next";
import { TarotRequest } from "../../tarot/api/useCheckFreeTarot";
import { FortuneRequest } from "../result/useCheckPayment";
import useUserTarots from "./useUserTarots";
import useUserDreams from "./useUserDreams";
import { DreamRequest } from "../../dreams/api/useCheckFreeDream";
import tarotIcon from "../../../assets/hero/tarot-card.png";
import przepowiedniaIcon from "../../../assets/hero/crystal-ball.png";
import dreamIcon from "../../../assets/hero/dream.png";

interface WrozbaListProps {
  currentUser: User;
}

const WrozbaList: React.FC<WrozbaListProps> = ({ currentUser }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    fortunes,
    loading: loadingFortunes,
    error: errorFortunes,
  } = useUserFortunes(currentUser.uid);
  const {
    tarots,
    loading: loadingTarots,
    error: errorTarots,
  } = useUserTarots(currentUser.uid);

  const {
    dreams,
    loading: loadingDreams,
    error: errorDreams,
  } = useUserDreams(currentUser.uid);

  type CombinedWrozbaType = {
    created_at: FieldValue;
    ai_response: string;
    type: "tarot" | "wrozba" | "dream";
    id: string;
  };

  function combineLists(
    tarotList: TarotRequest[],
    wrozbaList: FortuneRequest[],
    dreamsList: DreamRequest[]
  ): CombinedWrozbaType[] {
    // Combine and transform the lists
    const combinedList = [
      ...tarotList.map((item) => ({
        created_at: item.created_at,
        ai_response: item.ai_response,
        type: "tarot" as const,
        id: item.id as string,
      })),
      ...wrozbaList.map((item) => ({
        created_at: item.created_at,
        ai_response: item.ai_response,
        type: "wrozba" as const,
        id: item.id as string,
      })),
      ...dreamsList.map((item) => ({
        created_at: item.created_at,
        ai_response: item.ai_response,
        type: "dream" as const,
        id: item.id as string,
      })),
    ];
    return combinedList;
  }

  function combineAndSortLists(
    tarotList: TarotRequest[],
    wrozbaList: FortuneRequest[],
    dreamsList: DreamRequest[]
  ): CombinedWrozbaType[] {
    const combinedList = combineLists(tarotList, wrozbaList, dreamsList);

    combinedList.sort((a, b) => {
      // Convert 'created_at' to Date object
      const dateA =
        a.created_at instanceof Timestamp ? a.created_at.toDate() : new Date();
      const dateB =
        b.created_at instanceof Timestamp ? b.created_at.toDate() : new Date();

      return dateB.getTime() - dateA.getTime();
    });

    return combinedList;
  }

  const combinedList = combineAndSortLists(tarots, fortunes, dreams);

  const handleWrozbaClick = (
    type: "tarot" | "wrozba" | "dream",
    id: string
  ) => {
    if (type === "tarot") {
      navigate(`/tarot/${id}`);
    } else if (type === "wrozba") {
      navigate(`/fortune/${id}`);
    } else {
      navigate(`/dream/${id}`);
    }

    window.scrollTo(0, 0);
  };

  const renderIcon = (type: "tarot" | "wrozba" | "dream") => {
    switch (type) {
      case "tarot":
        return tarotIcon;
      case "wrozba":
        return przepowiedniaIcon;
      case "dream":
        return dreamIcon;
      default:
        return przepowiedniaIcon;
    }
  };

  const renderText = (type: "tarot" | "wrozba" | "dream") => {
    switch (type) {
      case "tarot":
        return t("fortunesList.tarot");
      case "wrozba":
        return t("fortunesList.prophecy");
      case "dream":
        return t("dream.form.title");
      default:
        return "";
    }
  };

  return (
    <WrozbaListContainer>
      <WrozbaListTitleContainer>
        {t("fortunesList.title")}
      </WrozbaListTitleContainer>

      {(loadingFortunes || loadingTarots || loadingDreams) && (
        <WrozbaLoadingContainer>
          <H3>{t("fortunesList.loading")}</H3>
        </WrozbaLoadingContainer>
      )}
      {(errorFortunes || errorTarots || errorDreams) && <div>Error...</div>}

      <WrozbaListContentContainer>
        {combinedList.length === 0 &&
        !loadingTarots &&
        !loadingFortunes &&
        !loadingDreams ? (
          <NoFortunesContainer>
            <H3>{t("fortunesList.noFortunes.row1")}</H3>
            <H3>{t("fortunesList.noFortunes.row2")}</H3>
            <Button
              style={{ marginTop: 20 }}
              onClick={() => navigate("/#selector")}
            >
              {t("fortunesList.noFortunes.buttonText")}
            </Button>
          </NoFortunesContainer>
        ) : (
          combinedList.map((fortune, i) => {
            const dateObject =
              fortune.created_at instanceof Timestamp
                ? fortune.created_at.toDate()
                : new Date();
            const formattedDate = moment(dateObject).format("DD.MM.YYYY HH:mm");

            const icon = renderIcon(fortune.type);

            const description = renderText(fortune.type);
            return (
              <WrozbaListRow
                style={{ marginBottom: 20 }}
                key={`fortune-${i}`}
                onClick={() => handleWrozbaClick(fortune.type, fortune.id)}
              >
                <WrozbaDate>
                  <P>{description}</P>
                  <P style={{ fontSize: 14 }}>{formattedDate}</P>
                </WrozbaDate>
                <WrozbaContent $tarot={fortune.type === "tarot"}>
                  <P>{fortune.ai_response.slice(0, 250)}...</P>

                  <WrozbaContentIcon
                    style={{ width: fortune.type === "wrozba" ? 45 : 50 }}
                    src={icon}
                  />
                </WrozbaContent>
                <WrozbaSeeMoreButtonContainer>
                  <H5>{t("fortunesList.seeMore")}</H5>
                </WrozbaSeeMoreButtonContainer>
              </WrozbaListRow>
            );
          })
        )}
      </WrozbaListContentContainer>
    </WrozbaListContainer>
  );
};

export default WrozbaList;
