import styled, { keyframes } from "styled-components";
import { FONT_WHITE, H4 } from "../../../App.style";

export const WrozbaDetailedContainer = styled.div`
  padding-top: 80px;
  width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;

  padding: 80px 15px;

  @media (min-width: 350px) {
    padding: 80px 20px;
  }

  @media (min-width: 768px) {
    padding: 80px 30px;
  }
`;

export const WrozbaDetailedTitleContainer = styled.div`
  text-align: center;
  max-width: 800px;

  margin-top: 30px;
  @media (min-width: 768px) {
    margin-top: 50px;
  }

  font-family: "Roboto", sans-serif;
  font-size: clamp(25px, 4vw + 1rem, 38px);
  font-style: normal;
  font-weight: 500;

  letter-spacing: 2px;

  color: ${FONT_WHITE};
`;

export const WrozbaDetailedContentContainer = styled.div`
  background: black;
  border-radius: 20px;
  opacity: 0.8;
  text-align: center;
  position: relative;
  z-index: 1;

  margin-top: 15px;
  margin-bottom: 20px;

  padding: 20px 0px;

  @media (min-width: 300px) {
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 20px 0px;
    max-width: 550px;
  }

  @media (min-width: 768px) {
    margin-top: 35px;
    margin-bottom: 20px;
    padding: 30px 0px;

    max-width: 700px;
  }

  @media (min-width: 1000px) {
    margin-top: 40px;
    margin-bottom: 30px;
    padding: 40px 30px;
    max-width: 1100px;
  }

  p {
    font-size: 16px;
    letter-spacing: 1.71px;
    text-shadow: 0px 8px 24px #000;

    @media (min-width: 768px) {
      font-size: 19px;
    }
  }
`;

//loading text animation lol
const fadeIn = keyframes`
  from {
    opacity: 0;
    filter: blur(4px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
`;

export const WrozbaDetaieldLoadingWord = styled.span`
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
  animation: ${fadeIn} 1.5s forwards;
  animation-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  margin-right: 10px;
`;

export const WrozbaDetailedSentenceContainer = styled(H4)`
  text-align: center;
  transform: scale(0.94);
  animation: scale 1s forwards cubic-bezier(0.5, 1, 0.89, 1);
  letter-spacing: 0.2px;
`;

export const WrozbaDetailedButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`;
