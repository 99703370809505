import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "@firebase/firestore";

import { db } from "../../../../config/firebase";
import { FortuneRequest } from "../../wrozba/result/useCheckPayment";
import { TarotRequest } from "../../tarot/api/useCheckFreeTarot";
import { DreamRequest } from "../../dreams/api/useCheckFreeDream";

export type WrozbaTimeRanges =
  | "jutro"
  | "przyszły tydzień"
  | "przyszły miesiac"
  | "przyszły rok"
  | "";

export interface UserFortuneFormData {
  name: string;
  age: string;
  professional_status: string;
  relationship_status: string;
  gender: string;
  fortune: string;
  time: WrozbaTimeRanges;
  mood: string;
}

export interface UserTarotFormData {
  name: string;
  fortune: string;
  relationship_status: string;
  cardsCount: string;
  tarotCards: { name: string; id: string }[];
}

export interface UserDreamFormData {
  name: string;
  dream_description: string;
  price: string;
}

export interface FirestoreUser {
  id: string;
  email: string;
  name: string;
  age: string;
  professional_status: string;
  joinedAt: string;
  gender: string;
  lastFreeTarot: string;
  lastFreeDream: string;
}

export const getFirestoreUser = async (userId: string) => {
  try {
    const userDoc = doc(db, "Users", userId);
    const docSnapshot = await getDoc(userDoc);

    if (docSnapshot.exists()) {
      return { id: docSnapshot.id, ...docSnapshot.data() } as FirestoreUser;
    } else {
      console.log("No user found!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user: ", error);
    return null;
  }
};

//update firestore user
export async function updateUser(
  userId: string,
  updatedFields: Partial<FirestoreUser>
) {
  const userRef = doc(db, "Users", userId);
  await updateDoc(userRef, updatedFields);
}

//delete from firestore
export async function deleteUserFromFirestore(userId: string) {
  const userRef = doc(db, "Users", userId);
  await deleteDoc(userRef);
}

export async function getUserFortunes(id: string): Promise<FortuneRequest[]> {
  const fortunesRef = collection(db, "Fortunes");
  const q = query(
    fortunesRef,
    where("user_id", "==", id),
    where("status", "==", "success"),
    orderBy("created_at", "desc")
  );

  const querySnapshot = await getDocs(q);

  const meetings: FortuneRequest[] = querySnapshot.docs.map((doc) => {
    const data = doc.data() as Omit<FortuneRequest, "id">;
    return {
      id: doc.id,
      ...data,
    };
  });

  return meetings;
}

export async function getUserDreams(id: string): Promise<DreamRequest[]> {
  const dreamsRef = collection(db, "Dreams");
  const q = query(
    dreamsRef,
    where("user_id", "==", id),
    where("status", "==", "success"),
    orderBy("created_at", "desc")
  );

  const querySnapshot = await getDocs(q);

  const meetings: DreamRequest[] = querySnapshot.docs.map((doc) => {
    const data = doc.data() as Omit<DreamRequest, "id">;
    return {
      id: doc.id,
      ...data,
    };
  });

  return meetings;
}

export async function getUserTarots(id: string): Promise<TarotRequest[]> {
  const fortunesRef = collection(db, "Tarots");
  const q = query(
    fortunesRef,
    where("user_id", "==", id),
    where("status", "==", "success"),
    orderBy("created_at", "desc")
  );

  const querySnapshot = await getDocs(q);

  const meetings: TarotRequest[] = querySnapshot.docs.map((doc) => {
    const data = doc.data() as Omit<TarotRequest, "id">;
    return {
      id: doc.id,
      ...data,
    };
  });

  return meetings;
}

export async function getFortuneById(
  documentId: string
): Promise<FortuneRequest | null> {
  try {
    const fortuneRef = doc(db, "Fortunes", documentId);
    const docSnapshot = await getDoc(fortuneRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data() as Omit<FortuneRequest, "id">;
      return data;
    } else {
      // Handle the case where the document does not exist
      console.log("No document with the given ID found");
      return null;
    }
  } catch (error) {
    // Handle any errors that occur during the fetch
    console.error("Error fetching document:", error);
    throw error;
  }
}

export async function getTarotById(
  documentId: string
): Promise<TarotRequest | null> {
  try {
    const tarotRef = doc(db, "Tarots", documentId);
    const docSnapshot = await getDoc(tarotRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data() as Omit<TarotRequest, "id">;
      return data;
    } else {
      // Handle the case where the document does not exist
      console.log("No document with the given ID found");
      return null;
    }
  } catch (error) {
    // Handle any errors that occur during the fetch
    console.error("Error fetching document:", error);
    throw error;
  }
}

export async function getDreamById(
  documentId: string
): Promise<DreamRequest | null> {
  try {
    const dreamRef = doc(db, "Dreams", documentId);
    const docSnapshot = await getDoc(dreamRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data() as Omit<DreamRequest, "id">;
      return data;
    } else {
      // Handle the case where the document does not exist
      console.log("No document with the given ID found");
      return null;
    }
  } catch (error) {
    // Handle any errors that occur during the fetch
    console.error("Error fetching document:", error);
    throw error;
  }
}

export async function deleteUser(email: string) {
  try {
    const deleteRef = collection(db, "DeleteAccount");

    const deleteDoc = {
      email: email,
      status: "pending",
    };

    await addDoc(deleteRef, deleteDoc);
  } catch (error) {
    console.error("Error adding new delete", error);
  }
}
// export async function deleteUserDataAndAuth(
//   currentUser: User,
//   currentPassword: string
// ): Promise<void> {
//   const auth = getAuth();

//   const userId = currentUser.uid;

//   try {
//     // Delete the user's data from Firestore first
//     if (currentUser.email) {
//       const credential = EmailAuthProvider.credential(
//         currentUser.email,
//         currentPassword
//       );
//       await reauthenticateWithCredential(currentUser, credential);
//     }

//     const userRef = doc(db, "Users", userId);
//     await deleteDoc(userRef);

//     // Then delete the user from Firebase Authentication
//     // Note: You might need to re-authenticate the user before this step
//     if (auth.currentUser && auth.currentUser.uid === userId) {
//       await deleteUser(auth.currentUser);
//       console.log(
//         `User with ID ${userId} deleted from Firebase Authentication.`
//       );
//     } else {
//       throw new Error("Authentication user mismatch or not found.");
//     }
//   } catch (error) {
//     console.error("Error in deleting user data and account:", error);
//     throw error; // Rethrow the error for further handling
//   }
// }
