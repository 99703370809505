import React from "react";
import {
  HowItWorksContainer,
  HowItWorksContentContainer,
  StepContainer,
  StepTitleContainer,
} from "./HowItWorks.style";
import { H2, H3, P } from "../../../../App.style";
import starIcon from "../../../../assets/how_it_works/star.png";
import { TitleContainer } from "../../Homepage.style";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HowItworks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const titleInView = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const krok1View = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const krok2View = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const handleWrozbaClick = () => {
    navigate("#selector");
  };

  return (
    <HowItWorksContainer>
      <TitleContainer ref={titleInView.ref} $isVisible={titleInView.inView}>
        <H2>{t("homepage.howItWorks.title")}</H2>
      </TitleContainer>
      <HowItWorksContentContainer>
        <StepContainer ref={krok1View.ref} $isVisible={krok1View.inView}>
          <StepTitleContainer>
            <img
              src={starIcon}
              style={{ width: 35, height: 35 }}
              alt="star-icon"
            />
            <H3>{t("homepage.howItWorks.step1.title")}</H3>
          </StepTitleContainer>
          <P>
            {t("homepage.howItWorks.step1.line1")}
            <span
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={handleWrozbaClick}
            >
              {t("homepage.howItWorks.step1.here")}
            </span>
            .
          </P>
          <P>{t("homepage.howItWorks.step1.line2")}</P>
        </StepContainer>

        <StepContainer ref={krok2View.ref} $isVisible={krok2View.inView}>
          <StepTitleContainer>
            <img src={starIcon} alt="star-icon" />
            <H3>{t("homepage.howItWorks.step2.title")}</H3>
          </StepTitleContainer>
          <P>{t("homepage.howItWorks.step2.text")}</P>
        </StepContainer>
      </HowItWorksContentContainer>
    </HowItWorksContainer>
  );
};

export default HowItworks;
