import styled, { css } from "styled-components";

export const HomepageContainer = styled.div`
  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

//title
export const fadeInAnimation = css`
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, visibility 0.6s;
`;

export interface FadeInProps {
  $isVisible: boolean;
}

export const TitleContainer = styled.div<FadeInProps>`
  opacity: 0;
  transform: translateY(40px);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, visibility 0.6s;
  ${({ $isVisible }) => $isVisible && fadeInAnimation}

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`;
