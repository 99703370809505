import React from "react";
import {
  ServiceWorkScreenContainer,
  ServiceWorkScreenText,
} from "./ServiceWorkScreen.style";

import { ServiceInfo } from "../api/useCheckService";
import { ParticleComponent } from "../../ParticlesComponent";
import { useTranslation } from "react-i18next";

interface ServiceWorkScreenProps {
  service: ServiceInfo;
}

const ServiceWorkScreen: React.FC<ServiceWorkScreenProps> = ({ service }) => {
  const { t } = useTranslation();
  return (
    <ServiceWorkScreenContainer>
      <ParticleComponent />
      <ServiceWorkScreenText>{t("serviceWork.title")}</ServiceWorkScreenText>
      <ServiceWorkScreenText>
        {t("serviceWork.subtitle", { backTime: service.time })}
      </ServiceWorkScreenText>
    </ServiceWorkScreenContainer>
  );
};

export default ServiceWorkScreen;
