import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translationsPl } from "./pl";
import { translationsEn } from "./en";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: translationsEn,
  },
  pl: {
    translation: translationsPl,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
    },
  });

export default i18n;
