import React, { useEffect, useState } from "react";
import {
  CheckboxContainer,
  CheckboxLabel,
  HiddenCheckbox,
  StyledCheckbox,
  TermsLink,
  WrozbaFormContainer,
  WrozbaFormContentContainer,
  WrozbaInputTitle,
  WrozbaTitle,
} from "../wrozba/Wrozba.style";
import { Button, H2, H5, P } from "../../App.style";
import {
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
  EuiSuperSelect,
  EuiText,
  EuiTextArea,
} from "@elastic/eui";
import { User } from "firebase/auth";
import {
  FirestoreUser,
  UserDreamFormData,
  updateUser,
} from "../session/api/userApi";

import { useNavigate } from "react-router-dom";
import DreamsFreeLoading from "./DreamsFreeLoading";
import { useTranslation } from "react-i18next";
import { DreamRequest, useCheckFreeDream } from "./api/useCheckFreeDream";
import { collection, serverTimestamp, addDoc } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import { TarotFormOptionText, TarotTitleText } from "../tarot/Tarot.style";
import moment from "moment";

interface DreamsFormProps {
  currentUser: User | null;
  firestoreUser: FirestoreUser | null;
  openLoginModal: () => void;
}

const DreamsForm: React.FC<DreamsFormProps> = ({
  currentUser,
  firestoreUser,
  openLoginModal,
}) => {
  const { t, i18n } = useTranslation();
  const [isFreeLoading, setIsFreeLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [dream, setDream] = useState<UserDreamFormData>({
    name: firestoreUser?.name ?? "",
    dream_description: "",
    price: "",
  });
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    if (firestoreUser) {
      setDream((prevDream) => {
        const updatedDream = { ...prevDream };

        if (firestoreUser.name) {
          updatedDream.name = firestoreUser.name;
        }

        const lastFreeDream = firestoreUser.lastFreeDream;
        updatedDream.price = lastFreeDream ? "pay" : "free";

        return updatedDream;
      });
    }
  }, [firestoreUser]);

  //validation
  const [isFieldValid, setIsFieldValid] = useState({
    name: true,
    dream_description: true,
    terms: true,
  });

  const validateDream = () => {
    const newValidationState = {
      name: !!dream.name,
      dream_description:
        !!dream.dream_description && dream.dream_description.length >= 20,
      terms: !!termsAccepted,
    };

    setIsFieldValid(newValidationState);

    return Object.values(newValidationState).every(Boolean);
  };

  const { checkFreeDream, response, error } = useCheckFreeDream();

  const addDream = async (userData: UserDreamFormData): Promise<string> => {
    const dreamsRef = collection(db, "Dreams");

    const dream_payload: DreamRequest = {
      status: "pending",
      user_form_data: userData,
      ai_response: "",
      user_id: currentUser?.uid ?? null,
      created_at: serverTimestamp(),
      lang: i18n.language === "pl-PL" ? "PL" : "EN",
    };

    try {
      const docRef = await addDoc(dreamsRef, dream_payload);

      localStorage.removeItem("apiResponseDream");
      localStorage.setItem("dreamDocId", docRef.id);
      localStorage.setItem("paymentStatusDreamCheckNeeded", "true");
      return docRef.id;
    } catch (error) {
      throw new Error("Failed to add new dream"); // Throw an error to be caught by the caller
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateDream()) {
      return;
    }

    if (dream.price === "free" && firestoreUser) {
      const data = {
        user_form_data: dream,
        lang: i18n.language === "pl-PL" ? "PL" : "EN",
        user_id: currentUser?.uid ?? null,
        isFree: true,
      };
      setIsFreeLoading(true);

      const currentTime = moment().format("DD.MM.YYYY, HH:mm");
      await updateUser(firestoreUser.id, { lastFreeDream: currentTime });

      checkFreeDream(data.user_form_data, data.lang, data.user_id);
    } else {
      const documentId = await addDream(dream);

      window.location.href = `https://buy.stripe.com/fZeg1ufklcdv5PyeV0?client_reference_id=${documentId}`;
    }
  };

  useEffect(() => {
    if (response) {
      navigate(`/dream/${response}`);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (error) {
      navigate("/#hero");
      window.location.reload();
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBackButtonClick = () => {
    navigate("#selector");
  };

  //test
  const options = [
    {
      value: "free",
      inputDisplay: t("homepage.wrozbaSelector.form.card1.price"),
      dropdownDisplay: (
        <div
          style={{
            position: "relative",
            opacity: currentUser === null ? 0.6 : 1,
          }}
        >
          <H5>{t("homepage.wrozbaSelector.form.card1.price")}</H5>
          {!currentUser && (
            <TarotFormOptionText style={{ marginTop: 5, fontWeight: 600 }}>
              {t("homepage.wrozbaSelector.form.needsLogin")}
            </TarotFormOptionText>
          )}
        </div>
      ),
    },
    {
      value: "pay",
      inputDisplay: t("homepage.wrozbaSelector.form.card2.price"),
      dropdownDisplay: (
        <div style={{ position: "relative" }}>
          <H5>{t("homepage.wrozbaSelector.form.card2.price")}</H5>
        </div>
      ),
    },
  ];
  return (
    <>
      {!isFreeLoading ? (
        <WrozbaFormContainer>
          <WrozbaTitle>
            <H2>{t("dream.form.title")}</H2>

            <TarotTitleText onClick={() => navigate("/#selector")}>
              <P>{t("homepage.hero.moreFortunes")}</P>
            </TarotTitleText>
          </WrozbaTitle>

          <WrozbaFormContentContainer id="dream" onSubmit={handleSubmit}>
            <EuiFormRow
              label={
                <WrozbaInputTitle>{t("dream.form.name")}</WrozbaInputTitle>
              }
              labelType="legend"
              isInvalid={!isFieldValid.name}
              error={t("fortuneModal.requiredError")}
            >
              <EuiFieldText
                maxLength={20}
                style={{ background: "#0D0D0D" }}
                value={dream.name}
                onChange={(e) => {
                  setDream({ ...dream, name: e.target.value });
                  setIsFieldValid({
                    ...isFieldValid,
                    name: !!e.target.value,
                  });
                }}
                compressed
                aria-label="imie"
              />
            </EuiFormRow>

            <EuiFormRow
              label={
                <WrozbaInputTitle>
                  {t("dream.form.description")}
                </WrozbaInputTitle>
              }
              labelType="legend"
              isInvalid={!isFieldValid.dream_description}
              error={
                dream.dream_description.length < 20
                  ? t("dream.form.errorLess")
                  : dream.dream_description.length > 300 &&
                    t("dream.form.errorMore")
              }
              helpText={
                <div>
                  {t("dream.form.info1")}
                  <br />
                  {t("dream.form.info2")}
                </div>
              }
            >
              <div style={{ position: "relative" }}>
                <EuiTextArea
                  style={{
                    background: "#0D0D0D",
                    minHeight: 50,
                    paddingBottom: 50,
                  }}
                  maxLength={300}
                  value={dream.dream_description}
                  onChange={(e) => {
                    setDream({ ...dream, dream_description: e.target.value });
                    setIsFieldValid({
                      ...isFieldValid,
                      dream_description: !!e.target.value,
                    });
                  }}
                  compressed
                  aria-label="opis snu"
                />
                <p
                  style={{
                    color: "white",
                    position: "absolute",
                    right: 8,

                    top: -18,
                    fontSize: 11,
                  }}
                >
                  {dream.dream_description.length} / 300
                </p>
              </div>
            </EuiFormRow>

            <EuiFormRow
              style={{ marginTop: 30 }}
              label={
                <WrozbaInputTitle>{t("dream.form.price")}</WrozbaInputTitle>
              }
              labelType="legend"
              //   isInvalid={!isFieldValid.fortune}
              error={t("fortuneModal.requiredError")}
            >
              <EuiSuperSelect
                style={{ background: "#0D0D0D" }}
                compressed
                disabled={firestoreUser?.lastFreeDream ? true : false}
                placeholder={t("fortuneModal.select")}
                valueOfSelected={dream.price}
                onChange={(value) => {
                  if (!currentUser && value === "free") {
                    openLoginModal();
                  } else {
                    setDream({ ...dream, price: value });
                  }
                }}
                options={options}
              />
            </EuiFormRow>

            <div
              style={{
                marginTop: 20,
                display: "flex",
                width: "100%",

                alignItems: "center",
                gap: 8,
                cursor: "pointer",
              }}
              onClick={() => setTermsAccepted((prev) => !prev)}
            >
              <CheckboxContainer>
                <HiddenCheckbox checked={termsAccepted} readOnly />
                <StyledCheckbox checked={termsAccepted} />
                <CheckboxLabel />
              </CheckboxContainer>

              <P style={{ opacity: 0.8 }}>
                {t("fortuneModal.step3.terms.accept")}
                <TermsLink href="https://mysticer.com/terms" target="_blank">
                  {t("fortuneModal.step3.terms.terms")}
                </TermsLink>{" "}
                {t("fortuneModal.step3.terms.and")}
                <TermsLink href="https://mysticer.com/privacy" target="_blank">
                  {t("fortuneModal.step3.terms.privacy")}
                </TermsLink>
                .
              </P>
            </div>

            {!isFieldValid.terms && !termsAccepted && (
              <div>
                <EuiText color={"danger"} style={{ marginTop: 5 }}>
                  {t("fortuneModal.step3.terms.error")}
                </EuiText>
              </div>
            )}

            <div
              style={{
                marginTop: 30,
                display: "flex",
                gap: 20,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <EuiButtonEmpty
                onClick={handleBackButtonClick}
                style={{ color: "white" }}
              >
                {t("fortuneModal.buttons.close")}
              </EuiButtonEmpty>

              <Button type="submit" form="dream">
                {t("fortuneModal.buttons.continue")}
              </Button>
            </div>
          </WrozbaFormContentContainer>
        </WrozbaFormContainer>
      ) : (
        <DreamsFreeLoading />
      )}
    </>
  );
};

export default DreamsForm;
