import React, { useState } from "react";
import {
  ModalOverlay,
  ModalCloseButton,
  ModalTitleContainer,
  ModalContentContainer,
  OrContainer,
  ModalContainer,
} from "./LoginModal.style";
import GoogleLoginButton from "./GoogleLoginButton";
import { H2, H4 } from "../../../App.style";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import PasswordReset from "./PasswordReset";
import { useTranslation } from "react-i18next";

interface LoginModalProps {
  onClose: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<
    "login" | "register" | "passwordReset"
  >("login");

  const handleLoginClick = () => setModalState("login");

  const handleRegisterClick = () => setModalState("register");

  const handlePasswordResetClick = () => setModalState("passwordReset");

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalCloseButton onClick={onClose}>&times;</ModalCloseButton>
        <ModalTitleContainer>
          <H2>Mysticer</H2>
          {modalState === "register" && (
            <H4 style={{ marginTop: 10 }}>{t("loginModal.register.title")}</H4>
          )}

          {modalState === "passwordReset" && (
            <H4 style={{ marginTop: 10 }}>
              {t("loginModal.passwordReset.title")}
            </H4>
          )}
        </ModalTitleContainer>
        <ModalContentContainer>
          {modalState !== "passwordReset" && (
            <>
              <GoogleLoginButton />

              <OrContainer>{t("loginModal.or")}</OrContainer>
            </>
          )}

          {modalState === "login" && (
            <LoginForm
              onClose={onClose}
              handleRegisterClick={handleRegisterClick}
              handlePasswordResetClick={handlePasswordResetClick}
            />
          )}

          {modalState === "register" && (
            <RegisterForm
              onClose={onClose}
              handleLoginClick={handleLoginClick}
            />
          )}

          {modalState === "passwordReset" && (
            <PasswordReset
              onClose={onClose}
              handleLoginClick={handleLoginClick}
            />
          )}
        </ModalContentContainer>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default LoginModal;
