import React, { useState } from "react";
import { AdminPanelContainer } from "./AdminPanel.style";
import { EuiFieldPassword, EuiFormRow } from "@elastic/eui";
import { Button } from "../../App.style";
import { useNavigate } from "react-router-dom";

const AdminPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const handleButtonClick = () => {
    if (password === "Dinozauureek2") {
      localStorage.setItem("adminPass", "lecimy");
      navigate("/admin-panel");
    } else {
      navigate("/");
    }
  };
  return (
    <AdminPanelContainer style={{ justifyContent: "center" }}>
      <EuiFormRow label="Elo">
        <EuiFieldPassword
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </EuiFormRow>
      <Button style={{ marginTop: 30 }} onClick={handleButtonClick}>
        Dawaj
      </Button>
    </AdminPanelContainer>
  );
};

export default AdminPassword;
