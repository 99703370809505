import React, { useState } from "react";
import { copyToClipboard } from "@elastic/eui";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { User } from "firebase/auth";
import useDreamById from "./useDreamById";
import { Button, ButtonSecondary } from "../../../App.style";
import {
  DreamDetailedContainer,
  DreamDetailedTitleContainer,
  DreamDetailedContentContainer,
  DreamDetailedSubtitleContainer,
  DreamDetailedButtonsContainer,
} from "./DreamDetailed.style";
import moment from "moment";
import { Timestamp } from "@firebase/firestore";

interface DreamDetailedProps {
  currentUser: User | null;
}

const DreamDetailed: React.FC<DreamDetailedProps> = ({ currentUser }) => {
  const { t } = useTranslation();
  const { docId } = useParams();
  const navigate = useNavigate();

  const handleNoDocument = () => {
    navigate("/");
  };

  const { dream, loading, error } = useDreamById(docId || "", handleNoDocument);

  const dreamDate =
    dream?.created_at instanceof Timestamp
      ? dream.created_at.toDate()
      : new Date();
  const formattedDate = moment(dreamDate).format("DD.MM.YYYY");

  const handleButtonClick = () => {
    if (currentUser) {
      navigate("/fortunes");
    } else {
      navigate("/");
    }
  };

  const [wrozbaCopied, setWrozbaCopied] = useState<boolean>(false);

  const handleCopyWrozba = () => {
    const copyLink = `https://mysticer.com/dream/${docId}`;
    copyToClipboard(copyLink);

    setWrozbaCopied(true);

    let interval = setInterval(() => {
      setWrozbaCopied(false);
      clearInterval(interval); // Clear the interval here
    }, 3000);
  };

  if (loading) {
    return <div>{t("fortunesList.loading")}</div>;
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <DreamDetailedContainer>
      <DreamDetailedTitleContainer>
        {dream?.user_form_data.name}
        {t("dream.detailed.title")}
      </DreamDetailedTitleContainer>
      <DreamDetailedSubtitleContainer>
        {formattedDate}
      </DreamDetailedSubtitleContainer>
      <DreamDetailedContentContainer>
        <span>"{dream?.user_form_data.dream_description}"</span>
        <p>{dream?.ai_response}</p>
      </DreamDetailedContentContainer>

      <DreamDetailedButtonsContainer>
        <ButtonSecondary
          onClick={handleCopyWrozba}
          onMouseLeave={() => setWrozbaCopied(false)}
        >
          {wrozbaCopied
            ? t("fortuneDetailed.buttons.copied")
            : t("fortuneDetailed.buttons.copy")}
        </ButtonSecondary>
        <Button onClick={handleButtonClick}>
          {currentUser
            ? t("fortuneDetailed.buttons.myFortunes")
            : t("fortuneDetailed.buttons.homepage")}
        </Button>
      </DreamDetailedButtonsContainer>
    </DreamDetailedContainer>
  );
};

export default DreamDetailed;
