import { EuiModal } from "@elastic/eui";
import styled from "styled-components";

export const CustomHelpModal = styled(EuiModal)`
  background: #151416;

  @media (min-width: 768px) {
    width: 550px;

    min-height: 450px;
    max-height: 600px;
  }
`;

export const HelpTitle = styled.div`
  margin: 20px 10px;

  text-align: center;
  max-width: 495px;

  @media (max-height: 638px) {
    margin: 20px 10px;
  }

  @media (min-width: 768px) {
    width: 90%;

    margin: 20px 10px;
    margin-bottom: 0px;
  }
`;

export const HelpForm = styled.form`
  width: 200px;

  @media (min-width: 330px) {
    width: 300px;
  }

  @media (min-width: 450px) {
    width: 400px;
  }

  @media (min-width: 768px) {
    margin: 30px 0px;

    width: 400px;
  }
`;

export const AfterModalMessageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;

  gap: 30px;

  @media (min-width: 768px) {
    padding: 20px 30px;
    height: 400px;
  }
`;
