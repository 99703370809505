import React from "react";
import { IntroductionContainer, TextContainer } from "./Introduction.style";
import { H2, P } from "../../../../App.style";
import { useInView } from "react-intersection-observer";
import { TitleContainer } from "../../Homepage.style";
import { useTranslation } from "react-i18next";

const Introduction = () => {
  const { t } = useTranslation();
  const titleInView = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const textInView = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  return (
    <IntroductionContainer>
      <TitleContainer ref={titleInView.ref} $isVisible={titleInView.inView}>
        <H2>{t("homepage.introduction.title")}</H2>
      </TitleContainer>
      <TextContainer ref={textInView.ref} $isVisible={textInView.inView}>
        <P>
          {t("homepage.introduction.subtitle.part1")} <br />
          {t("homepage.introduction.subtitle.part2")}
        </P>
      </TextContainer>
    </IntroductionContainer>
  );
};

export default Introduction;
