// Importy
import React from "react";
import styled from "styled-components";
import { H3, SuperButton } from "../../../../App.style";
import { t } from "i18next";

// Style karty
const HeroSelectorCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 8px;

  width: 95%;

  max-width: 320px;

  gap: 8px;
  padding: 18px 16px;
  height: 250px;

  margin: auto;
  background: #1d1e24;

  box-shadow: rgba(89, 29, 125, 0.4) 5px 5px, rgba(89, 29, 125, 0.3) 10px 10px,
    rgba(89, 29, 125, 0.2) 15px 15px, rgba(89, 29, 125, 0.1) 20px 20px,
    rgba(89, 29, 125, 0.05) 25px 25px;
`;

const Description = styled.p`
  text-align: center;
  color: white;
  margin-top: 5px;

  font-size: 15px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
`;

const CardIcon = styled.img<{ $przepowiednia?: boolean }>`
  width: ${({ $przepowiednia }) => ($przepowiednia ? `55px` : "60px")};

  margin-bottom: ${({ $przepowiednia }) => ($przepowiednia ? `5px` : "0")};
`;

// Typ propsów komponentu
interface HeroSelectorCardProps {
  icon: string;
  title: string;
  description: string;
  type: "tarot" | "przepowiednia" | "sen" | "coin";
  button: () => void;
}

const HeroSelectorCard: React.FC<HeroSelectorCardProps> = ({
  icon,
  title,
  description,
  type,
  button,
}) => {
  return (
    <HeroSelectorCardContainer>
      <CardIcon src={icon} $przepowiednia={type === "przepowiednia"} />
      <H3 style={{ fontWeight: 500 }}>{title}</H3>
      <Description>{description}</Description>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          bottom: 16,
          gap: 8,
        }}
      >
        <SuperButton type={"button"} onClick={button}>
          {t("homepage.wrozbaSelector.next")}
        </SuperButton>
      </div>
    </HeroSelectorCardContainer>
  );
};

export default HeroSelectorCard;
