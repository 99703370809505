import React from "react";
import { styled } from "styled-components";
import { signInWithGoogle } from "../api/sessionApi";
import googleLogo from "../../../assets/session/google.png";
import { useTranslation } from "react-i18next";
import { COLOUR_VIOLET } from "../../../App.style";

export const GoogleButtonContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;
  cursor: pointer;
`;

export const GoogleButton = styled.button`
  border-radius: 4px;
  padding: 8px;
  background: ${COLOUR_VIOLET};

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;

  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;

  color: white;

  &:hover {
    transform: scale(1.05);
  }
`;

const GoogleLoginButton: React.FC = () => {
  const { t } = useTranslation();
  return (
    <GoogleButtonContainer>
      <GoogleButton onClick={signInWithGoogle} type="button">
        <img style={{ width: 20, height: 20 }} alt={""} src={googleLogo} />
        {t("loginModal.login.google")}
      </GoogleButton>
    </GoogleButtonContainer>
  );
};

export default GoogleLoginButton;
