import { EuiFormRow, EuiFieldText } from "@elastic/eui";
import React, { useState } from "react";
import { Button, H3, H5 } from "../../../App.style";
import { WrozbaInputTitle } from "../../wrozba/Wrozba.style";
import { LoginFormContainer, RegisterContainer } from "./LoginModal.style";
import { passwordReset } from "../api/sessionApi";
import { useTranslation } from "react-i18next";

interface PasswordResetProps {
  onClose: () => void;
  handleLoginClick: () => void;
}

const PasswordReset: React.FC<PasswordResetProps> = ({
  onClose,
  handleLoginClick,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");

  const [passwordResetSent, setPasswordResetSent] = useState<boolean>(false);

  const isEmailValid = () => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);

  const [emailError, setEmailError] = useState<string>();
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!isEmailValid()) {
      setEmailError(t("loginModal.register.error.wrongEmail"));
    } else {
      try {
        await passwordReset(email).then(() => {
          setPasswordResetSent(true);
          setTimeout(() => {
            handleLoginClick();
          }, 8000);
        });
      } catch (error: any) {
        console.log(error);
      }
    }
  };
  return (
    <LoginFormContainer onSubmit={handleSubmit}>
      {passwordResetSent ? (
        <div style={{ textAlign: "center" }}>
          <H3>{t("loginModal.passwordReset.sent.line1")}</H3>
          <H3 style={{ marginTop: 10 }}>
            {t("loginModal.passwordReset.sent.line2")}
          </H3>
        </div>
      ) : (
        <>
          <EuiFormRow
            label={<WrozbaInputTitle>Email</WrozbaInputTitle>}
            style={{ marginBottom: 20 }}
            labelType="legend"
            isInvalid={emailError ? true : false}
            error={emailError}
          >
            <EuiFieldText
              isInvalid={emailError ? true : false}
              maxLength={40}
              style={{ background: "#0D0D0D" }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(undefined);
              }}
              compressed
            />
          </EuiFormRow>

          <Button type="submit">{t("loginModal.passwordReset.remind")}</Button>
        </>
      )}

      <RegisterContainer onClick={handleLoginClick}>
        <H5>{t("loginModal.passwordReset.backToLogin")}</H5>
      </RegisterContainer>
    </LoginFormContainer>
  );
};

export default PasswordReset;
