import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { Engine, ISourceOptions } from "tsparticles-engine";

export const ParticleComponent = () => {
  const particlesInit = async (main: Engine): Promise<void> => {
    await loadFull(main);
  };

  const particlesOptions: ISourceOptions = {
    fps_limit: 120,
    fullScreen: {
      enable: true,
      zIndex: 1,
    },
    particles: {
      number: {
        value: 350, //bylo 80
        density: {
          enable: true,
          value_area: 800,
        },
      },
      shape: {
        type: "circle", // Change this to 'circle', 'edge', 'triangle', 'polygon', 'star', or 'image'
      },
      color: {
        value: "#ffffff", // Use any hex color or an array of colors for a mix
      },
      opacity: {
        value: 0.7,
        anim: {
          enable: true,
          speed: 3,
          opacity_min: 0.1,
          sync: false,
        },
      },
      size: {
        value: 2, //3
        random: true,
        anim: {
          enable: true,
          speed: 2,
          size_min: 0.1,
          sync: false,
        },
      },
    },
    interactivity: {
      events: {
        onHover: {
          enable: true,
          mode: "repulse",
        },
        resize: true,
      },
      modes: {
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 80, // Adjust this value as needed
          duration: 0.2,
        },
      },
    },
    detectRetina: true,
    // ... other configuration options
  };

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={particlesOptions}
    />
  );
};
