import styled from "styled-components";
import { FONT_WHITE } from "../../App.style";

export const LegalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: black;
  z-index: 2;

  width: 100%;
  max-width: 800px;

  position: relative;

  padding: 80px 15px;

  @media (min-width: 350px) {
    padding: 80px 20px;
  }

  @media (min-width: 768px) {
    padding: 80px 30px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  margin-top: 30px;
  @media (min-width: 768px) {
    margin-top: 50px;
  }

  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;

  letter-spacing: 2px;

  color: ${FONT_WHITE};
`;

export const Subtitle = styled.h2`
  text-align: center;
  margin: 30px 0px;
  color: #666;
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  color: ${FONT_WHITE};
`;

export const Paragraph = styled.p<{ terms?: boolean }>`
  margin-top: ${(props) => (props.terms ? "10px" : "30px")};
  color: #666;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: ${FONT_WHITE};
`;

export const BulletList = styled.ul`
  color: white;
  font-size: 18px;
  margin-bottom: 5px;
`;
export const ListItem = styled.li`
  color: white;
  font-size: 18px;
  margin-bottom: 5px;
`;

export const NestedList = styled.ul`
  color: white;
  padding-left: 20px;
`;

export const NestedListItem = styled.li`
  color: white;
`;

export const PrivacyPolicyContentContainer = styled.div`
  width: 80%;
  border: 1px solid red;
  margin-top: 30px;

  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: clamp(15px, 2vw + 1rem, 15px);
  opacity: 0.8;

  color: #fff;

  letter-spacing: 1.5px;
  line-height: 20px;
`;
