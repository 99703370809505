import { useState } from "react";
import { FieldValue } from "@firebase/firestore";
import { UserTarotFormData } from "../../session/api/userApi";

export interface TarotRequest {
  id?: string;

  status: "pending" | "success" | "error";

  user_form_data: UserTarotFormData;

  ai_response: string;
  user_id: string | null;

  error_details?: string;

  refund_issued?: boolean;
  refund_issue_date?: FieldValue;

  created_at: FieldValue;
  updated_at?: FieldValue;

  lang: "PL" | "EN";
}

export const useCheckFreeTarot = () => {
  const [response, setResponse] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const checkFreeTarot = async (
    formData: UserTarotFormData,
    lang: string,
    user_id: string | null
  ) => {
    if (response !== null) return;

    setIsLoading(true);

    try {
      const response = await fetch(
        "https://europe-central2-fortune-teller-ai.cloudfunctions.net/getFreeTarot",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_form_data: formData,
            lang: lang,
            status: "success",
            ai_response: "",
            user_id: user_id,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`${response.status}: ${errorText}`);
      }

      const data = await response.json();
      setResponse(data);
    } catch (e) {
      setError(e instanceof Error ? e.message : "An unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return { checkFreeTarot, response, error, isLoading };
};
