import { useEffect, useState } from "react";
import { getUserFortunes } from "../../session/api/userApi";
import { FortuneRequest } from "../result/useCheckPayment";

const useUserFortunes = (userMail: string) => {
  const [fortunes, setFortunes] = useState<FortuneRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchFortunes = async () => {
      setLoading(true);
      try {
        const fetchedFortunes = await getUserFortunes(userMail);
        setFortunes(fetchedFortunes);
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        } else {
          setError(new Error("Unknown error occurred while fetching fortunes"));
        }
      } finally {
        setLoading(false);
      }
    };

    if (userMail) {
      fetchFortunes();
    }
  }, [userMail]);

  return { fortunes, loading, error };
};

export default useUserFortunes;
