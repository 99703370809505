import the_fool from "../../../assets/tarot_card_images/the_fool.png";
import the_magician from "../../../assets/tarot_card_images/the_magician.png";
import the_high_priestess from "../../../assets/tarot_card_images/the_high_priestess.png";
import the_empress from "../../../assets/tarot_card_images/the_empress.png";
import the_emperor from "../../../assets/tarot_card_images/the_emperor.png";
import the_hierophant from "../../../assets/tarot_card_images/the_hierophant.png";
import the_lovers from "../../../assets/tarot_card_images/the_lovers.png";
import the_chariot from "../../../assets/tarot_card_images/the_chariot.png";
import strenght from "../../../assets/tarot_card_images/strenght.png";
import the_hermit from "../../../assets/tarot_card_images/the_hermit.png";
import the_wheel_of_fortune from "../../../assets/tarot_card_images/the_wheel_of_fortune.png";
import justice from "../../../assets/tarot_card_images/justice.png";
import the_hanged_man from "../../../assets/tarot_card_images/the_hanged_man.png";
import death from "../../../assets/tarot_card_images/death.png";
import temperance from "../../../assets/tarot_card_images/temperance.png";
import the_devil from "../../../assets/tarot_card_images/the_devil.png";
import the_tower from "../../../assets/tarot_card_images/the_tower.png";
import the_star from "../../../assets/tarot_card_images/the_star.png";
import the_moon from "../../../assets/tarot_card_images/the_moon.png";
import the_sun from "../../../assets/tarot_card_images/the_sun.png";
import judgement from "../../../assets/tarot_card_images/judgement.png";
import the_world from "../../../assets/tarot_card_images/the_world.png";
import ace_of_swords from "../../../assets/tarot_card_images/ace_of_swords.png";
import two_of_swords from "../../../assets/tarot_card_images/two_of_swords.png";
import three_of_swords from "../../../assets/tarot_card_images/three_of_swords.png";
import four_of_swords from "../../../assets/tarot_card_images/four_of_swords.png";
import five_of_swords from "../../../assets/tarot_card_images/five_of_swords.png";
import six_of_swords from "../../../assets/tarot_card_images/six_of_swords.png";
import seven_of_swords from "../../../assets/tarot_card_images/seven_of_swords.png";
import eight_of_swords from "../../../assets/tarot_card_images/eight_of_swords.png";
import nine_of_swords from "../../../assets/tarot_card_images/nine_of_swords.png";
import ten_of_swords from "../../../assets/tarot_card_images/ten_of_swords.png";
import page_of_swords from "../../../assets/tarot_card_images/page_of_swords.png";
import knight_of_swords from "../../../assets/tarot_card_images/knight_of_swords.png";
import queen_of_swords from "../../../assets/tarot_card_images/queen_of_swords.png";
import king_of_swords from "../../../assets/tarot_card_images/king_of_swords.png";
import ace_of_wands from "../../../assets/tarot_card_images/ace_of_wands.png";
import two_of_wands from "../../../assets/tarot_card_images/two_of_wands.png";
import three_of_wands from "../../../assets/tarot_card_images/three_of_wands.png";
import four_of_wands from "../../../assets/tarot_card_images/four_of_wands.png";
import five_of_wands from "../../../assets/tarot_card_images/five_of_wands.png";
import six_of_wands from "../../../assets/tarot_card_images/six_of_wands.png";
import seven_of_wands from "../../../assets/tarot_card_images/seven_of_wands.png";
import eight_of_wands from "../../../assets/tarot_card_images/eight_of_wands.png";
import nine_of_wands from "../../../assets/tarot_card_images/nine_of_wands.png";
import ten_of_wands from "../../../assets/tarot_card_images/ten_of_wands.png";
import page_of_wands from "../../../assets/tarot_card_images/page_of_wands.png";
import knight_of_wands from "../../../assets/tarot_card_images/knight_of_wands.png";
import queen_of_wands from "../../../assets/tarot_card_images/queen_of_wands.png";
import king_of_wands from "../../../assets/tarot_card_images/king_of_wands.png";
import ace_of_cups from "../../../assets/tarot_card_images/ace_of_cups.png";
import two_of_cups from "../../../assets/tarot_card_images/two_of_cups.png";
import three_of_cups from "../../../assets/tarot_card_images/three_of_cups.png";
import four_of_cups from "../../../assets/tarot_card_images/four_of_cups.png";
import five_of_cups from "../../../assets/tarot_card_images/five_of_cups.png";
import six_of_cups from "../../../assets/tarot_card_images/six_of_cups.png";
import seven_of_cups from "../../../assets/tarot_card_images/seven_of_cups.png";
import eight_of_cups from "../../../assets/tarot_card_images/eight_of_cups.png";
import nine_of_cups from "../../../assets/tarot_card_images/nine_of_cups.png";
import ten_of_cups from "../../../assets/tarot_card_images/ten_of_cups.png";
import page_of_cups from "../../../assets/tarot_card_images/page_of_cups.png";
import knight_of_cups from "../../../assets/tarot_card_images/knight_of_cups.png";
import queen_of_cups from "../../../assets/tarot_card_images/queen_of_cups.png";
import king_of_cups from "../../../assets/tarot_card_images/king_of_cups.png";
import ace_of_pentacles from "../../../assets/tarot_card_images/ace_of_pentacles.png";
import two_of_pentacles from "../../../assets/tarot_card_images/two_of_pentacles.png";
import three_of_pentacles from "../../../assets/tarot_card_images/three_of_pentacles.png";
import four_of_pentacles from "../../../assets/tarot_card_images/four_of_pentacles.png";
import five_of_pentacles from "../../../assets/tarot_card_images/five_of_pentacles.png";
import six_of_pentacles from "../../../assets/tarot_card_images/six_of_pentacles.png";
import seven_of_pentacles from "../../../assets/tarot_card_images/seven_of_pentacles.png";
import eight_of_pentacles from "../../../assets/tarot_card_images/eight_of_pentacles.png";
import nine_of_pentacles from "../../../assets/tarot_card_images/nine_of_pentacles.png";
import ten_of_pentacles from "../../../assets/tarot_card_images/ten_of_pentacles.png";
import page_of_pentacles from "../../../assets/tarot_card_images/page_of_pentacles.png";
import knight_of_pentacles from "../../../assets/tarot_card_images/knight_of_pentacles.png";
import queen_of_pentacles from "../../../assets/tarot_card_images/queen_of_pentacles.png";
import king_of_pentacles from "../../../assets/tarot_card_images/king_of_pentacles.png";
import back_image from "../../../assets/tarot_card_images/tarot_card_back.png";
import { useTranslation } from "react-i18next";

export interface TarotCard {
  id: string; //pokazuje ktora karte na widoku szczegolowym
  src: string; //link
  text: string; //nazwa karty,
  description: string; //opis karty??
}

export const useTarotCards = () => {
  const { t } = useTranslation();

  const tarotCards: TarotCard[] = [
    // {
    //   id: "tarot_back_image",
    //   src: back_image,
    //   text: "Tył karty XD",
    //   description: "",
    // },

    {
      id: "tarot_the_fool",
      src: the_fool,
      text: t("tarot.card1.name"),
      description: "Smieszna karcioszka",
    },
    {
      id: "tarot_the_magician",
      src: the_magician,
      text: t("tarot.card2.name"),
      description: "",
    },

    {
      id: "tarot_the_high_priestess",
      src: the_high_priestess,
      text: t("tarot.card3.name"),
      description: "",
    },

    {
      id: "tarot_the_empress",
      src: the_empress,
      text: t("tarot.card4.name"),
      description: "",
    },
    {
      id: "tarot_the_emperor",
      src: the_emperor,
      text: t("tarot.card5.name"),
      description: "",
    },
    {
      id: "tarot_the_hierophant",
      src: the_hierophant,
      text: t("tarot.card6.name"),
      description: "",
    },
    {
      id: "tarot_the_lovers",
      src: the_lovers,
      text: t("tarot.card7.name"),
      description: "",
    },
    {
      id: "tarot_the_chariot",
      src: the_chariot,
      text: t("tarot.card8.name"),
      description: "",
    },
    {
      id: "tarot_strenght",
      src: strenght,
      text: t("tarot.card9.name"),
      description: "",
    },
    {
      id: "tarot_the_hermit",
      src: the_hermit,
      text: t("tarot.card10.name"),
      description: "",
    },
    {
      id: "tarot_the_wheel_of_fortune",
      src: the_wheel_of_fortune,
      text: t("tarot.card11.name"),
      description: "",
    },
    {
      id: "tarot_justice",
      src: justice,
      text: t("tarot.card12.name"),
      description: "",
    },
    {
      id: "tarot_the_hanged_man",
      src: the_hanged_man,
      text: t("tarot.card13.name"),
      description: "",
    },
    {
      id: "tarot_death",
      src: death,
      text: t("tarot.card14.name"),
      description: "",
    },
    {
      id: "tarot_temperance",
      src: temperance,
      text: t("tarot.card15.name"),
      description: "",
    },
    {
      id: "tarot_the_devil",
      src: the_devil,
      text: t("tarot.card16.name"),
      description: "",
    },
    {
      id: "tarot_the_tower",
      src: the_tower,
      text: t("tarot.card17.name"),
      description: "",
    },
    {
      id: "tarot_the_the_star",
      src: the_star,
      text: t("tarot.card18.name"),
      description: "",
    },

    {
      id: "tarot_the_moon",
      src: the_moon,
      text: t("tarot.card19.name"),
      description: "",
    },

    {
      id: "tarot_the_sun",
      src: the_sun,
      text: t("tarot.card20.name"),
      description: "",
    },

    {
      id: "tarot_judgement",
      src: judgement,
      text: t("tarot.card21.name"),
      description: "",
    },

    {
      id: "tarot_the_world",
      src: the_world,
      text: t("tarot.card22.name"),
      description: "",
    },

    //kubki
    {
      id: "tarot_ace_of_cups",
      src: ace_of_cups,
      text: t("tarot.card23.name"),
      description: "",
    },

    {
      id: "tarot_two_of_cups",
      src: two_of_cups,
      text: t("tarot.card24.name"),
      description: "",
    },
    {
      id: "tarot_three_of_cups",
      src: three_of_cups,
      text: t("tarot.card25.name"),
      description: "",
    },
    {
      id: "tarot_four_of_cups",
      src: four_of_cups,
      text: t("tarot.card26.name"),
      description: "",
    },
    {
      id: "tarot_five_of_cups",
      src: five_of_cups,
      text: t("tarot.card27.name"),
      description: "",
    },
    {
      id: "tarot_six_of_cups",
      src: six_of_cups,
      text: t("tarot.card28.name"),
      description: "",
    },
    {
      id: "tarot_seven_of_cups",
      src: seven_of_cups,
      text: t("tarot.card29.name"),
      description: "",
    },
    {
      id: "tarot_eight_of_cups",
      src: eight_of_cups,
      text: t("tarot.card30.name"),
      description: "",
    },
    {
      id: "tarot_nine_of_cups",
      src: nine_of_cups,
      text: t("tarot.card31.name"),
      description: "",
    },
    {
      id: "tarot_ten_of_cups",
      src: ten_of_cups,
      text: t("tarot.card32.name"),
      description: "",
    },
    {
      id: "tarot_page_of_cups",
      src: page_of_cups,
      text: t("tarot.card33.name"),
      description: "",
    },
    {
      id: "tarot_knight_of_cups",
      src: knight_of_cups,
      text: t("tarot.card34.name"),
      description: "",
    },
    {
      id: "tarot_queen_of_cups",
      src: queen_of_cups,
      text: t("tarot.card35.name"),
      description: "",
    },
    {
      id: "tarot_king_of_cups",
      src: king_of_cups,
      text: t("tarot.card36.name"),
      description: "",
    },

    //miecze
    {
      id: "tarot_ace_of_swords",
      src: ace_of_swords,
      text: t("tarot.card37.name"),
      description: "",
    },

    {
      id: "tarot_two_of_swords",
      src: two_of_swords,
      text: t("tarot.card38.name"),
      description: "",
    },
    {
      id: "tarot_three_of_swords",
      src: three_of_swords,
      text: t("tarot.card39.name"),
      description: "",
    },
    {
      id: "tarot_four_of_swords",
      src: four_of_swords,
      text: t("tarot.card40.name"),
      description: "",
    },
    {
      id: "tarot_five_of_swords",
      src: five_of_swords,
      text: t("tarot.card41.name"),
      description: "",
    },
    {
      id: "tarot_six_of_swords",
      src: six_of_swords,
      text: t("tarot.card42.name"),
      description: "",
    },
    {
      id: "tarot_seven_of_swords",
      src: seven_of_swords,
      text: t("tarot.card43.name"),
      description: "",
    },
    {
      id: "tarot_eight_of_swords",
      src: eight_of_swords,
      text: t("tarot.card44.name"),
      description: "",
    },
    {
      id: "tarot_nine_of_swords",
      src: nine_of_swords,
      text: t("tarot.card45.name"),
      description: "",
    },
    {
      id: "tarot_ten_of_swords",
      src: ten_of_swords,
      text: t("tarot.card46.name"),
      description: "",
    },
    {
      id: "tarot_page_of_swords",
      src: page_of_swords,
      text: t("tarot.card47.name"),
      description: "",
    },
    {
      id: "tarot_knight_of_swords",
      src: knight_of_swords,
      text: t("tarot.card48.name"),
      description: "",
    },
    {
      id: "tarot_queen_of_swords",
      src: queen_of_swords,
      text: t("tarot.card49.name"),
      description: "",
    },
    {
      id: "tarot_king_of_swords",
      src: king_of_swords,
      text: t("tarot.card50.name"),
      description: "",
    },

    //zmiana

    {
      id: "tarot_ace_of_wands",
      src: ace_of_wands,
      text: t("tarot.card51.name"),
      description: "",
    },

    {
      id: "tarot_two_of_wands",
      src: two_of_wands,
      text: t("tarot.card52.name"),
      description: "",
    },
    {
      id: "tarot_three_of_wands",
      src: three_of_wands,
      text: t("tarot.card53.name"),
      description: "",
    },
    {
      id: "tarot_four_of_wands",
      src: four_of_wands,
      text: t("tarot.card54.name"),
      description: "",
    },
    {
      id: "tarot_five_of_wands",
      src: five_of_wands,
      text: t("tarot.card55.name"),
      description: "",
    },
    {
      id: "tarot_six_of_wands",
      src: six_of_wands,
      text: t("tarot.card56.name"),
      description: "",
    },
    {
      id: "tarot_seven_of_wands",
      src: seven_of_wands,
      text: t("tarot.card57.name"),
      description: "",
    },
    {
      id: "tarot_eight_of_wands",
      src: eight_of_wands,
      text: t("tarot.card58.name"),
      description: "",
    },
    {
      id: "tarot_nine_of_wands",
      src: nine_of_wands,
      text: t("tarot.card59.name"),
      description: "",
    },
    {
      id: "tarot_ten_of_wands",
      src: ten_of_wands,
      text: t("tarot.card60.name"),
      description: "",
    },
    {
      id: "tarot_page_of_wands",
      src: page_of_wands,
      text: t("tarot.card61.name"),
      description: "",
    },
    {
      id: "tarot_knight_of_wands",
      src: knight_of_wands,
      text: t("tarot.card62.name"),
      description: "",
    },
    {
      id: "tarot_queen_of_wands",
      src: queen_of_wands,
      text: t("tarot.card63.name"),
      description: "",
    },
    {
      id: "tarot_king_of_wands",
      src: king_of_wands,
      text: t("tarot.card64.name"),
      description: "",
    },

    // zmiana

    {
      id: "tarot_ace_of_pentacles",
      src: ace_of_pentacles,
      text: t("tarot.card65.name"),
      description: "",
    },

    {
      id: "tarot_two_of_pentacles",
      src: two_of_pentacles,
      text: t("tarot.card66.name"),
      description: "",
    },
    {
      id: "tarot_three_of_pentacles",
      src: three_of_pentacles,
      text: t("tarot.card67.name"),
      description: "",
    },
    {
      id: "tarot_four_of_pentacles",
      src: four_of_pentacles,
      text: t("tarot.card68.name"),
      description: "",
    },
    {
      id: "tarot_five_of_pentacles",
      src: five_of_pentacles,
      text: t("tarot.card69.name"),
      description: "",
    },
    {
      id: "tarot_six_of_pentacles",
      src: six_of_pentacles,
      text: t("tarot.card70.name"),
      description: "",
    },
    {
      id: "tarot_seven_of_pentacles",
      src: seven_of_pentacles,
      text: t("tarot.card71.name"),
      description: "",
    },
    {
      id: "tarot_eight_of_pentacles",
      src: eight_of_pentacles,
      text: t("tarot.card72.name"),
      description: "",
    },
    {
      id: "tarot_nine_of_pentacles",
      src: nine_of_pentacles,
      text: t("tarot.card73.name"),
      description: "",
    },
    {
      id: "tarot_ten_of_pentacles",
      src: ten_of_pentacles,
      text: t("tarot.card74.name"),
      description: "",
    },
    {
      id: "tarot_page_of_pentacles",
      src: page_of_pentacles,
      text: t("tarot.card75.name"),
      description: "",
    },
    {
      id: "tarot_knight_of_pentacles",
      src: knight_of_pentacles,
      text: t("tarot.card76.name"),
      description: "",
    },
    {
      id: "tarot_queen_of_pentacles",
      src: queen_of_pentacles,
      text: t("tarot.card77.name"),
      description: "",
    },
    {
      id: "tarot_king_of_pentacles",
      src: king_of_pentacles,
      text: t("tarot.card78.name"),
      description: "",
    },
  ];

  function shuffleArray(array: TarotCard[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  function getRandomTarotCards(count: number): TarotCard[] {
    shuffleArray(tarotCards);
    return tarotCards.slice(0, count);
  }

  const getTarotCardsById = (ids: string[]): TarotCard[] => {
    return tarotCards.filter((obj) => ids.includes(obj.id));
  };

  return {
    tarotCards,
    getRandomTarotCards,
    getTarotCardsById,
  };
};
