import React from "react";
import {
  FooterBottomContainer,
  FooterContainer,
  FooterLanguageSelectorContainer,
  FooterPrivacyContainer,
  FooterText,
  FooterTopContainer,
} from "./Footer.style";
import LanguageSelector from "./languageSelector/LanguageSelector";
import { useNavigate } from "react-router-dom";
import { P } from "../../App.style";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <FooterTopContainer>
        <FooterText href="mailto:contact@mysticer.com">
          contact@mysticer.com{" "}
        </FooterText>
      </FooterTopContainer>
      <FooterBottomContainer>
        <FooterLanguageSelectorContainer>
          <LanguageSelector />
        </FooterLanguageSelectorContainer>

        <div style={{ display: "flex", gap: 20 }}>
          <FooterPrivacyContainer
            onClick={() => navigate("/terms")}
            style={{ marginBottom: 5 }} //dodac tu underline na hover
          >
            <P>{t("footer.legal.terms")}</P>
          </FooterPrivacyContainer>

          <FooterPrivacyContainer onClick={() => navigate("/privacy")}>
            <P>{t("footer.legal.privacy")}</P>
          </FooterPrivacyContainer>
        </div>
      </FooterBottomContainer>
    </FooterContainer>
  );
};

export default Footer;
