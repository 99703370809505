import styled from "styled-components";
import { fadeInAnimation } from "../../Homepage.style";

export const IntroductionContainer = styled.div`
  max-width: 1100px;
  margin-top: 50px;
  width: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  padding: 0px 10px;

  margin-bottom: 80px;

  @media (min-width: 320px) {
    padding: 0px 20px;
  }

  @media (min-width: 600px) {
    padding: 0px 40px;
  }
`;

export const TextContainer = styled.div<{ $isVisible: boolean }>`
  opacity: 0;
  transform: translateY(40px);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, visibility 0.6s;
  ${({ $isVisible }) => $isVisible && fadeInAnimation}
`;
