import styled, { keyframes } from "styled-components";

export const FONT_WHITE = "#ffffff";

export const H1 = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: clamp(30px, 5vw + 1rem, 60px);
  font-style: normal;
  font-weight: 500;

  letter-spacing: 2px;

  color: ${FONT_WHITE};
`;

export const H2 = styled.h2`
  font-family: "Roboto", sans-serif;
  font-size: clamp(19px, 3vw + 1rem, 32px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 4px;

  color: ${FONT_WHITE};
`;

export const H3 = styled.h3`
  font-family: "Roboto", sans-serif;
  font-size: clamp(18px, 4vw - 1rem, 24px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.16px;

  color: ${FONT_WHITE};
`;

export const H4 = styled.h4`
  font-family: "Noto Sans", sans-serif;
  font-size: clamp(15px, 3vw - 1rem, 21px);

  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
  letter-spacing: 1.71px;

  color: ${FONT_WHITE};
`;

export const H5 = styled.h5`
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;

  font-style: normal;
  font-weight: 400;
  line-height: normal;

  color: ${FONT_WHITE};
  opacity: 1;
`;

export const H6 = styled.h6`
  font-family: "Noto Sans", sans-serif;
  font-size: 10px;

  font-style: normal;
  font-weight: 400;
  line-height: normal;

  color: ${FONT_WHITE};
  opacity: 0.6;
`;

export const P = styled.p`
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  opacity: 0.8;

  color: #fff;

  letter-spacing: 1.5px;
  line-height: 20px;
`;

export const AppContainer = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  align-items: center;

  background: #000000;
`;

// Keyframes for the background animation of the button
const backgroundAnimate = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const glowing = keyframes`
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
`;

// Styled component for the glowing button
export const SuperButton = styled.button`
  background: linear-gradient(270deg, #9d58c7 0.21%, #400c61 101.28%);
  background-size: 200% 200%; // For gradient animation
  position: relative;
  padding: 10px 16px;

  border-radius: 8px;

  color: white;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: ${backgroundAnimate} 3s linear infinite;

  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:before {
    content: "";
    background: linear-gradient(
      45deg,
      #2800a0,
      #3a199e,
      #462e90,
      #5c4995,
      #6b608d,
      #7e788f,
      #8c8b8e,
      #7e788f,
      #6b608d,
      #5c4995,
      #462e90,
      #3a199e,
      #2800a0
    );
    opacity: 0.8;
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: ${glowing} 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }
`;

export const COLOUR_VIOLET = "#591d7d";

export const Button = styled.button`
  background: ${COLOUR_VIOLET};

  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex-shrink: 0;

  min-width: 100px;

  padding: 10px 16px;
  text-align: center;
  transition: all 0.5s;
  letter-spacing: 1.26px;

  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    background: grey;
    cursor: not-allowed;
  }
`;

export const ButtonSecondary = styled.button`
  background: #292929;

  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex-shrink: 0;

  min-width: 80px;

  padding: 8px 15px;
  text-align: center;
  transition: all 0.5s;
  letter-spacing: 1.26px;

  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;

  transition: all 0.5s;
  &:hover {
    background: #591d7d;
    transform: scale(1.08);
  }
`;

export const ButtonEmpty = styled.button`
  color: #fff;
  opacity: 0.8;
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 1.08px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
