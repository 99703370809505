import React, { useEffect, useState } from "react";
import { HomepageContainer } from "./Homepage.style";
import Hero from "./sections/hero/Hero";
import Section from "./Section";
import { User } from "firebase/auth";
import "../wrozba/customCss.css";
import Introduction from "./sections/introduction/Introduction";
import HowItworks from "./sections/how_it_works/HowItworks";
import FAQ from "./sections/faq/FAQ";
import Socials from "./sections/socials/Socials";
import { useLocation } from "react-router-dom";
import { FirestoreUser } from "../session/api/userApi";

interface HomepageProps {
  currentUser: User | null;
  firestoreUser: FirestoreUser | null;
  openLoginModal: () => void;
  openContactModal: () => void;
}

const Homepage: React.FC<HomepageProps> = ({
  currentUser,
  openLoginModal,
  openContactModal,
  firestoreUser,
}) => {
  const location = useLocation();

  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.replace("#", "");
      if (["hero", "selector", "tarot", "fortune"].includes(hash)) {
        // If the hash is one of the component states, update the state
        setHeroState(hash as "hero" | "selector" | "tarot" | "fortune");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      } else {
        // Otherwise, proceed with scrolling to the element
        const element = document.getElementById(hash);
        if (element) {
          const offsetTop = element.offsetTop - 100; // 100px offset
          window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
          });

          // Removing the hash from the URL while keeping the page state
          window.history.pushState(
            "",
            document.title,
            window.location.pathname + window.location.search
          );
        }
      }
    }
  }, [location]);

  const [heroState, setHeroState] = useState<
    "hero" | "selector" | "tarot" | "fortune" | "dreams"
  >("hero");

  return (
    <HomepageContainer>
      <Hero
        currentUser={currentUser}
        firestoreUser={firestoreUser}
        heroState={heroState}
        setHeroState={setHeroState}
        openLoginModal={openLoginModal}
      />

      <Introduction />

      <Section id="how">
        <HowItworks />
      </Section>

      <Section id="help">
        <FAQ
          currentUser={currentUser}
          openLoginModal={openLoginModal}
          openContactModal={openContactModal}
        />
      </Section>

      <Section id="socials">
        <Socials />
      </Section>
    </HomepageContainer>
  );
};

export default Homepage;
