import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { H2, H3 } from "../../../App.style";
import {
  WrozbaResultContainer,
  WrozbaResultTitleContainer,
  WrozbaResultContentContainer,
  Canvas,
  Moon,
  LoadingBottomContainer,
  LoadingSentenceContainer,
  LoadingWord,
  ProgressBarContainer,
  ProgressValue,
} from "../../wrozba/result/WrozbaResult.style";

const TarotFreeLoading = () => {
  const getRandomSentenceIndex = (currentIndex: number, length: number) => {
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * length);
    } while (newIndex === currentIndex);
    return newIndex;
  };

  const sentences: string[] = [
    t("wrozbaResult.loading.sentences.1"),
    t("wrozbaResult.loading.sentences.2"),
    t("wrozbaResult.loading.sentences.3"),
    t("wrozbaResult.loading.sentences.4"),
    t("wrozbaResult.loading.sentences.5"),
    t("wrozbaResult.loading.sentences.6"),
    t("wrozbaResult.loading.sentences.7"),
    t("wrozbaResult.loading.sentences.8"),
    t("wrozbaResult.loading.sentences.9"),
    t("wrozbaResult.loading.sentences.10"),
    t("wrozbaResult.loading.sentences.11"),
    t("wrozbaResult.loading.sentences.12"),
    t("wrozbaResult.loading.sentences.13"),
    t("wrozbaResult.loading.sentences.14"),
  ];

  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = getRandomSentenceIndex(
        currentSentenceIndex,
        sentences.length
      );
      setCurrentSentenceIndex(newIndex);
    }, 4000); // Change sentence every 3 seconds

    return () => clearInterval(interval);
  }, [currentSentenceIndex, sentences.length]);

  const words = sentences[currentSentenceIndex].split(" ");
  return (
    <WrozbaResultContainer style={{ marginTop: -80 }}>
      <WrozbaResultTitleContainer>
        <H2>{t("wrozbaResult.loading.title")}</H2>
        <H3 style={{ marginTop: 30 }}>{t("wrozbaResult.loading.subtitle")}</H3>
      </WrozbaResultTitleContainer>
      <WrozbaResultContentContainer>
        <Canvas>
          <Moon />
        </Canvas>

        <LoadingBottomContainer>
          <LoadingSentenceContainer key={currentSentenceIndex}>
            {words.map((word, index) => (
              <LoadingWord
                key={`word-${index}`}
                style={{ animationDelay: `${index * 0.1}s` }}
              >
                {word}
              </LoadingWord>
            ))}
          </LoadingSentenceContainer>
          <ProgressBarContainer>
            <ProgressValue />
          </ProgressBarContainer>
        </LoadingBottomContainer>
      </WrozbaResultContentContainer>
    </WrozbaResultContainer>
  );
};

export default TarotFreeLoading;
