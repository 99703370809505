import { EuiFormRow, EuiFieldText, EuiFieldPassword } from "@elastic/eui";
import React, { useState } from "react";
import { Button, H5 } from "../../../App.style";
import { WrozbaInputTitle } from "../../wrozba/Wrozba.style";
import { signIn } from "../api/sessionApi";
import {
  ForgotPasswordContaienr,
  LoginFormContainer,
  RegisterContainer,
} from "./LoginModal.style";
import { useTranslation } from "react-i18next";

interface LoginFormProps {
  onClose: () => void;
  handlePasswordResetClick: () => void;
  handleRegisterClick: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  onClose,
  handleRegisterClick,
  handlePasswordResetClick,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [signInApiError, setSignInApiError] = useState<string>();

  const [emailError, setEmailError] = useState<boolean>(false);

  const isEmailValid = () => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!isEmailValid()) {
      setEmailError(true);
      return;
    }

    try {
      await signIn(email, password);
      onClose();
    } catch (error) {
      setSignInApiError(t("loginModal.login.error")); // Generic error message
    }
  };
  return (
    <LoginFormContainer onSubmit={handleSubmit}>
      <EuiFormRow
        label={<WrozbaInputTitle>Email</WrozbaInputTitle>}
        labelType="legend"
        isInvalid={signInApiError ? true : emailError}
        error={
          signInApiError
            ? signInApiError
            : emailError && t("loginModal.login.wrongEmail")
        }
      >
        <EuiFieldText
          isInvalid={signInApiError ? true : emailError}
          maxLength={40}
          style={{ background: "#0D0D0D" }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setSignInApiError(undefined);
            setEmailError(false);
          }}
          compressed
        />
      </EuiFormRow>

      <EuiFormRow
        style={{ marginTop: 0 }}
        label={
          <WrozbaInputTitle>{t("loginModal.login.password")}</WrozbaInputTitle>
        }
        labelType="legend"
        isInvalid={signInApiError ? true : false}
      >
        <EuiFieldPassword
          isInvalid={signInApiError ? true : false}
          type="dual"
          maxLength={40}
          style={{
            background: "#0D0D0D",
            border: "none",
            outline: "none",
          }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setSignInApiError(undefined);
          }}
          compressed
        />
      </EuiFormRow>
      <ForgotPasswordContaienr onClick={handlePasswordResetClick}>
        {t("loginModal.login.forgotPassword")}
      </ForgotPasswordContaienr>
      <Button type="submit">{t("loginModal.login.login")}</Button>

      <RegisterContainer>
        <H5>{t("loginModal.login.dontHaveAcc")}</H5>{" "}
        <H5
          className="register_link"
          style={{ opacity: 0.6 }}
          onClick={handleRegisterClick}
        >
          {t("loginModal.login.register")}
        </H5>
      </RegisterContainer>
    </LoginFormContainer>
  );
};

export default LoginForm;
