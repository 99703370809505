import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SelectorContainer,
  OptionsContainer,
  PopoverOption,
} from "../../navbar/accountPopover/AccountPopover.style";
import arrowUp from "../../../assets/navbar/arrow-up.png";
import arrowDown from "../../../assets/navbar/arrow-down.png";
import {
  LanguageSelectorSelectedOption,
  LanguageSelectorText,
} from "./LanguageSelector.style";

const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lang: "pl-PL" | "en") => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    localStorage.setItem("preferredLanguage", lang);
  };

  return (
    <SelectorContainer
      onClick={toggleDropdown}
      ref={containerRef}
      style={{ marginTop: 0 }}
    >
      <LanguageSelectorSelectedOption>
        <LanguageSelectorText>
          {i18n.language === "pl-PL" ? "Polski" : "English"}
        </LanguageSelectorText>

        <img alt="arrow" src={isOpen ? arrowUp : arrowDown} />
      </LanguageSelectorSelectedOption>
      {isOpen && (
        <OptionsContainer $top>
          <PopoverOption
            selected={i18n.language === "pl-PL"}
            onClick={() => changeLanguage("pl-PL")}
          >
            <LanguageSelectorText>Polski</LanguageSelectorText>
          </PopoverOption>
          <PopoverOption
            selected={i18n.language !== "pl-PL"}
            onClick={() => changeLanguage("en")}
          >
            <LanguageSelectorText>English</LanguageSelectorText>
          </PopoverOption>
        </OptionsContainer>
      )}
    </SelectorContainer>
  );
};

export default LanguageSelector;
