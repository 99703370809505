import React, { useEffect, useState } from "react";
import { FirestoreUser, UserTarotFormData } from "../../session/api/userApi";
import {
  EuiFormRow,
  EuiFieldText,
  EuiSuperSelect,
  EuiButtonEmpty,
  EuiText,
} from "@elastic/eui";
import {
  CheckboxContainer,
  CheckboxLabel,
  HiddenCheckbox,
  StyledCheckbox,
  TermsLink,
  WrozbaInputTitle,
} from "../../wrozba/Wrozba.style";
import {
  TarotFormFortunesCardsContainer,
  TarotFormStep1Container,
} from "../Tarot.style";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../../../translations/i18n";
import { Button, P } from "../../../App.style";
import "./customCss.css";
import { relationStatusCategories } from "../../wrozba/WrozbaForm";
import { User } from "firebase/auth";
import TarotFormOption from "./TarotFormOption";
import edukacjaIcon from "../../../assets/tarotForm/edukacja.png";
import finanseIcon from "../../../assets/tarotForm/finanse_i_kariera.png";
import marzeniaIcon from "../../../assets/tarotForm/marzenia_i_aspiraje.png";
import miloscIcon from "../../../assets/tarotForm/love.png";
import ogolneIcon from "../../../assets/tarotForm/ogolne.png";
import rodzinaIcon from "../../../assets/tarotForm/rodzina_i_relacje.png";
import rozwojIcon from "../../../assets/tarotForm/rozwoj_osobisty.png";
import zdrowieIcon from "../../../assets/tarotForm/zdrowie.png";
import cardGroup1 from "../../../assets/tarotForm/group1.png";
import cardGroup3 from "../../../assets/tarotForm/group3.png";
import cardGroup5 from "../../../assets/tarotForm/group5.png";

interface TarotFormStep1Props {
  tarot: UserTarotFormData;
  setTarot: React.Dispatch<React.SetStateAction<UserTarotFormData>>;
  setCurrentStep: (value: React.SetStateAction<number>) => void;
  currentUser: User | null;
  firestoreUser: FirestoreUser | null;
  openLoginModal: () => void;
}

const TarotFormStep1: React.FC<TarotFormStep1Props> = ({
  tarot,
  setTarot,
  setCurrentStep,
  currentUser,
  openLoginModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  //validation state
  const [isFieldValid, setIsFieldValid] = useState({
    name: true,
    fortune: true,
    relationship_status: true,
    cardsCount: true,
    terms: true,
  });

  //validatoin function
  const validateStep1 = () => {
    const newValidationState = {
      name: !!tarot.name,
      fortune: !!tarot.fortune,
      relationship_status:
        tarot.fortune !== "Love" && tarot.fortune !== "Miłość"
          ? true
          : !!tarot.relationship_status,
      cardsCount: !!tarot.cardsCount,
      terms: true,
    };

    setIsFieldValid(newValidationState);

    for (const [key, isValid] of Object.entries(newValidationState)) {
      if (!isValid) {
        return `${key}`;
      }
    }

    return null;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const invalidField = validateStep1();
    console.log("elooo");

    if (invalidField) {
      const element = document.getElementById(invalidField);
      element?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return; // Stop form submission since there's a validation error
    }

    //jak wszystko git to idzie dalej
    window.scrollTo(0, 0);
    setCurrentStep((prev) => prev + 1);
  };

  const handleBackButtonClick = () => {
    navigate("#selector");
  };

  const createSelectOptions = (
    items: Record<string, string>,
    sort?: boolean
  ) => {
    const isPolish = i18n.language === "pl-PL";

    let options = Object.entries(items).map(([englishName, polishName]) => {
      return {
        value: isPolish ? polishName : englishName,
        inputDisplay: isPolish ? polishName : englishName,
      };
    });

    if (sort) {
      options = options.sort((a, b) => {
        if (a.inputDisplay < b.inputDisplay) {
          return -1;
        }
        if (a.inputDisplay > b.inputDisplay) {
          return 1;
        }
        return 0;
      });
    }

    return options;
  };

  const fortunes_categories_options = createSelectOptions(fortunesCategories);

  const relationship_status_options = createSelectOptions(
    relationStatusCategories
  );

  useEffect(() => {
    if (tarot.fortune !== "Miłość" && tarot.fortune !== "Love") {
      setTarot({ ...tarot, relationship_status: "" });
    }
  }, [tarot.fortune]); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    return;
  };

  const scrollTo100 = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetTop =
        element.getBoundingClientRect().top + window.pageYOffset - 120;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <TarotFormStep1Container id="form" onSubmit={handleSubmit}>
      <div id={"name"}>
        <EuiFormRow
          label={
            <WrozbaInputTitle>{t("fortuneModal.step1.name")}</WrozbaInputTitle>
          }
          labelType="legend"
          isInvalid={!isFieldValid.name}
          error={t("fortuneModal.requiredError")}
        >
          <EuiFieldText
            maxLength={20}
            style={{ background: "#0D0D0D" }}
            value={tarot.name}
            onChange={(e) => {
              setTarot({ ...tarot, name: e.target.value });
              setIsFieldValid({
                ...isFieldValid,
                name: !!e.target.value,
              });
            }}
            compressed
          />
        </EuiFormRow>
      </div>

      <div id="fortune">
        <EuiFormRow
          style={{ marginTop: 30 }}
          label={
            <WrozbaInputTitle>
              {t("fortuneModal.step2.fortuneTopic")}
            </WrozbaInputTitle>
          }
          labelType="legend"
          isInvalid={!isFieldValid.fortune}
          error={t("fortuneModal.requiredError")}
        >
          <TarotFormFortunesCardsContainer>
            {/* ogólne */}
            <TarotFormOption
              isSelected={
                tarot.fortune === fortunes_categories_options[0].value
              }
              imgSrc={ogolneIcon}
              width={47}
              onClick={() => {
                setTarot({
                  ...tarot,
                  fortune: fortunes_categories_options[0].value,
                });
                setIsFieldValid({ ...isFieldValid, fortune: true });
                scrollTo100("cardsCount");
              }}
              text={fortunes_categories_options[0].inputDisplay}
            />

            {/* milosc */}
            <TarotFormOption
              isSelected={
                tarot.fortune === fortunes_categories_options[1].value
              }
              imgSrc={miloscIcon}
              width={47}
              onClick={() => {
                setTarot({
                  ...tarot,
                  fortune: fortunes_categories_options[1].value,
                });
                setIsFieldValid({ ...isFieldValid, fortune: true });
                scrollTo100("relationship_status");
              }}
              text={fortunes_categories_options[1].inputDisplay}
            />

            {/* zdrowie */}
            <TarotFormOption
              isSelected={
                tarot.fortune === fortunes_categories_options[2].value
              }
              imgSrc={zdrowieIcon}
              width={47}
              onClick={() => {
                setTarot({
                  ...tarot,
                  fortune: fortunes_categories_options[2].value,
                });
                setIsFieldValid({ ...isFieldValid, fortune: true });
                scrollTo100("cardsCount");
              }}
              text={fortunes_categories_options[2].inputDisplay}
            />

            {/* kariera i finanse */}
            <TarotFormOption
              isSelected={
                tarot.fortune === fortunes_categories_options[3].value
              }
              imgSrc={finanseIcon}
              width={47}
              onClick={() => {
                setTarot({
                  ...tarot,
                  fortune: fortunes_categories_options[3].value,
                });
                setIsFieldValid({ ...isFieldValid, fortune: true });
                scrollTo100("cardsCount");
              }}
              text={fortunes_categories_options[3].inputDisplay}
            />

            {/* edukacja */}
            <TarotFormOption
              isSelected={
                tarot.fortune === fortunes_categories_options[4].value
              }
              imgSrc={edukacjaIcon}
              width={47}
              onClick={() => {
                setTarot({
                  ...tarot,
                  fortune: fortunes_categories_options[4].value,
                });
                setIsFieldValid({ ...isFieldValid, fortune: true });
                scrollTo100("cardsCount");
              }}
              text={fortunes_categories_options[4].inputDisplay}
            />

            {/* rodzina i relacje */}
            <TarotFormOption
              isSelected={
                tarot.fortune === fortunes_categories_options[5].value
              }
              imgSrc={rodzinaIcon}
              width={47}
              onClick={() => {
                setTarot({
                  ...tarot,
                  fortune: fortunes_categories_options[5].value,
                });
                setIsFieldValid({ ...isFieldValid, fortune: true });
                scrollTo100("cardsCount");
              }}
              text={fortunes_categories_options[5].inputDisplay}
            />

            {/* rozwoj osobisty */}
            <TarotFormOption
              isSelected={
                tarot.fortune === fortunes_categories_options[6].value
              }
              imgSrc={rozwojIcon}
              width={47}
              onClick={() => {
                setTarot({
                  ...tarot,
                  fortune: fortunes_categories_options[6].value,
                });
                setIsFieldValid({ ...isFieldValid, fortune: true });
                scrollTo100("cardsCount");
              }}
              text={fortunes_categories_options[6].inputDisplay}
            />

            {/* marzenia i aspiracje */}
            <TarotFormOption
              isSelected={
                tarot.fortune === fortunes_categories_options[7].value
              }
              imgSrc={marzeniaIcon}
              width={47}
              onClick={() => {
                setTarot({
                  ...tarot,
                  fortune: fortunes_categories_options[7].value,
                });
                setIsFieldValid({ ...isFieldValid, fortune: true });
                scrollTo100("cardsCount");
              }}
              text={fortunes_categories_options[7].inputDisplay}
            />
          </TarotFormFortunesCardsContainer>
        </EuiFormRow>
      </div>

      <div style={{ marginTop: 16 }} id="relationship_status">
        {(tarot.fortune === "Miłość" || tarot.fortune === "Love") && (
          <EuiFormRow
            label={
              <WrozbaInputTitle>
                {t("fortuneModal.step2.relationship_status")}
              </WrozbaInputTitle>
            }
            labelType="legend"
            isInvalid={!isFieldValid.relationship_status}
            error={t("fortuneModal.requiredError")}
          >
            <EuiSuperSelect
              style={{ background: "#0D0D0D" }}
              compressed
              placeholder={t("fortuneModal.select")}
              valueOfSelected={tarot.relationship_status}
              onChange={(value) => {
                setTarot({ ...tarot, relationship_status: value });
                setIsFieldValid({
                  ...isFieldValid,
                  relationship_status: true,
                });
                scrollTo("cardsCount");
              }}
              options={relationship_status_options}
            />
          </EuiFormRow>
        )}
      </div>

      <div id="cardsCount">
        <EuiFormRow
          style={{ marginTop: 30 }}
          label={
            <WrozbaInputTitle>
              {t("homepage.wrozbaSelector.form.cardCount")}
            </WrozbaInputTitle>
          }
          labelType="legend"
          isInvalid={!isFieldValid.cardsCount}
          error={t("fortuneModal.requiredError")}
        >
          <div>
            <TarotFormOption
              isSelected={tarot.cardsCount === "1"}
              imgSrc={cardGroup1}
              width={100}
              onClick={() => {
                setTarot({ ...tarot, cardsCount: "1" });
                scrollTo("terms");
                setIsFieldValid({
                  ...isFieldValid,
                  cardsCount: true,
                });
              }}
              text={t("homepage.wrozbaSelector.form.card1.title")}
              description={t("homepage.wrozbaSelector.form.card1.description")}
              price={t("homepage.wrozbaSelector.form.card1.price")}
              needsLog={!currentUser}
              iconSize={40}
            />

            <TarotFormOption
              isSelected={tarot.cardsCount === "3"}
              imgSrc={cardGroup3}
              width={100}
              onClick={() => {
                setTarot({
                  ...tarot,
                  cardsCount: "3",
                });
                setIsFieldValid({
                  ...isFieldValid,
                  cardsCount: true,
                });
                scrollTo("terms");
              }}
              text={t("homepage.wrozbaSelector.form.card3.title")}
              description={t("homepage.wrozbaSelector.form.card3.description")}
              price={t("homepage.wrozbaSelector.form.card1.price")}
              iconSize={90}
            />

            <TarotFormOption
              isSelected={tarot.cardsCount === "5"}
              imgSrc={cardGroup5}
              width={100}
              onClick={() => {
                setTarot({
                  ...tarot,
                  cardsCount: "5",
                });
                setIsFieldValid({
                  ...isFieldValid,
                  cardsCount: true,
                });
                scrollTo("terms");
              }}
              text={t("homepage.wrozbaSelector.form.card5.title")}
              description={t("homepage.wrozbaSelector.form.card5.description")}
              price={t("homepage.wrozbaSelector.form.card1.price")}
              iconSize={90}
            />
          </div>
        </EuiFormRow>
      </div>

      {/* <div
        id="terms"
        style={{
          marginTop: 20,
          display: "flex",
          width: "100%",

          alignItems: "center",
          gap: 8,
          cursor: "pointer",
        }}
        onClick={() => {
          setTermsAccepted((prev) => !prev);
          scrollTo("terms");
        }}
      >
        <CheckboxContainer>
          <HiddenCheckbox checked={termsAccepted} readOnly />
          <StyledCheckbox checked={termsAccepted} />
          <CheckboxLabel />
        </CheckboxContainer>

        <P style={{ opacity: 0.8 }}>
          {t("fortuneModal.step3.terms.accept")}
          <TermsLink href="https://mysticer.com/terms" target="_blank">
            {t("fortuneModal.step3.terms.terms")}
          </TermsLink>{" "}
          {t("fortuneModal.step3.terms.and")}
          <TermsLink href="https://mysticer.com/privacy" target="_blank">
            {t("fortuneModal.step3.terms.privacy")}
          </TermsLink>
          .
        </P>
      </div> */}

      {!isFieldValid.terms && (
        <div>
          <EuiText color={"danger"} style={{ marginTop: 5 }}>
            {t("fortuneModal.step3.terms.error")}
          </EuiText>
        </div>
      )}

      <div
        style={{
          marginTop: 30,
          display: "flex",
          gap: 20,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EuiButtonEmpty
          onClick={handleBackButtonClick}
          style={{ color: "white" }}
        >
          {t("fortuneModal.buttons.close")}
        </EuiButtonEmpty>

        <Button type="submit" form="form">
          {t("fortuneModal.buttons.continue")}
        </Button>
      </div>
    </TarotFormStep1Container>
  );
};

export default TarotFormStep1;

const fortunesCategories = {
  General: "Ogólne",
  Love: "Miłość",
  Health: "Zdrowie",
  Career: "Kariera i Finanse",
  Education: "Edukacja",
  "Family and Relationships": "Rodzina i Relacje",
  "Personal Growth": "Rozwój Osobisty",
  "Dreams and Aspirations": "Marzenia i Aspiracje",
};
