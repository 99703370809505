import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";

import { db } from "../../../../config/firebase";
import { DreamRequest } from "../api/useCheckFreeDream";

function useDreamById(documentId: string, onNoDocument: () => void) {
  const [dream, setDream] = useState<DreamRequest | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    async function fetchDream() {
      setLoading(true);
      setError(false);

      try {
        const dreamRef = doc(db, "Dreams", documentId);
        const docSnapshot = await getDoc(dreamRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data() as Omit<DreamRequest, "id">;

          setDream(data);
        } else {
          setDream(null);
          onNoDocument();
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    if (documentId) {
      fetchDream();
    }
  }, [documentId]);

  return { dream, loading, error };
}

export default useDreamById;
