import {
  EuiFormRow,
  EuiFieldText,
  EuiFieldNumber,
  EuiSuperSelect,
} from "@elastic/eui";
import React, { useEffect, useState } from "react";
import { H3, P, ButtonSecondary } from "../../../App.style";
import { WrozbaInputTitle } from "../../wrozba/Wrozba.style";
import { SettingsForm, SettingsButtonsContainer } from "../SettingsModal.style";
import { User } from "firebase/auth";
import { FirestoreUser, updateUser } from "../../session/api/userApi";
import { useTranslation } from "react-i18next";
import { professions, genderCategories } from "../../wrozba/WrozbaForm";

interface FortuneDataProps {
  currentUser: User;
  firestoreUser: FirestoreUser;
}

const FortuneData: React.FC<FortuneDataProps> = ({
  currentUser,
  firestoreUser,
}) => {
  const { t, i18n } = useTranslation();
  const [fortuneData, setFortuneData] = useState<{
    name: string;
    age: string;
    professional_status: string;
    gender: string;
  }>({
    name: firestoreUser.name,
    age: firestoreUser.age,
    professional_status: firestoreUser.professional_status,
    gender: firestoreUser.gender,
  });
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateWrozbaSettings()) {
      return null;
    }

    await updateUser(firestoreUser.id, fortuneData).then(() => {
      setChanges(false);
      setChangesSaved(true);
      window.location.reload();
    });
  };

  const [changes, setChanges] = useState<boolean>(false);
  const [changesSaved, setChangesSaved] = useState<boolean>(false);

  const handleClearChanges = () => {
    !changes && setChanges(true);
    changesSaved && setChangesSaved(false);
  };

  const [isFieldValid, setIsFieldValid] = useState({
    name: true,
    age: true,
    professional_status: true,
    gender: true,
  });

  const validateWrozbaSettings = () => {
    const newValidationState = {
      name: !!fortuneData.name,
      age: !!fortuneData.age,
      professional_status: !!fortuneData.professional_status,
      gender: !!fortuneData.gender,
    };

    setIsFieldValid(newValidationState);

    return Object.values(newValidationState).every(Boolean);
  };

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (changesSaved) {
      timer = setTimeout(() => {
        setChangesSaved(false);
      }, 5000); // Close the modal after 5 seconds
    }

    return () => clearTimeout(timer); // Cleanup the timer on component unmount or before re-running the effect
  }, [changesSaved]);

  const createSelectOptions = (
    items: Record<string, string>,
    sort?: boolean
  ) => {
    const isPolish = i18n.language === "pl-PL";

    let options = Object.entries(items).map(([englishName, polishName]) => {
      return {
        value: isPolish ? polishName : englishName,
        inputDisplay: isPolish ? polishName : englishName,
      };
    });

    if (sort) {
      options = options.sort((a, b) => {
        if (a.inputDisplay < b.inputDisplay) {
          return -1;
        }
        if (a.inputDisplay > b.inputDisplay) {
          return 1;
        }
        return 0;
      });
    }

    return options;
  };

  const proffesions_options = createSelectOptions(professions, true);
  const gender_options = createSelectOptions(genderCategories);
  return (
    <SettingsForm id="form" onSubmit={handleSubmit}>
      <H3 style={{ marginBottom: 20 }}>{t("settings.userData.myData")}</H3>
      <EuiFormRow
        label={
          <WrozbaInputTitle>{t("settings.userData.name")}</WrozbaInputTitle>
        }
        labelType="legend"
        isInvalid={!isFieldValid.name}
        error={t("settings.userData.required")}
      >
        <EuiFieldText
          maxLength={20}
          style={{ background: "#0D0D0D" }}
          value={fortuneData.name}
          onChange={(e) => {
            setFortuneData({ ...fortuneData, name: e.target.value });
            handleClearChanges();
            setIsFieldValid({
              ...isFieldValid,
              name: !!e.target.value,
            });
          }}
          compressed
        />
      </EuiFormRow>

      <EuiFormRow
        label={
          <WrozbaInputTitle>{t("settings.userData.age")}</WrozbaInputTitle>
        }
        labelType="legend"
        isInvalid={!isFieldValid.age}
        error={t("settings.userData.required")}
      >
        <EuiFieldNumber
          style={{ background: "#0D0D0D" }}
          min={1}
          max={120}
          inputMode="numeric"
          compressed
          value={fortuneData.age}
          onChange={(e) => {
            setFortuneData({ ...fortuneData, age: e.target.value });
            handleClearChanges();
            setIsFieldValid({
              ...isFieldValid,
              age: !!e.target.value,
            });
          }}
        />
      </EuiFormRow>

      <EuiFormRow
        label={
          <WrozbaInputTitle>{t("fortuneModal.step1.gender")}</WrozbaInputTitle>
        }
        labelType="legend"
        isInvalid={!isFieldValid.gender}
        error={t("fortuneModal.requiredError")}
      >
        <EuiSuperSelect
          style={{ background: "#0D0D0D" }}
          compressed
          placeholder={t("fortuneModal.select")}
          valueOfSelected={fortuneData.gender}
          onChange={(value) => {
            setFortuneData({ ...fortuneData, gender: value });
            setIsFieldValid({
              ...isFieldValid,
              gender: true,
            });
          }}
          options={gender_options}
        />
      </EuiFormRow>

      <EuiFormRow
        label={
          <WrozbaInputTitle>
            {t("settings.userData.profession")}
          </WrozbaInputTitle>
        }
        labelType="legend"
        isInvalid={!isFieldValid.professional_status}
        error={t("settings.userData.required")}
      >
        <EuiSuperSelect
          style={{ background: "#0D0D0D" }}
          compressed
          placeholder={t("fortuneModal.select")}
          valueOfSelected={fortuneData.professional_status}
          onChange={(value) => {
            setFortuneData({ ...fortuneData, professional_status: value });
            setIsFieldValid({
              ...isFieldValid,
              professional_status: true,
            });
            handleClearChanges();
          }}
          options={proffesions_options}
        />
      </EuiFormRow>

      <SettingsButtonsContainer>
        <ButtonSecondary type="submit">
          {t("settings.userData.updateButton")}
        </ButtonSecondary>
      </SettingsButtonsContainer>

      {changesSaved && (
        <P style={{ textAlign: "center", marginTop: 10 }}>
          {t("settings.userData.dataSaved")}
        </P>
      )}
    </SettingsForm>
  );
};

export default FortuneData;
