import styled from "styled-components";

export const HeroContainer = styled.div`
  padding-top: 100px;

  display: flex;
  flex-direction: column; // Stack items vertically
  align-items: center; // Center items horizontally
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  text-align: center; // Center text for all views
  position: relative;

  @media (min-width: 768px) {
    padding-top: 80px;
    min-height: 800px;
  }
`;

export const TitleContainer = styled.div`
  width: auto;
  text-align: center;
  padding: 5px 10px;

  @media (min-width: 350px) {
    margin: 20px 20px;
  }

  @media (min-width: 768px) {
    margin-top: 100px;
    max-width: 1200px;
  }
`;

export const TextSection = styled.div`
  padding: 10px 20px;
  color: white;
  max-width: 900px;

  @media (min-width: 330px) {
    padding: 0px 20px;
  }

  z-index: 2;
`;

export const ButtonsContainer = styled.div`
  margin-top: 50px;
  z-index: 2;

  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-bottom: 40px;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 80%;
  height: auto;
  margin-top: calc(-10vh);
  max-width: 800px;
  overflow: hidden;

  img {
    width: 100%;
    max-width: 800px; // This is good, it caps the size for larger screens
    height: auto;
    object-fit: contain; // This ensures that the image is fully visible
    border-radius: 10px; /* Zaokrąglenie brzegów */

    opacity: 100%;

    @media (max-width: 768px) {
      max-width: 500px; // Adjust the maximum width for tablets
      margin-top: calc(5vh); // Smaller negative margin for tablets
    }

    @media (max-width: 400px) {
      max-width: 300px; // Further adjust for mobile phones
    }
  }
`;

export const WrozbaSelectorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

export const WrozbaSelectorTitleContainer = styled.div`
  width: auto;
  text-align: center;
  padding: 5px 10px;

  @media (min-width: 350px) {
    margin: 20px 20px;
  }

  @media (min-width: 768px) {
    margin-top: 60px;
    max-width: 1200px;
  }
`;

export const WrozbaSelectorContentContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  padding: 0px 10px;
  gap: 30px;
  z-index: 1;

  @media (min-width: 400) {
    gap: 40px;
  }

  @media (min-width: 768px) {
    margin-top: 50px;
    gap: 60px;
  }
`;

//do 4 kart
export const WrozbaSelectorContentTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;

export const WrozbaSelectorContentBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;
