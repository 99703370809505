import React, { useEffect, useRef, useState } from "react";
import { H5 } from "../../../App.style";
import {
  SelectorContainer,
  SelectedOption,
  OptionsContainer,
  PopoverOption,
  UserImage,
} from "./AccountPopover.style";
import { EuiIcon } from "@elastic/eui";
import arrowUp from "../../../assets/navbar/arrow-up.png";
import arrowDown from "../../../assets/navbar/arrow-down.png";
import { signOutUser } from "../../session/api/sessionApi";
import { useTranslation } from "react-i18next";
import { User } from "firebase/auth";

interface AccountPopoverProps {
  openSettingsModal: () => void;
  currentUser: User;
}

const AccountPopover: React.FC<AccountPopoverProps> = ({
  openSettingsModal,
  currentUser,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <SelectorContainer onClick={toggleDropdown} ref={containerRef}>
      <SelectedOption>
        {currentUser.photoURL ? (
          <UserImage src={currentUser.photoURL} alt="users image" />
        ) : (
          <EuiIcon type="user" color="ghost" />
        )}

        <img alt="arrow" src={isOpen ? arrowUp : arrowDown} />
      </SelectedOption>
      {isOpen && (
        <OptionsContainer style={{ width: 90 }}>
          <PopoverOption
            style={{ borderBottom: "1px solid black" }}
            onClick={openSettingsModal}
          >
            <H5>{t("navbar.popover.settings")}</H5>
          </PopoverOption>
          <PopoverOption onClick={signOutUser}>
            <H5>{t("navbar.popover.logout")}</H5>
          </PopoverOption>
        </OptionsContainer>
      )}
    </SelectorContainer>
  );
};

export default AccountPopover;
