import React, { useState } from "react";
import {
  AdminPanelBarButton,
  AdminPanelContainer,
  AdminPanelContent,
  AdminPanelTopBar,
} from "./AdminPanel.style";
import FortunesList from "./fortunes/FortunesList";
import ContactsList from "./contact/ContactsList";
import TarotsList from "./tarots/TarotsList";

const AdminPanel = () => {
  const [selectedTab, setSelectedTab] = useState<
    "contact" | "fortunes" | "tarots"
  >("tarots");

  return (
    <AdminPanelContainer>
      <AdminPanelTopBar>
        <AdminPanelBarButton
          selected={selectedTab === "tarots"}
          onClick={() => setSelectedTab("tarots")}
        >
          Tarots
        </AdminPanelBarButton>

        <AdminPanelBarButton
          selected={selectedTab === "fortunes"}
          onClick={() => setSelectedTab("fortunes")}
        >
          Fortunes
        </AdminPanelBarButton>
        <AdminPanelBarButton
          selected={selectedTab === "contact"}
          onClick={() => setSelectedTab("contact")}
        >
          Contact
        </AdminPanelBarButton>
      </AdminPanelTopBar>

      <AdminPanelContent>
        {selectedTab === "fortunes" && <FortunesList />}
        {selectedTab === "contact" && <ContactsList />}
        {selectedTab === "tarots" && <TarotsList />}
      </AdminPanelContent>
    </AdminPanelContainer>
  );
};

export default AdminPanel;
