import { useEffect, useState } from "react";
import { getTarotById } from "../../session/api/userApi";

export const useCheckTarotPayment = () => {
  const [response, setResponse] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [intervalId, setIntervalId] = useState<number | null>(null);

  const resetResponse = () => {
    //zmienic localStorage itemy
    setResponse(null);
    setError(null);
    localStorage.removeItem("apiResponseTarot");
    localStorage.removeItem("paymentStatusTarotCheckNeeded");
    localStorage.removeItem("tarotDocId");
  };

  const fetchAndCheckStatus = async (docId: string) => {
    try {
      const docData = await getTarotById(docId);

      if (docData) {
        if (docData.status === "success") {
          setResponse(docId);
          setIsLoading(false);
          clearIntervalIfNeeded();
          return true;
        } else if (docData.status === "error") {
          setIsLoading(false);
          setError("Something went wrong");
          clearIntervalIfNeeded();
          return false;
        }
      }
      return null;
    } catch (e) {
      setError("redirect");
      clearIntervalIfNeeded();
      return false;
    }
  };

  const clearIntervalIfNeeded = () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
      alert("Clearing interval");
    }
  };

  const checkPayment = async (checkout_session_id: string) => {
    const isPaymentStatusCheckNeeded =
      localStorage.getItem("paymentStatusTarotCheckNeeded") === "true";
    const fortuneDocId = localStorage.getItem("tarotDocId");

    if (response !== null) return;

    if (!fortuneDocId) {
      setError("redirect");
      return;
    }

    setIsLoading(true);
    //jesli uzytkownik pierwszy raz wszedl na ten komponent, robi zapytanie na backend
    if (isPaymentStatusCheckNeeded) {
      localStorage.setItem("paymentStatusTarotCheckNeeded", "false");
      try {
        const response = await fetch(
          "https://europe-central2-fortune-teller-ai.cloudfunctions.net/checkTarotPayment",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ checkout_session_id: checkout_session_id }),
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Get the error message from the response
          throw new Error(`${response.status}: ${errorText}`);
        }

        const data = await response.json();
        setResponse(data);
        localStorage.setItem("apiResponseTarot", JSON.stringify(data));
      } catch (e) {
        setError(e instanceof Error ? e.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    } else {
      //zamiast odpytywac GPT, sprawdza dokument w bazie danych i sprwadza status
      let attemptCount = 0;

      const immediateCheck = await fetchAndCheckStatus(fortuneDocId);
      if (immediateCheck === null) {
        const checkInterval = setInterval(async () => {
          if (++attemptCount >= 6) {
            clearInterval(checkInterval);
            setError("Firestore document fetch time exceeded");
            setIntervalId(null);
          } else {
            const checkResult = await fetchAndCheckStatus(fortuneDocId);
            if (checkResult !== null) {
              clearInterval(checkInterval);
              setIntervalId(null);
            }
          }
        }, 10000) as unknown as number;
        setIntervalId(checkInterval);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (intervalId !== null) clearInterval(intervalId);
    };
  }, [intervalId]);

  return { checkPayment, resetResponse, response, error, isLoading };
};
