import styled from "styled-components";
import { FONT_WHITE } from "../../../App.style";

export const ServiceWorkScreenContainer = styled.div`
  width: 100%;
  min-height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ServiceWorkScreenText = styled.div`
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: clamp(15px, 3vw - 1rem, 21px);

  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
  letter-spacing: 1.71px;

  color: ${FONT_WHITE};
`;
