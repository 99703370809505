import styled from "styled-components";

export const LanguageSelectorText = styled.p`
  color: white;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
  }
`;

export const LanguageSelectorSelectedOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    ${LanguageSelectorText} {
      color: #6c3db8;
      text-shadow: 0 0 8px #6c3db8;
      transform: scale(1.08);
    }
  }
`;
