import {
  AuthError,
  EmailAuthProvider,
  GoogleAuthProvider,
  User,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
} from "firebase/auth";
import { auth, db } from "../../../../config/firebase";
import { doc, getDoc, setDoc } from "@firebase/firestore";
import moment from "moment";
import { FirestoreUser } from "./userApi";

//normal login with login and password
export const signIn = async (email: string, password: string) => {
  await signInWithEmailAndPassword(auth, email, password);
};

//register
export const registerUser = async (email: string, password: string) => {
  await createUserWithEmailAndPassword(auth, email, password).then(
    async (userCredentials) => {
      const email = userCredentials.user.email;
      if (email) await userDatabaseSave(email, userCredentials.user.uid);
    }
  );
};

//password reset
export const passwordReset = async (email: string) => {
  await sendPasswordResetEmail(auth, email);
};

//password changes
export const passwordChange = async (
  currentUser: User,
  currentPassword: string,
  newPassword: string
) => {
  try {
    if (currentUser.email) {
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        currentPassword
      );
      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, newPassword);
    }
  } catch (error) {
    const authError = error as AuthError;
    if (
      authError.code === "auth/wrong-password" ||
      authError.code === "auth/invalid-login-credentials"
    ) {
      throw new Error("wrong-password");
    } else {
      throw error;
    }
  }
};

//login with google
const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then(async (result) => {
      const user = result.user;
      if (user) {
        const email = user.email;

        if (email) await userDatabaseSave(user.email, user.uid);
      }
    })
    .catch((error) => {
      alert(error);
    });
};

//sign out
export const signOutUser = async () => {
  try {
    await signOut(auth);
    window.location.reload();
  } catch (error) {
    // Handle errors
    alert("something went wrong lol");
  }
};

//function for saving inside database
export async function userDatabaseSave(email: string, uid: string) {
  try {
    const userRef = doc(db, "Users", uid);

    const userDocSnap = await getDoc(userRef);

    if (!userDocSnap.exists()) {
      const newUser: FirestoreUser = {
        id: uid,
        email: email,
        name: "",
        gender: "",
        age: "",
        professional_status: "",
        lastFreeTarot: "",
        lastFreeDream: "",

        joinedAt: moment().format("yyyy-MM-dd'T'HH:mm:ss"),
      };

      const { id, ...userToSend } = newUser;

      await setDoc(userRef, userToSend);
    }
  } catch (error) {
    console.error("Error registering user: ", error);
  }
}
