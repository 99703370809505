import {
  EuiButtonEmpty,
  EuiFieldNumber,
  EuiFieldText,
  EuiFormRow,
  EuiSuperSelect,
  EuiSelectableOption,
  EuiSelectable,
  EuiText,
} from "@elastic/eui";
import { collection, serverTimestamp, addDoc } from "@firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db } from "../../../config/firebase";
import {
  FirestoreUser,
  UserFortuneFormData,
  WrozbaTimeRanges,
  updateUser,
} from "../session/api/userApi";
import { User } from "firebase/auth";

import { Button, H2, H6, P } from "../../App.style";
import {
  CheckboxContainer,
  CheckboxLabel,
  HiddenCheckbox,
  StyledCheckbox,
  WrozbaFormContainer,
  WrozbaFormContentContainer,
  WrozbaInputTitle,
  WrozbaSummaryContainer,
  WrozbaSummaryRow,
  WrozbaTitle,
} from "./Wrozba.style";
import { useTranslation } from "react-i18next";
import "./customCss.css";
import { useNavigate } from "react-router-dom";
import { TarotTitleText } from "../tarot/Tarot.style";
import { FortuneRequest } from "./result/useCheckPayment";

interface WrozbaFormProps {
  currentUser: User | null;
  firestoreUser: FirestoreUser | null;
}

const WrozbaForm: React.FC<WrozbaFormProps> = ({
  currentUser,
  firestoreUser,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [wrozba, setWrozba] = useState<UserFortuneFormData>({
    name: firestoreUser?.name ?? "",
    age: firestoreUser?.age ?? "",
    professional_status: firestoreUser?.professional_status ?? "",
    gender: firestoreUser?.gender ?? "",
    fortune: "",
    time: "",
    mood: "",
    relationship_status: "",
  });

  const [saveData, setSaveData] = useState<boolean>(true);

  const handleSaveData = async () => {
    if (currentUser) {
      const data: Partial<FirestoreUser> = {};

      if (wrozba.name !== firestoreUser?.name) {
        data.name = wrozba.name;
      }

      if (wrozba.age !== firestoreUser?.age) {
        data.age = wrozba.age;
      }

      if (wrozba.professional_status !== firestoreUser?.professional_status) {
        data.professional_status = wrozba.professional_status;
      }

      if (wrozba.gender !== firestoreUser?.gender) {
        data.gender = wrozba.gender;
      }

      if (Object.keys(data).length > 0) {
        await updateUser(currentUser.uid, data);
      }
    }
  };

  const addFortune = async (userData: UserFortuneFormData): Promise<string> => {
    const fortunesRef = collection(db, "Fortunes");

    const fortune: FortuneRequest = {
      status: "pending",
      user_form_data: userData,
      ai_response: "",
      user_id: currentUser ? currentUser.uid : null, // using null for no user
      created_at: serverTimestamp(),
      lang: i18n.language === "pl-PL" ? "PL" : "EN",
    };

    try {
      const docRef = await addDoc(fortunesRef, fortune);

      localStorage.removeItem("apiResponse");
      localStorage.setItem("fortuneDocId", docRef.id);
      localStorage.setItem("paymentStatusCheckNeeded", "true");
      return docRef.id; // Return the document ID
    } catch (error) {
      throw new Error("Failed to add new fortune"); // Throw an error to be caught by the caller
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (currentStep === 1) {
      if (!validateStep1()) {
        return;
      }

      if (saveData) handleSaveData();

      goToNextStep();
    } else if (currentStep === 2) {
      if (!validateStep2()) {
        return;
      }

      goToNextStep();
    } else {
      try {
        const documentId = await addFortune(wrozba);

        //original

        navigate(`/fortune/check/${documentId}`);
        // Do something with documentId if needed
      } catch (error) {
        console.error("Failed to create new fortune document:", error);
      }
    }
  };

  const [isFieldValid, setIsFieldValid] = useState({
    name: true,
    age: true,
    professional_status: true,
    relationship_status: true,
    gender: true,
    mood: true,
    fortune: true,
    time: true,
  });

  const validateStep1 = () => {
    const newValidationState = {
      name: !!wrozba.name,
      age: !!wrozba.age,
      professional_status: !!wrozba.professional_status,
      gender: !!wrozba.gender,
      fortune: true,
      time: true,
      mood: true,
      relationship_status: true,
    };

    setIsFieldValid(newValidationState);

    return Object.values(newValidationState).every(Boolean);
  };

  const validateStep2 = () => {
    const newValidationState = {
      name: true,
      age: true,
      professional_status: true,
      gender: true,
      fortune: !!wrozba.fortune,
      relationship_status: !!wrozba.relationship_status,
      time: !!wrozba.time,
      mood: !!wrozba.mood,
    };

    setIsFieldValid(newValidationState);

    return Object.values(newValidationState).every(Boolean);
  };

  //steps
  const [currentStep, setCurrentStep] = useState(1);
  const goToNextStep = () => {
    if (currentStep < 3) {
      setCurrentStep((prev) => prev + 1);
      window.scrollTo(0, 0);
    }
  };
  const goToPreviousStep = () => {
    setCurrentStep((prev) => prev - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const renderFormTitle = (step: number): string => {
    switch (step) {
      case 1:
        return t("fortuneModal.step1.title");
      case 2:
        return t("fortuneModal.step2.title");
      case 3:
        return t("fortuneModal.step3.title");
      default:
        return "Error";
    }
  };

  const handleBackButtonClick = () => {
    if (currentStep === 1) {
      navigate("#selector");
    } else {
      goToPreviousStep();
    }
  };

  const createSelectOptions = (
    items: Record<string, string>,
    sort?: boolean
  ) => {
    const isPolish = i18n.language === "pl-PL";

    let options = Object.entries(items).map(([englishName, polishName]) => {
      return {
        value: isPolish ? polishName : englishName,
        inputDisplay: isPolish ? polishName : englishName,
      };
    });

    if (sort) {
      options = options.sort((a, b) => {
        if (a.inputDisplay < b.inputDisplay) {
          return -1;
        }
        if (a.inputDisplay > b.inputDisplay) {
          return 1;
        }
        return 0;
      });
    }

    return options;
  };

  const gender_options = createSelectOptions(genderCategories);

  const mood_options = createSelectOptions(moodCategories, true);

  const proffesions_options = createSelectOptions(professions, true);

  const fortunes_categories_options = createSelectOptions(
    fortunesCategories,
    true
  );

  const time_categories_options = createSelectOptions(timeCategories);

  const relationship_status_options = createSelectOptions(
    relationStatusCategories
  );

  //proffesion selectable
  const onProffesionChange = (options: EuiSelectableOption[]) => {
    const selected = options.find((option) => option.checked === "on");
    if (selected && selected.key) {
      setWrozba({ ...wrozba, professional_status: selected.key });
      setIsFieldValid({ ...isFieldValid, professional_status: true });
      setIsListVisible(false);
    }
  };

  const selectableOptions: EuiSelectableOption[] = proffesions_options.map(
    (option) => ({
      label: option.inputDisplay,
      key: option.value,
    })
  );

  const [isListVisible, setIsListVisible] = useState(false);

  const searchProps = {
    placeholder: t("fortuneModal.step1.proffesionPlaceholder"),
  };

  const [termsAccepted, setTermsAccepted] = useState(false);

  const [termsError, setTermsError] = useState<boolean>(false);

  const errorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (termsError && !termsAccepted && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [termsError, termsAccepted]);

  return (
    <WrozbaFormContainer>
      <WrozbaTitle>
        <H2>{renderFormTitle(currentStep)}</H2>
        {currentStep === 1 && (
          <TarotTitleText onClick={() => navigate("/#selector")}>
            <P>{t("homepage.hero.moreFortunes")}</P>
          </TarotTitleText>
        )}
      </WrozbaTitle>

      <WrozbaFormContentContainer id="form" onSubmit={handleSubmit}>
        {currentStep === 1 ? (
          <>
            <EuiFormRow
              label={
                <WrozbaInputTitle>
                  {t("fortuneModal.step1.name")}
                </WrozbaInputTitle>
              }
              labelType="legend"
              isInvalid={!isFieldValid.name}
              error={t("fortuneModal.requiredError")}
            >
              <EuiFieldText
                maxLength={20}
                style={{ background: "#0D0D0D" }}
                value={wrozba.name}
                onChange={(e) => {
                  setWrozba({ ...wrozba, name: e.target.value });
                  setIsFieldValid({
                    ...isFieldValid,
                    name: !!e.target.value,
                  });
                }}
                compressed
              />
            </EuiFormRow>

            <EuiFormRow
              label={
                <WrozbaInputTitle>
                  {t("fortuneModal.step1.age")}
                </WrozbaInputTitle>
              }
              labelType="legend"
              isInvalid={!isFieldValid.age}
              error={t("fortuneModal.requiredError")}
            >
              <EuiFieldNumber
                style={{ background: "#0D0D0D" }}
                min={1}
                max={150}
                inputMode="numeric"
                compressed
                value={wrozba.age}
                onChange={(e) => {
                  setWrozba({ ...wrozba, age: e.target.value });
                  setIsFieldValid({
                    ...isFieldValid,
                    age: !!e.target.value,
                  });
                }}
              />
            </EuiFormRow>

            <EuiFormRow
              label={
                <WrozbaInputTitle>
                  {t("fortuneModal.step1.gender")}
                </WrozbaInputTitle>
              }
              labelType="legend"
              isInvalid={!isFieldValid.gender}
              error={t("fortuneModal.requiredError")}
            >
              <EuiSuperSelect
                style={{ background: "#0D0D0D" }}
                compressed
                placeholder={t("fortuneModal.select")}
                valueOfSelected={wrozba.gender}
                onChange={(value) => {
                  setWrozba({ ...wrozba, gender: value });
                  setIsFieldValid({
                    ...isFieldValid,
                    gender: true,
                  });
                }}
                options={gender_options}
              />
            </EuiFormRow>

            <EuiFormRow
              label={
                <WrozbaInputTitle>
                  {t("fortuneModal.step1.profession")}
                </WrozbaInputTitle>
              }
              labelType="legend"
              isInvalid={!isFieldValid.professional_status}
              error={t("fortuneModal.requiredError")}
            >
              <div
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                {wrozba.professional_status !== "" && (
                  <EuiFieldText
                    value={wrozba.professional_status}
                    readOnly
                    style={{ background: "#0d0d0d" }}
                  />
                )}
                <EuiSelectable
                  aria-label="Single selection example"
                  options={selectableOptions}
                  onChange={onProffesionChange}
                  singleSelection
                  searchable
                  searchProps={searchProps}
                  noMatchesMessage={"Nie znaleziono opcji"}
                  style={{
                    background: "#0D0D0D",
                    height: 200,
                    overflow: "hidden",
                  }}
                >
                  {(list, search) => (
                    <div>
                      {search}
                      {!isListVisible && list}
                    </div>
                  )}
                </EuiSelectable>
              </div>
            </EuiFormRow>

            {currentUser && (
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  gap: 8,
                  cursor: "pointer",
                }}
                onClick={() => setSaveData((prev) => !prev)}
              >
                <CheckboxContainer>
                  <HiddenCheckbox checked={saveData} readOnly />
                  <StyledCheckbox checked={saveData} />
                  <CheckboxLabel />
                </CheckboxContainer>

                <H6>{t("fortuneModal.step1.saveData")}</H6>
              </div>
            )}
          </>
        ) : currentStep === 2 ? (
          <>
            <EuiFormRow
              style={{ marginTop: 30 }}
              label={
                <WrozbaInputTitle>
                  {t("fortuneModal.step2.fortuneTopic")}
                </WrozbaInputTitle>
              }
              labelType="legend"
              isInvalid={!isFieldValid.fortune}
              error={t("fortuneModal.requiredError")}
            >
              <EuiSuperSelect
                style={{ background: "#0D0D0D" }}
                compressed
                placeholder={t("fortuneModal.select")}
                valueOfSelected={wrozba.fortune}
                onChange={(value) => {
                  setWrozba({ ...wrozba, fortune: value });
                  setIsFieldValid({
                    ...isFieldValid,
                    fortune: true,
                  });
                }}
                options={fortunes_categories_options}
              />
            </EuiFormRow>

            <EuiFormRow
              label={
                <WrozbaInputTitle>
                  {t("fortuneModal.step2.time")}
                </WrozbaInputTitle>
              }
              labelType="legend"
              isInvalid={!isFieldValid.time}
              error={t("fortuneModal.requiredError")}
            >
              <EuiSuperSelect
                style={{ background: "#0D0D0D" }}
                compressed
                placeholder={t("fortuneModal.select")}
                valueOfSelected={wrozba.time}
                onChange={(value) => {
                  setWrozba({ ...wrozba, time: value as WrozbaTimeRanges });
                  setIsFieldValid({
                    ...isFieldValid,
                    time: true,
                  });
                }}
                options={time_categories_options}
              />
            </EuiFormRow>

            <EuiFormRow
              label={
                <WrozbaInputTitle>
                  {t("fortuneModal.step2.relationship_status")}
                </WrozbaInputTitle>
              }
              labelType="legend"
              isInvalid={!isFieldValid.relationship_status}
              error={t("fortuneModal.requiredError")}
            >
              <EuiSuperSelect
                style={{ background: "#0D0D0D" }}
                compressed
                placeholder={t("fortuneModal.select")}
                valueOfSelected={wrozba.relationship_status}
                onChange={(value) => {
                  setWrozba({ ...wrozba, relationship_status: value });
                  setIsFieldValid({
                    ...isFieldValid,
                    relationship_status: true,
                  });
                }}
                options={relationship_status_options}
              />
            </EuiFormRow>

            <EuiFormRow
              label={
                <WrozbaInputTitle>
                  {t("fortuneModal.step2.mood")}
                </WrozbaInputTitle>
              }
              labelType="legend"
              isInvalid={!isFieldValid.mood}
              error={t("fortuneModal.requiredError")}
            >
              <EuiSuperSelect
                style={{ background: "#0D0D0D" }}
                compressed
                placeholder={t("fortuneModal.select")}
                valueOfSelected={wrozba.mood}
                onChange={(value) => {
                  setWrozba({ ...wrozba, mood: value });
                  setIsFieldValid({
                    ...isFieldValid,
                    mood: true,
                  });
                }}
                options={mood_options}
              />
            </EuiFormRow>
          </>
        ) : (
          <WrozbaSummaryContainer>
            <WrozbaSummaryRow>
              <P>{t("fortuneModal.step1.name")}:</P>{" "}
              <P style={{ opacity: 0.6 }}>{wrozba.name}</P>
            </WrozbaSummaryRow>
            <WrozbaSummaryRow>
              <P>{t("fortuneModal.step1.age")}:</P>{" "}
              <P style={{ opacity: 0.6 }}>{wrozba.age}</P>
            </WrozbaSummaryRow>
            <WrozbaSummaryRow>
              <P>{t("fortuneModal.step1.profession")}:</P>{" "}
              <P style={{ opacity: 0.6 }}>{wrozba.professional_status}</P>
            </WrozbaSummaryRow>
            <WrozbaSummaryRow>
              <P>{t("fortuneModal.step1.gender")}:</P>{" "}
              <P style={{ opacity: 0.6 }}>{wrozba.gender}</P>
            </WrozbaSummaryRow>

            <WrozbaSummaryRow style={{ marginTop: 10 }}>
              <P>{t("fortuneModal.step2.fortuneTopic")}:</P>
              <P style={{ opacity: 0.6 }}>{wrozba.fortune}</P>
            </WrozbaSummaryRow>

            <WrozbaSummaryRow>
              <P>{t("fortuneModal.step2.time")}:</P>
              <P style={{ opacity: 0.6 }}>{wrozba.time}</P>
            </WrozbaSummaryRow>

            <WrozbaSummaryRow>
              <P>{t("fortuneModal.step2.relationship_status")}</P>
              <P style={{ opacity: 0.6 }}>{wrozba.relationship_status}</P>
            </WrozbaSummaryRow>

            <WrozbaSummaryRow>
              <P>{t("fortuneModal.step2.mood")}</P>
              <P style={{ opacity: 0.6 }}>{wrozba.mood}</P>
            </WrozbaSummaryRow>

            {termsError && !termsAccepted && (
              <div ref={errorRef}>
                <EuiText color={"danger"} style={{ marginTop: 5 }}>
                  {t("fortuneModal.step3.terms.error")}
                </EuiText>
              </div>
            )}
          </WrozbaSummaryContainer>
        )}
      </WrozbaFormContentContainer>

      <div style={{ marginTop: 30, display: "flex", gap: 20 }}>
        <EuiButtonEmpty
          onClick={handleBackButtonClick}
          style={{ color: "white" }}
        >
          {currentStep === 1
            ? t("fortuneModal.buttons.close")
            : t("fortuneModal.buttons.back")}
        </EuiButtonEmpty>

        <Button type="submit" form="form">
          {t("fortuneModal.buttons.continue")}
        </Button>
      </div>
    </WrozbaFormContainer>
  );
};

export default WrozbaForm;

export const professions = {
  "IT Administrator": "Administrator IT",
  "Real Estate Administrator": "Administrator nieruchomości",
  Lawyer: "Adwokat",
  "Customs Agent": "Agent celny",
  "Real Estate Agent": "Agent nieruchomości",
  "Insurance Agent": "Agent ubezpieczeniowy",
  Actor: "Aktor",
  Acoustician: "Akustyk",
  "Financial Analyst": "Analityk finansowy",
  Anaesthesiologist: "Anestezjolog",
  Animator: "Animator",
  Surveyor: "Ankieter",
  Trainee: "Aplikant",
  Archaeologist: "Archeolog",
  Architect: "Architekt",
  Archivist: "Archiwista",
  Artist: "Artysta",
  Assistant: "Asystent",
  "Dental Assistant": "Asystent stomatologiczny",
  "Executive Assistant": "Asystent zarządu",
  Auditor: "Audytor",
  "Automation Engineer": "Automatyk",
  Embalmber: "Balsamista",
  Banker: "Bankowiec, Bankier",
  Barista: "Barista",
  Bartender: "Barman",
  "Concrete Worker, Reinforcement Worker": "Betoniarz, Zbrojarz",
  Librarian: "Bibliotekarz",
  "Biologist, Botanist": "Biolog, Botanik",
  Biotechnologist: "Biotechnolog",
  "Sheet Metal Worker": "Blacharz",
  "Insurance Broker": "Broker ubezpieczeniowy",
  Paver: "Brukarz",
  Foreman: "Brygadzista",
  "Construction Worker": "Budowlaniec",
  Bookmaker: "Bukmacher",
  "Business Development Manager": "Business Development Manager",
  "Customs Officer": "Celnik",
  Ceramist: "Ceramik",
  Chemist: "Chemik",
  Choreographer: "Choreograf",
  Carpenter: "Cieśla / Stolarz",
  "Content Manager": "Content Manager",
  Copywriter: "Copywriter",
  "Pastry Chef": "Cukiernik",
  Roofer: "Dekarz",
  Decorator: "Dekorator",
  Detective: "Detektyw",
  Diagnostician: "Diagnosta",
  "Laboratory Diagnostician": "Diagnosta laboratoryjny",
  Dietitian: "Dietetyk",
  Advisor: "Doradca",
  "Customer Advisor": "Doradca klienta",
  "Supplier, Procurement Officer": "Dostawca, Zaopatrzeniowiec",
  Caretaker: "Dozorca",
  Printer: "Drukarz",
  Lumberjack: "Drwal",
  Clergyman: "Duchowny",
  Director: "Dyrektor",
  Dispatcher: "Dyspozytor",
  Journalist: "Dziennikarz",
  Examiner: "Egzaminator",
  Economist: "Ekonomista",
  "Sales Clerk": "Ekspedient",
  "Electrical Power Engineer": "Elektroenergetyk",
  Electromechanic: "Elektromechanik",
  "Electrical Fitter": "Elektromonter",
  "Electronics Engineer": "Elektronik",
  "X-ray Technician": "Elektroradiolog",
  Electrician: "Elektryk",
  "Energy Specialist": "Energetyk",
  Pharmacist: "Farmaceuta",
  "Filmmaker, Director": "Filmowiec, Reżyser",
  Philologist: "Filolog",
  Philosopher: "Filozof",
  Physiotherapist: "Fizjoterapeuta",
  Physicist: "Fizyk",
  Florist: "Florysta",
  Photographer: "Fotograf",
  Franchisee: "Franczyzobiorca",
  "Milling Machine Operator": "Frezer",
  "Front-end Developer": "Front-end developer",
  Hairdresser: "Fryzjer",
  Fundraiser: "Fundraiser",
  "Tannery Worker": "Garbarz",
  "Gas Technician": "Gazownik",
  Geodesist: "Geodeta",
  Geologist: "Geolog",
  Gynecologist: "Ginekolog",
  Miner: "Górnik",
  Gravedigger: "Grabarz",
  "Graphic Designer": "Grafik",
  Groomer: "Groomer (psi fryzjer)",
  Salesman: "Handlowiec",
  Headhunter: "Headhunter",
  Historian: "Historyk",
  Breeder: "Hodowca",
  Hostess: "Hostessa",
  "HR Manager": "HR Manager",
  Metallurgist: "Hutnik",
  Plumber: "Hydraulik",
  Influencer: "Influencer",
  "IT Specialist": "Informatyk",
  Other: "Inny",
  Inspector: "Inspektor",
  Installer: "Instalator",
  Instructor: "Instruktor",
  "Fitness Instructor": "Instruktor fitness",
  Bookbinder: "Introligator",
  Engineer: "Inżynier",
  "Construction Engineer": "Inżynier budowy",
  "Quality Engineer": "Inżynier jakości",
  "Software Engineer": "Inżynier oprogramowania",
  "Java Developer": "Java developer",
  Rider: "Jeździec",
  Jeweler: "Jubiler",
  Shoemaker: "Kaletnik, Rymarz",
  Stonemason: "Kamieniarz",
  Cartographer: "Kartograf",
  Cashier: "Kasjer",
  Waiter: "Kelner",
  "Key Account Manager": "Key Account Manager",
  Driver: "Kierowca",
  Manager: "Kierownik",
  "Pharmacy Manager": "Kierownik apteki",
  "Office Manager": "Kierownik biura",
  "Construction Manager": "Kierownik budowy",
  "Warehouse Manager": "Kierownik magazynu",
  "Production Manager": "Kierownik produkcji",
  "Regional Manager": "Kierownik regionalny",
  "Store Manager": "Kierownik sklepu",
  "Film Operator": "Kinooperator",
  Classifier: "Klasyfikator",
  "Chimney Sweep": "Kominiarz",
  Bailiff: "Komornik",
  Conservator: "Konserwator",
  Concierge: "Konsjerż",
  Constructor: "Konstruktor",
  Consultant: "Konsultant",
  Controller: "Kontroler",
  "Quality Controller": "Kontroler jakości",
  "Air Traffic Controller": "Kontroler ruchu lotniczego",
  Tutor: "Korepetytor",
  Cosmetologist: "Kosmetolog",
  Beautician: "Kosmetyczka",
  Estimator: "Kosztorysant",
  Blacksmith: "Kowal",
  Tailor: "Krawiec",
  Draughtsman: "Kreślarz",
  Croupier: "Krupier",
  Criminologist: "Kryminolog",
  Accountant: "Księgowy",
  "Cook, Chef": "Kucharz, Szef kuchni",
  Courier: "Kurier",
  Furrier: "Kuśnierz",
  "Laboratory Assistant": "Laborant",
  Painter: "Lakiernik",
  Doctor: "Lekarz",
  Lector: "Lektor",
  Liquidator: "Likwidator",
  Postman: "Listonosz",
  Logistician: "Logistyk",
  "Speech Therapist": "Logopeda",
  Aviator: "Lotnik",
  Luthier: "Lutnik",
  Storekeeper: "Magazynier",
  Warehouseman: "Magazynier",
  Broker: "Makler",
  "Artist/Painter": "Malarz",
  Marketer: "Marketingowiec",
  Sailor: "Marynarz",
  Masseur: "Masażysta",
  Machinist: "Maszynista",
  Mechanic: "Mechanik",
  "Mechatronics Engineer": "Mechatronik",
  Merchandiser: "Merchandiser",
  Meteorologist: "Meteorolog",
  Metrologist: "Metrolog",
  Model: "Model",
  "Film or Sound Editor": "Montażysta obrazu lub dźwięku",
  Assembler: "Monter",
  Bricklayer: "Murarz",
  Museologist: "Muzealnik",
  Musician: "Muzyk",
  "Junior Accountant": "Młodszy księgowy",
  Teacher: "Nauczyciel",
  "Preschool Teacher": "Nauczyciel przedszkola",
  Scientist: "Naukowiec",
  Negotiator: "Negocjator",
  Nanny: "Niania",
  Notary: "Notariusz",
  Diver: "Nurek",
  "Gastronomy Staff": "Obsługa gastronomii",
  "Hotel Staff": "Obsługa hotelowa",
  "Security Guard": "Ochroniarz",
  Gardener: "Ogrodnik",
  Operator: "Operator",
  "Excavator Operator": "Operator koparki",
  "Machine Operator": "Operator maszyn",
  "Construction Machine Operator": "Operator maszyn budowlanych",
  "CNC Machine Operator": "Operator maszyn CNC",
  "Forklift Operator": "Operator wózków widłowych",
  Caregiver: "Opiekun",
  "Optician, Ophthalmologist": "Optyk, Okulista",
  "Service or Event Organizer": "Organizator usług lub wydarzeń",
  Orthopedist: "Ortopeda",
  "Lighting Technician": "Oświetleniowiec",
  Pedagogue: "Pedagog",
  "Special Education Teacher": "Pedagog specjalny",
  Baker: "Piekarz",
  Nurse: "Pielęgniarka / Pielęgniarz",
  Pilot: "Pilot samolotu",
  "Tour Guide": "Pilot wycieczek",
  Planner: "Planista",
  "Plastic Artist": "Plastyk",
  Policeman: "Policjant",
  Housekeeper: "Pomoc domowa",
  "Kitchen Assistant": "Pomoc kuchenna",
  Midwife: "Położna",
  Mediator: "Pośrednik",
  "Office Worker": "Pracownik biurowy",
  "Call Center Agent": "Pracownik call center",
  "Manual Worker": "Pracownik fizyczny",
  "Economic Worker": "Pracownik gospodarczy",
  "Cultural Institution Worker": "Pracownik instytucji kultury",
  "Railway Worker": "Pracownik kolei",
  "Production Worker": "Pracownik produkcji",
  "Social Worker": "Pracownik socjalny",
  Cleaner: "Pracownik sprzątający",
  "Maintenance Worker": "Pracownik utrzymania ruchu",
  Presenter: "Prezenter",
  Apprentice: "Probierz",
  "Film, TV, Radio, Music Producer":
    "Producent filmowy, telewizyjny, radiowy, muzyczny",
  "Product Manager": "Product manager",
  Programmer: "Programista",
  "Project Manager": "Project Manager",
  Designer: "Projektant",
  Promoter: "Promotor",
  Prosthetist: "Protetyk",
  Stenographer: "Protokolant, Stenotypista",
  "Sales Representative": "Przedstawiciel handlowy",
  "Medical Representative": "Przedstawiciel medyczny",
  "Food Processor": "Przetwórca żywności",
  Psychologist: "Psycholog",
  Psychotherapist: "Psychoterapeuta",
  "Back-end Developer": "Back-end Developer",
  "Legal Counsel": "Radca prawny",
  Radiologist: "Radiolog",
  Rescuer: "Ratownik",
  Paramedic: "Ratownik medyczny",
  Receptionist: "Recepcjonistka",
  Editor: "Redaktor",
  "Medical Registrar": "Rejestratorka medyczna",
  Restorer: "Renowator",
  "Reporter, Photo Reporter": "Reporter, Fotoreporter",
  Farmer: "Rolnik",
  Gunsmith: "Rusznikarz",
  "Fisherman, Fish Processor": "Rybak, Przetwórca ryb",
  Spokesperson: "Rzecznik",
  Expert: "Rzeczoznawca",
  Craftsman: "Rzemieślnik",
  Butcher: "Rzeźnik",
  Orchardist: "Sadownik",
  Wardmaid: "Salowa",
  Screenwriter: "Scenarzysta",
  "Set Designer": "Scenograf",
  Secretary: "Sekretarz",
  "Service Technician": "Serwisant",
  "Text Compiler": "Składacz tekstu",
  Sociologist: "Socjolog",
  Sommelier: "Sommelier",
  Paratrooper: "Spadochroniarz",
  Welder: "Spawacz",
  "Administration Specialist": "Specjalista ds. administracji",
  "Analysis and Statistics Specialist": "Specjalista ds. analizy i statystyki",
  "Research and Development Specialist": "Specjalista ds. badań i rozwoju",
  "IT Security Specialist": "Specjalista ds. bezpieczeństwa IT",
  "HSE and Environmental Protection Specialist":
    "Specjalista ds. BHP i ochrony środowiska",
  "Refrigeration and Air Conditioning Specialist":
    "Specjalista ds. chłodnictwa i klimatyzacji",
  "E-commerce Specialist": "Specjalista ds. e-commerce",
  "Finance Specialist": "Specjalista ds. finansów",
  "Import and Export Specialist": "Specjalista ds. importu i eksportu",
  "HR and Payroll Specialist": "Specjalista ds. kadr i płac",
  "Marketing Specialist": "Specjalista ds. marketingu",
  "Customer Service Specialist": "Specjalista ds. obsługi klienta",
  "Data Protection Specialist": "Specjalista ds. ochrony danych",
  "Healthcare Specialist": "Specjalista ds. ochrony zdrowia",
  "Bidding Specialist": "Specjalista ds. ofertowania",
  "PR Specialist": "Specjalista ds. PR",
  "Advertising Specialist": "Specjalista ds. reklamy",
  "Social Media Specialist": "Specjalista ds. Social Media",
  "Sales Specialist": "Specjalista ds. Sprzedaży",
  "International Relations Specialist":
    "Specjalista ds. stosunków międzynarodowych",
  "Telecommunications Specialist": "Specjalista ds. telekomunikacji",
  "Transport Specialist": "Specjalista ds. transportu",
  "Tourism Specialist, Resident": "Specjalista ds. turystyki, Rezydent",
  "Management Specialist": "Specjalista ds. Zarządzania",
  "Specialist in Unconventional Medicine":
    "Specjalista medycyny niekonwencjonalnej",
  "SEO or SEM Specialist": "Specjalista SEO lub SEM",
  Forwarder: "Spedytor",
  Athlete: "Sportowiec",
  Salesperson: "Sprzedawca",
  Coxswain: "Sternik",
  Stewardess: "Stewardessa / Stewart",
  Dentist: "Stomatolog",
  Fireman: "Strażak",
  "Border Guard": "Strażnik graniczny",
  Stylist: "Stylista",
  Cobbler: "Szewc",
  Glazier: "Szklarz",
  Shipbuilder: "Szkutnik",
  Grinder: "Szlifierz",
  Referee: "Sędzia piłkarski",
  "Prison Service": "Służba więzienna",
  "Uniformed Services": "Służby mundurowe",
  Locksmith: "Ślusarz",
  "Mystery Shopper": "Tajemniczy klient",
  "Taxi Driver": "Taksówkarz",
  Dancer: "Tancerz",
  Upholsterer: "Tapicer",
  "Team Leader": "Team leader",
  Technician: "Technik",
  "Foundry Technician": "Technik odlewnictwa",
  "Apicultural Technician": "Technik pszczelarz",
  "Medical Sterilization Technician": "Technik sterylizacji medycznej",
  "Sanitary Equipment Technician": "Technik urządzeń sanitarnych",
  Technologist: "Technolog",
  "Food Technologist": "Technolog żywności",
  Telemarketer: "Telemarketer",
  Therapist: "Terapeuta",
  Tester: "Tester",
  Turner: "Tokarz",
  "Personal Trainer": "Trener Personalny",
  "Coach, Trainer": "Trener, Szkoleniowiec",
  "Animal Trainer": "Treser",
  Translator: "Tłumacz",
  Clerk: "Urzędnik",
  "UX Designer": "UX designer",
  "Implementation Specialist": "Wdrożeniowiec",
  Webmaster: "Webmaster",
  Veterinarian: "Weterynarz",
  "Debt Collector": "Windykator",
  Vulcanizer: "Wulkanizator",
  "Textile Worker, Knitter": "Włókiennik, Dziewiarz",
  Watchmaker: "Zegarmistrz",
  Zootechnician: "Zootechnik",
  Student: "Student / Uczeń",
  Unemployed: "Bezrobotny/a",
  Entrepreneur: "Przedsiębiorca",
  "Make-up artist": "Makijażystka",
};

const fortunesCategories = {
  Career: "Kariera",
  Health: "Zdrowie",
  Love: "Miłość",
  Business: "Biznes",
  Finances: "Finanse",
  Education: "Edukacja",
  General: "Ogólne",
  "Spiritual Growth": "Rozwoj Duchowy",
  "Family and Relationships": "Rodzina i Relacje",
  "Personal Growth": "Rozwoj Osobisty",
  Creativity: "Kreatywność",
  "Travel and Adventures": "Podróże i Przygody",
  "Well-being and Lifestyle": "Dobrostan i Styl Życia",
  "Challenges and Obstacles": "Wyzwania i Przeszkody",
  Opportunities: "Możliwości",
  "Destiny and Life Path": "Przeznaczenie i Ścieżka Życia",
  "Dreams and Aspirations": "Marzenia i Aspiracje",
};

const timeCategories = {
  // Tomorrow: "Jutro", kiedys moze wroci
  Week: "Tydzień",
  Month: "Miesiąc",
  Year: "Rok",
};

export const genderCategories = {
  Female: "Kobieta",
  Male: "Mężczyzna",
  Other: "Inna",
};

const moodCategories = {
  Happy: "Szczęśliwy",
  Sad: "Smutny",
  Angry: "Zły",
  Anxious: "Zaniepokojony",
  Excited: "Podekscytowany",
  Tired: "Zmęczony",
  Relaxed: "Zrelaksowany",
  Bored: "Znudzony",
  Inspired: "Zainspirowany",
  Neutral: "Obojętny",
};

export const relationStatusCategories = {
  Single: "Wolny/Wolna",
  "In a Relationship": "W związku",
  Engaged: "Zaręczony/Zaręczona",
  Married: "Małżeństwo",
  Divorced: "Rozwiedziony/Rozwiedziona",
  Widowed: "Wdowiec/Wdowa",
};
