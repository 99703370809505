import React, { useState } from "react";
import {
  PasswordChangeContainer,
  SettingsButtonsContainer,
} from "../SettingsModal.style";
import { ButtonSecondary, H3, P } from "../../../App.style";
import { EuiFormRow, EuiFieldPassword } from "@elastic/eui";
import { WrozbaInputTitle } from "../../wrozba/Wrozba.style";
import { passwordChange } from "../../session/api/sessionApi";
import { User } from "firebase/auth";
import { useTranslation } from "react-i18next";

interface PasswordChangeProps {
  currentUser: User;
}

const PasswordChange: React.FC<PasswordChangeProps> = ({ currentUser }) => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");

  const [passwordError, setPasswordError] = useState<string>();

  const isPasswordStrong = () => newPassword.length >= 8; // Możesz dodać więcej warunków

  const [currentPasswordError, setCurerentPasswordError] = useState<string>();

  const handlePasswordChange = async () => {
    if (!isPasswordStrong()) {
      setPasswordError(t("settings.passwordChange.error.lengthError"));
    } else if (newPassword !== rePassword) {
      setPasswordError(t("settings.passwordChange.error.notTheSame"));
    } else {
      try {
        await passwordChange(currentUser, currentPassword, newPassword);
        setChangesSaved(true);
        handleCancelClick();
      } catch (error: any) {
        if (error.message === "wrong-password") {
          setCurerentPasswordError(
            t("settings.passwordChange.error.wrongPassword")
          );
        } else {
          setCurerentPasswordError(
            t("settings.passwordChange.error.something")
          );
        }
      }
    }
  };

  const handleCancelClick = () => {
    setCurrentPassword("");
    setNewPassword("");
    setRePassword("");
    setPasswordError(undefined);
    setCurerentPasswordError(undefined);
  };

  const [changesSaved, setChangesSaved] = useState<boolean>(false);

  return (
    <PasswordChangeContainer>
      <H3 style={{ marginBottom: 20 }}>{t("settings.passwordChange.title")}</H3>

      <>
        <EuiFormRow
          label={
            <WrozbaInputTitle>
              {t("settings.passwordChange.current")}
            </WrozbaInputTitle>
          }
          labelType="legend"
          isInvalid={currentPasswordError ? true : false}
          error={currentPasswordError}
        >
          <EuiFieldPassword
            isInvalid={currentPasswordError ? true : false}
            type="dual"
            maxLength={40}
            style={{
              background: "#0D0D0D",
              border: "none",
              outline: "none",
            }}
            value={currentPassword}
            onChange={(e) => {
              setCurrentPassword(e.target.value);
              setPasswordError(undefined);
              setCurerentPasswordError(undefined);
            }}
            compressed
          />
        </EuiFormRow>

        <EuiFormRow
          label={
            <WrozbaInputTitle>
              {t("settings.passwordChange.password")}
            </WrozbaInputTitle>
          }
          labelType="legend"
          isInvalid={passwordError ? true : false}
          error={passwordError}
        >
          <EuiFieldPassword
            isInvalid={passwordError ? true : false}
            type="dual"
            maxLength={40}
            style={{
              background: "#0D0D0D",
              border: "none",
              outline: "none",
            }}
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
              setPasswordError(undefined);
            }}
            compressed
          />
        </EuiFormRow>

        <EuiFormRow
          label={
            <WrozbaInputTitle>
              {t("settings.passwordChange.rePassword")}
            </WrozbaInputTitle>
          }
          labelType="legend"
          isInvalid={passwordError ? true : false}
        >
          <EuiFieldPassword
            isInvalid={passwordError ? true : false}
            type="dual"
            maxLength={40}
            style={{
              background: "#0D0D0D",
              border: "none",
              outline: "none",
            }}
            value={rePassword}
            onChange={(e) => {
              setRePassword(e.target.value);
              setPasswordError(undefined);
            }}
            compressed
          />
        </EuiFormRow>

        <SettingsButtonsContainer>
          <ButtonSecondary onClick={handlePasswordChange}>
            {t("settings.passwordChange.saveData")}
          </ButtonSecondary>
        </SettingsButtonsContainer>
      </>

      {changesSaved && (
        <P style={{ textAlign: "center", marginTop: 10 }}>
          {t("settings.passwordChange.savedPassword")}
        </P>
      )}
    </PasswordChangeContainer>
  );
};

export default PasswordChange;
