import styled, { keyframes } from "styled-components";

export const NavbarContainer = styled.nav`
  width: 100%;
  height: 80px; /* Fixed height */
  display: flex;
  align-items: center;
  background: black;
  position: fixed;
  top: 0px;
  z-index: 1000;
  padding: 0px 10px;

  @media (min-width: 640px) {
    padding: 0px 30px;
  }

  @media (min-width: 1000px) {
    padding: 0px 50px;
  }
`;

export const NavbarLeftContainer = styled.div`
  width: 50%;
  height: 100%;
  gap: 30px;
  display: flex;
  padding-right: 70px;
  align-items: center;
`;

export const NavbarRightContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding-right: 10px;
`;

export const NavbarDesktopLinks = styled.div`
  gap: 30px;

  display: none;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const NavbarLogoContainer = styled.div`
  position: absolute;
  left: 50%;

  transform: translateX(-50%);

  @media (max-width: 768px) {
    top: 20px;
  }
`;

const float = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
`;

export const NavbarLogo = styled.img`
  width: 30px;

  transition: transform 0.3s ease-in-out;

  @media (min-width: 768px) {
    width: 40px;
    &:hover {
      transform: scale(1.05) rotate(10deg); // Slight scale and rotate
      animation: ${float} 2s ease-in-out infinite; // Floating animation
    }
  }
`;

export const NavbarLink = styled.button`
  color: white;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #6c3db8; // Change color on hover (gold)
    text-shadow: 0 0 8px #6c3db8; // Add a glowing effect
    transform: scale(1.08); // Slightly increase size
  }

  &:focus {
    outline: none;
  }
`;

export const NavbarDesktopContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavbarExtendedContainer = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: black;
  position: fixed;
  top: 0;
  margin-top: 80px;
  left: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 250px; // Set a width for the sliding menu
  transform: translateX(100%); // Start hidden to the right
  transition: transform 0.3s ease-out; // Smooth transition for sliding in
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5); // Subtle shadow for depth
  gap: 20px;
  padding-bottom: 20px;

  @media (min-width: 1000px) {
    width: 0; // Ensure it doesn't show on larger screens
    display: none; // Hide it on larger screens
  }

  // Apply the transition effect when the isOpen state changes
  transform: ${(props) =>
    props.$isOpen ? "translateX(0)" : "translateX(-100%)"};
`;

//hamberger

export const NavbarMobileContainer = styled.button`
  width: 40px;
  height: 60px;
  cursor: pointer;
  position: relative;

  border-radius: 8px;
  border: none;
  background: transparent;

  margin-right: 25px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  height: 32px;
  width: 32px;
  top: 20px;
  left: 20px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
`;

export const HamburgerLines = styled.div`
  position: absolute;
  top: 17px;
  left: 20px;
  width: 32px;
  height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Line = styled.span<{
  $top?: boolean;
  $middle?: boolean;
  $bottom?: boolean;
  $open: boolean;
}>`
  height: 4px;
  width: 100%;
  background: #eff0f7;
  border-radius: 10px;
  transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
  transform-origin: ${(props) =>
    props.$top ? "0% 0%" : props.$bottom ? "0% 100%" : "center"};

  ${(props) => props.$middle && props.$open && "opacity: 0;"}
  ${(props) => props.$top && props.$open && "transform: rotate(45deg);"}
  ${(props) => props.$bottom && props.$open && "transform: rotate(-45deg);"}
`;
