import { styled } from "styled-components";
import { FONT_WHITE } from "../../../App.style";

export const WrozbaListContainer = styled.div`
  display: flex;
  flex-direction: column; // Stack items vertically
  align-items: center; // Center items horizontally
  justify-content: flex-start;
  min-height: 100vh;

  width: 100%;
  max-width: 1000px;

  position: relative;

  padding: 80px 15px;

  @media (min-width: 350px) {
    padding: 80px 20px;
  }

  @media (min-width: 768px) {
    padding: 80px 30px;
  }
`;

export const WrozbaListTitleContainer = styled.div`
  margin-top: 30px;
  @media (min-width: 768px) {
    margin-top: 50px;
  }

  font-family: "Roboto", sans-serif;
  font-size: clamp(30px, 5vw + 1rem, 60px);
  font-style: normal;
  font-weight: 500;

  letter-spacing: 2px;

  color: ${FONT_WHITE};
`;

export const WrozbaLoadingContainer = styled.div`
  width: 100%;
  height: 300px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrozbaListContentContainer = styled.div`
  margin-top: 50px;
`;

export const WrozbaListRow = styled.div``;

export const WrozbaDate = styled.div`
  margin-left: 30px;
  @media (min-width: 768px) {
    margin-left: 60px;
  }
`;

export const WrozbaContent = styled.div<{ $tarot?: boolean }>`
  margin-top: 10px;
  background: ${(props) => (props.$tarot ? "#200630" : "#151416")};
  background: #151416;
  border-radius: 20px;

  cursor: pointer;

  padding: 15px 20px;

  @media (min-width: 300px) {
    padding: 15px 30px;
  }

  @media (min-width: 768px) {
    padding: 15px 60px;
  }
  position: relative;
`;

export const WrozbaContentIcon = styled.img`
  position: absolute;
  width: 25px;
  right: 10px;
  top: 0px;
  transform: translateY(-50%);
`;

export const WrozbaSeeMoreButtonContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  padding-right: 30px;
  text-align: right;
  cursor: pointer;
  color: white;
  @media (min-width: 768px) {
    padding-right: 60px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const NoFortunesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
`;
