import React from "react";
import {
  LegalContainer,
  Title,
  BulletList,
  Paragraph,
  Subtitle,
  ListItem,
  NestedList,
  NestedListItem,
} from "./Legal.style";

const PrivacyPolicy = () => {
  return (
    <LegalContainer>
      <Title>Polityka Prywatności</Title>

      <Paragraph>
        Niniejsza Polityka stanowi podstawową informację o celach, sposobach
        przetwarzania oraz bezpieczeństwie Twoich danych osobowych, jako
        użytkownika strony: www.mysticer.com(zwanej dalej Stroną). Zapoznając
        się z Polityką prywatności dowiesz się kto jest administratorem Twoich
        danych osobowych, jakie dane osobowe są przez Stronę zbierane, w jakich
        celach są wykorzystywane oraz jak są chronione.
      </Paragraph>

      <Subtitle>§1. Informacje podstawowe.</Subtitle>
      <BulletList>
        <ListItem>
          1: Administratorem Twoich danych osobowych jest: Jakub Kracla
        </ListItem>
        <ListItem>
          2. Kontakt z Administratorem jest możliwy za pośrednictwem:
          <NestedList>
            <NestedListItem>{`1) poczty e-mail: contact@mysticer.com .`}</NestedListItem>
          </NestedList>
        </ListItem>
      </BulletList>

      <Subtitle>§2. Zasady przetwarzania danych.</Subtitle>
      <BulletList>
        <ListItem>
          1. Administrator przetwarza dane osobowe z poszanowaniem następujących
          zasad:
          <NestedList>
            <NestedListItem>{`1) w oparciu o podstawę prawną i zgodnie z prawem`}</NestedListItem>
            <NestedListItem>{`2) rzetelnie i uczciwie`}</NestedListItem>
            <NestedListItem>{`3) w sposób przejrzysty dla osoby, której dane dotyczą`}</NestedListItem>
            <NestedListItem>{`4) w konkretnych celach i nie „na zapas”`}</NestedListItem>
            <NestedListItem>{`5) nie więcej niż potrzeba`}</NestedListItem>
            <NestedListItem>{`6) z dbałością o prawidłowość danych`}</NestedListItem>
            <NestedListItem>{`7) nie dłużej niż potrzeba`}</NestedListItem>
            <NestedListItem>{`8) zapewniając odpowiednie bezpieczeństwo danych`}</NestedListItem>
          </NestedList>
        </ListItem>
      </BulletList>

      <Subtitle>
        §3. Cele, podstawy prawne i zakres przetwarzania danych.
      </Subtitle>
      <Paragraph>{`1) w celach kontaktowych, aby udzielić ci odpowiedzi na Twoją wiadomość przesłaną nam bezpośrednio drogą e-mailową lub za pośrednictwem formularza na podstawie realizacji prawnie uzasadnionego interesu Administratora związanego z koniecznością udzielenia Ci odpowiedzi.`}</Paragraph>
      <Paragraph>
        {
          "2) w celach technicznych przy użyciu localStorage oraz plików cookies (ciasteczka techniczne), na podstawie realizacji prawnie uzasadnionego interesu Administratora związanego z prawidłowym działaniem i funkcjonowaniem Strony."
        }
      </Paragraph>
      <Paragraph>
        {
          "3) w celach statystycznych i analitycznych, jeśli wyraziłeś zgodę na wykorzystanie przez nas plików cookies oraz localStorage do celów analitycznych (ciasteczka analityczne)."
        }
      </Paragraph>
      <Paragraph>
        {
          "4) w celach marketingowych, jeśli wyraziłeś zgodę na wykorzystanie przez nas plików cookies oraz localStorage do celów marketingowych (ciasteczka marketingowe)"
        }
      </Paragraph>
      <Paragraph>
        {
          "5) w celach związanych z prowadzeniem przez nas profilu firmowego w mediach społecznościowych (takich jak Facebook / Instagram /Tiktok/ X) na zasadach określonych przez właścicieli danego medium (portalu) i informowania w nim o naszych produktach, promocjach i innych wiadomościach dotyczących naszej działalności, co uważamy za nasz prawnie uzasadniony interes."
        }
      </Paragraph>

      <Subtitle>§4. Przetwarzamy Twoje dane w zakresie:</Subtitle>
      <Paragraph>{`1) jeśli wypełnisz formularz w celu uzyskania wróżby dane, które będziemy przetwarzać to- imię, wiek, płeć, zawód. Podanie tych danych stanowi warunek uzyskania wróżby oraz jest całkowicie dobrowolne.`}</Paragraph>
      <Paragraph>
        {
          "2) jeśli prześlesz wiadomość e-mail to będziemy przetwarzać dane, które będą w niej zawarte. Przekazanie nam danych w e-mailu następuje dobrowolnie. W każdym czasie możesz zwrócić się do nas z prośbą o usunięcie tych danych."
        }
      </Paragraph>
      <Paragraph>
        {
          "3) pozostałe dane, które Administrator przetwarza to adres IP i inne dane zapisywane w plikach cookies i localStorage. To Ty decydujesz w jakim zakresie będziemy mogli wykorzystać pliki cookies i localStorage z Twoimi danymi. Przekazanie nam danych w ten sposób następuje dobrowolnie. W każdym czasie możesz zmienić swoje preferencje. Możesz to zrobić w swojej przeglądarce internetowej lub też po usunięciu zapisanych plików cookies pochodzących z naszej Strony."
        }
      </Paragraph>
      <Paragraph>
        {
          "4) jeśli polubisz lub w inny sposób dołączysz do naszego profilu w mediach społecznościowych (np. X) będziemy przetwarzać Twoje dane, które podałeś w danym medium i do których będziemy mieli dostęp. W celu zakończenia przetwarzania możesz zakończyć subskrypcję naszego profilu."
        }
      </Paragraph>
      <Paragraph>
        {
          "5) API zewnętrznych partnerów: Nasza strona może korzystać z API (interfejsów programistycznych aplikacji) dostarczanych przez zewnętrznych partnerów w celu zintegrowania różnych funkcjonalności takich jak usługi analityczne, reklamowe czy media społecznościowe."
        }
      </Paragraph>

      <Subtitle>
        §5. Odbiorcy danych oraz zamiar przekazywania danych do Państwa spoza
        EOG lub organizacji międzynarodowej.
      </Subtitle>
      <BulletList>
        <ListItem>Odbiorcami Twoich danych osobowych są:</ListItem>
        <ListItem>{`1) jeśli wyraziłeś zgodę na ciasteczka marketingowe, Twoje dane zapisane w plikach cookies, będą przekazane do naszych partnerów reklamowych.`}</ListItem>
        <ListItem>{`2) jeśli wyraziłeś zgodę na ciasteczka analityczne, Twoje dane zapisane w plikach cookies, będą przetwarzane przez podmiot dostarczający oprogramowanie do analizy ruchu naszej stronie.`}</ListItem>
        <ListItem>
          {`3) Twoje dane nie są przekazywane do Państw spoza EOG lub organizacji międzynarodowych, z wyjątkiem partnerów wymienionych poniżej:`}
          <NestedList>
            <NestedListItem>
              {`1)`} Google Inc. z siedzibą w USA w zakresie analityki ruchu na
              stronie w systemie Google Analytics oraz dostarczania dodatkowych
              funkcji (takich jak Google Maps, Google Search Console) związanych
              z prawidłowym funkcjonowaniem strony oraz wyświetlaniem wyników
              wyszukiwania.{" "}
              <a href={"https://policies.google.com/privacy?hl=pl"}>
                Zobacz jak Google przetwarza i chroni Twoje dane.
              </a>
              <a href={"https://tools.google.com/dlpage/gaoptout/"}>
                Ten dodatek do przeglądarki{" "}
              </a>{" "}
              umożliwi Ci zarządzanie zakresem i poziomem dostępu do danych
              zbieranych przez Google Analytics.
            </NestedListItem>
            <NestedListItem>
              {`2)`} Google Inc. z siedzibą w USA w zakresie reklam i mierzenia
              ich skuteczności w systemie Google Ads.{" "}
              <a href={"https://policies.google.com/privacy?hl=pl"}>
                Zobacz jak Google przetwarza i chroni Twoje dane.
              </a>
              Tutaj możesz zarządzać{" "}
              <a href={"https://myadcenter.google.com/customize?hl=pl"}>
                swoimi preferencjami i informacjami w systemie reklamowym
                Google.
              </a>
            </NestedListItem>
            <NestedListItem>
              {`3)`} Facebook Inc. z siedzibą w USA w zakresie obsługi konta i
              dostępu do danych w serwisach społecznościowych (Facebook,
              Instagram), reklam i mierzenia ich skuteczności związanych z
              instalacją na stronie piksela Facebooka oraz API Konwersji.{" "}
              <a href={"https://www.facebook.com/business/gdpr"}>
                Zobacz jak Facebook przetwarza i chroni Twoje dane.
              </a>
            </NestedListItem>
            <NestedListItem>
              {`4)`} X Inc. z siedzibą w USA w zakresie obsługi konta i dostępu
              do danych w serwisach społecznościowych, reklam i mierzenia ich
              skuteczności związanych z instalacją na stronie piksela X oraz API
              Konwersji.{" "}
              <a href={"https://twitter.com/pl/privacy"}>
                Zobacz jak X przetwarza i chroni Twoje dane.
              </a>
            </NestedListItem>
          </NestedList>
        </ListItem>
      </BulletList>

      <Subtitle>§6. Termin przechowywania danych.</Subtitle>
      <Paragraph>
        Nie jesteśmy w stanie ustalić przez jaki okres przetwarzać będziemy
        Twoje dane osobowe. Jeśli przekazałeś nam swoje dane w formularzu lub
        wiadomości e-mail w celach kontaktowych to Twoje dane będziemy
        przetwarzać do momentu złożenia przez Ciebie żądania usunięcia tych
        danych lub sprzeciwu wobec przetwarzania, ale również w przypadku, w
        którym uznamy, że zrealizowaliśmy prawnie uzasadniony interes
        Administratora.
      </Paragraph>

      <Subtitle>§7. Prawa użytkowników.</Subtitle>
      <BulletList>
        <ListItem>
          1. W związku z przetwarzaniem Twoich danych przysługują Ci następujące
          prawa:
          <NestedList>
            <NestedListItem>{`1) dostępu do treści swoich danych oraz`}</NestedListItem>
            <NestedListItem>{`2) prawo ich sprostowania, usunięcia, ograniczenia przetwarzania,`}</NestedListItem>
            <NestedListItem>{`3) prawo do przenoszenia danych,`}</NestedListItem>
            <NestedListItem>{`4) prawo wniesienia sprzeciwu,`}</NestedListItem>
            <NestedListItem>{`5) prawo do cofnięcia zgody na ich przetwarzanie w dowolnym momencie i w dowolnej formie,
chyba że przetwarzanie Państwa danych odbywa się w celu wykonywania umowy przez Administratora, w celu wywiązania się przez Administratora z obowiązków prawnych względem instytucji państwowych lub w celu realizacji prawnie uzasadnionych interesów Administratora.`}</NestedListItem>
          </NestedList>
        </ListItem>
        <ListItem>
          2. Masz także prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych
          Osobowych (na adres Urzędu Ochrony Danych Osobowych, ul. Stawki 2,
          00-193 Warszawa).
        </ListItem>
        <ListItem>
          Więcej informacji w przedmiocie ochrony danych osobowych mogą Państwo
          otrzymać na stronie internetowej Urzędu Ochrony Danych Osobowych:{" "}
          <a href={"https://www.uodo.gov.pl"}>www.uodo.gov.pl</a>
        </ListItem>
      </BulletList>

      <Subtitle>§8. Informacja o ciasteczkach*</Subtitle>
      <BulletList>
        <ListItem>
          1. Na naszej stronie wykorzystujemy ciasteczka (pliki cookies).
        </ListItem>
        <ListItem>
          2. Ciasteczka (pliki cookies) są niewielkimi plikami tekstowymi
          przechowywanymi na urządzeniach użytkowników podczas przeglądania
          strony internetowej. Są one wykorzystywane w celu ułatwienia
          korzystania z naszej strony, poprawienia jakości usług oraz
          umożliwienia analizy ruchu i zachowań użytkowników.
        </ListItem>
        <ListItem>
          3. Ciasteczka mogą być stałe (trwałe) lub tymczasowe (sesyjne). Stałe
          ciasteczka są przechowywane na urządzeniu użytkownika przez określony
          czas lub do momentu ich usunięcia przez użytkownika. Tymczasowe
          ciasteczka są usuwane po zamknięciu przeglądarki.
        </ListItem>
        <ListItem>
          4. Wykorzystujemy ciasteczka własne oraz ciasteczka stron trzecich.
          Ciasteczka własne są wykorzystywane w celu zapewnienia prawidłowego
          działania strony, personalizacji treści, zapisywania preferencji
          użytkowników oraz analizy statystyk. Ciasteczka stron trzecich są
          wykorzystywane przez zewnętrzne narzędzia analityczne, reklamowe oraz
          do integracji z mediami społecznościowymi.
        </ListItem>
        <ListItem>
          5. Użytkownicy mają prawo kontrolować i ograniczać sposób, w jaki
          ciasteczka są wykorzystywane na naszej stronie. Większość przeglądarek
          internetowych umożliwia zarządzanie plikami cookies, w tym blokowanie,
          ograniczanie lub usuwanie ciasteczek. Szczegółowe informacje na temat
          zarządzania ciasteczkami można znaleźć w ustawieniach przeglądarki
          użytkownika.
        </ListItem>
        <ListItem>
          6. Należy pamiętać, że wyłączenie lub ograniczenie ciasteczek może
          wpłynąć na funkcjonalność strony oraz jakość świadczonych usług.
        </ListItem>
        <ListItem>
          7. Na naszej stronie wykorzystujemy różne rodzaje ciasteczek, aby
          lepiej dostosować się do potrzeb użytkowników i zapewnić optymalne
          korzystanie ze strony.
        </ListItem>
        <ListItem>
          8. Ciasteczka techniczne: Są niezbędne do prawidłowego funkcjonowania
          witryny. Umożliwiają utrzymanie sesji użytkownika, obsługę formularzy,
          zapamiętywanie preferencji oraz zapewnienie bezpieczeństwa strony.
        </ListItem>
        <ListItem>
          9. Ciasteczka marketingowe: Pozwalają na personalizację reklam oraz
          prowadzenie działań remarketingowych, które są dostosowane do
          zainteresowań i preferencji użytkowników. Służą również do mierzenia
          skuteczności kampanii reklamowych oraz optymalizacji treści
          reklamowych.
        </ListItem>
        <ListItem>
          10. Ciasteczka analityczne: Są wykorzystywane do zbierania informacji
          o sposobie korzystania z witryny przez użytkowników, takich jak liczba
          odwiedzin, strony odwiedzane, czas spędzony na stronie czy źródło
          ruchu. Uzyskane dane pomagają w analizie i optymalizacji strony,
          poprawiając jej funkcjonowanie i dostosowując treści do potrzeb
          użytkowników.
        </ListItem>
        <li style={{ color: "grey" }}>
          * w tożsamy sposób korzystamy z localStorage.
        </li>
      </BulletList>

      <Subtitle>§9. Dlaczego korzystamy z ciasteczek?*</Subtitle>
      <ListItem>
        1. Komfort korzystania ze strony: Ciasteczka (pliki cookies) mogą
        znacząco wpłynąć na komfort korzystania ze strony internetowej.
      </ListItem>
      <ListItem>
        2. Personalizacja treści: Ciasteczka pozwalają na dostosowanie
        wyświetlanych treści do preferencji i zainteresowań użytkownika, co
        sprawia, że strona staje się bardziej atrakcyjna i użyteczna.
      </ListItem>
      <ListItem>
        3. Zapamiętywanie ustawień: Dzięki ciasteczkom strona może zapamiętać
        wybrane przez użytkownika ustawienia, takie jak język, układ czy
        kolorystyka, co pozwala na szybsze i wygodniejsze korzystanie z serwisu.
      </ListItem>
      <ListItem>
        4. Logowanie: Ciasteczka umożliwiają przechowywanie informacji o
        zalogowanym użytkowniku, co pozwala na łatwe przełączanie się między
        różnymi sekcjami strony bez konieczności ponownego logowania.
      </ListItem>
      <ListItem>
        5. Utrzymanie sesji: Dzięki ciasteczkom strona może śledzić aktywność
        użytkownika na różnych podstronach, co pozwala na łatwe wracanie do
        ostatnio odwiedzanych sekcji lub kontynuowanie niedokończonych działań,
        takich jak wypełnianie formularzy.
      </ListItem>
      <ListItem>
        6. Optymalizacja wydajności: Ciasteczka mogą być wykorzystane do analizy
        ruchu i zachowań użytkowników na stronie, co pomaga w optymalizacji jej
        wydajności oraz dostarczaniu treści o wysokiej jakości.
      </ListItem>
      <ListItem>
        7. Reklamy: Ciasteczka umożliwiają wyświetlanie spersonalizowanych
        reklam, które są dopasowane do zainteresowań i potrzeb użytkownika.
        Dzięki temu, reklamy są mniej inwazyjne i bardziej adekwatne.
      </ListItem>
      <ListItem>
        8. Integracja z mediami społecznościowymi: Ciasteczka pozwalają na
        integrację z serwisami społecznościowymi, takimi jak Facebook, X czy
        Instagram, co umożliwia łatwe udostępnianie treści oraz korzystanie z
        dodatkowych funkcji, takich jak komentowanie czy polubienia.
      </ListItem>
      <li style={{ color: "grey" }}>
        * w tożsamy sposób korzystamy z localStorage.
      </li>

      <Subtitle>§10 Funkcje ciasteczek technicznych*</Subtitle>
      <ListItem>
        1. Utrzymanie sesji użytkownika: Ciasteczka techniczne umożliwiają
        utrzymanie sesji użytkownika podczas poruszania się po stronie, co
        pozwala na przechodzenie między różnymi sekcjami witryny bez
        konieczności ponownego logowania czy wprowadzania tych samych
        informacji.
      </ListItem>
      <ListItem>
        2. Zapamiętywanie preferencji użytkownika: Dzięki ciasteczkom
        technicznym strona może przechowywać informacje na temat preferencji
        użytkowników, takie jak wybrany język, wielkość czcionki, ustawienia
        kolorystyczne czy inne elementy personalizacji.
      </ListItem>
      <ListItem>
        3. Obsługa formularzy: Ciasteczka techniczne wspomagają funkcjonowanie
        formularzy na stronie, takich jak formularze logowania, rejestracji czy
        kontaktowe, zapewniając prawidłowe przetwarzanie danych wprowadzanych
        przez użytkowników.
      </ListItem>
      <ListItem>
        4. Optymalizacja wydajności strony: Użycie ciasteczek technicznych
        umożliwia monitorowanie wydajności strony oraz szybkość ładowania
        poszczególnych elementów, co pozwala na optymalizację i poprawę jakości
        świadczonych usług.
      </ListItem>
      <ListItem>
        5. Bezpieczeństwo: Ciasteczka techniczne pomagają w zapewnieniu
        bezpieczeństwa witryny i jej użytkowników, na przykład poprzez
        weryfikację tożsamości użytkowników, wykrywanie prób włamań czy ochronę
        przed atakami typu CSRF (Cross-Site Request Forgery).
      </ListItem>
      <li style={{ color: "grey" }}>
        * w tożsamy sposób korzystamy z localStorage.
      </li>

      <Subtitle>§11. Funkcje ciasteczek analitycznych*</Subtitle>
      <ListItem>
        1. Pomiar ruchu na stronie: Ciasteczka analityczne pozwalają na
        monitorowanie liczby odwiedzin, unikalnych użytkowników i wyświetleń
        stron, co daje ogólny obraz popularności i wydajności witryny.
      </ListItem>
      <ListItem>
        2. Analiza zachowań użytkowników: Dzięki ciasteczkom analitycznym można
        zbierać informacje o sposobie, w jaki użytkownicy poruszają się po
        stronie, na przykład czas spędzony na poszczególnych podstronach,
        ścieżki nawigacji oraz miejsca, w których opuszczają witrynę.
      </ListItem>
      <ListItem>
        3. Optymalizacja konwersji: Ciasteczka analityczne pomagają w
        identyfikowaniu elementów strony, które wpływają na skuteczność
        osiągania celów biznesowych, takich jak rejestracja, złożenie zamówienia
        czy wypełnienie formularza kontaktowego.
      </ListItem>
      <ListItem>
        4. Segmentacja użytkowników: Użycie ciasteczek analitycznych umożliwia
        grupowanie użytkowników według różnych kryteriów co pozwala na lepsze
        dostosowanie treści i oferty do potrzeb poszczególnych segmentów.
      </ListItem>
      <ListItem>
        5. Testowanie A/B: Ciasteczka analityczne są wykorzystywane do
        przeprowadzania testów A/B, które polegają na porównaniu dwóch wersji
        strony internetowej, aby sprawdzić, która z nich lepiej spełnia założone
        cele. Testy te pomagają w optymalizacji wyglądu, funkcjonalności i
        treści witryny.
      </ListItem>
      <li style={{ color: "grey" }}>
        * w tożsamy sposób korzystamy z localStorage.
      </li>

      <Subtitle>§12. Funkcje ciasteczek reklamowych*</Subtitle>
      <ListItem>
        1. Spersonalizowane reklamy: Ciasteczka marketingowe umożliwiają
        wyświetlanie reklam dopasowanych do zainteresowań, preferencji i
        historii przeglądania użytkownika, co sprawia, że są one bardziej
        atrakcyjne i efektywne.
      </ListItem>
      <ListItem>
        2. Remarketing: Dzięki ciasteczkom marketingowym można śledzić
        użytkowników, którzy odwiedzili witrynę, ale nie dokonali zakupu lub
        innej akcji, i ponownie kierować do nich reklamy, aby zachęcić do
        powrotu na stronę i finalizacji transakcji.
      </ListItem>
      <ListItem>
        3. Pomiar skuteczności reklam: Ciasteczka marketingowe pozwalają na
        monitorowanie wyników kampanii reklamowych, takich jak liczba kliknięć,
        konwersji czy wyświetleń, co umożliwia optymalizację strategii
        marketingowej.
      </ListItem>
      <ListItem>
        4. Zarządzanie reklamami w wielu kanałach: Użycie ciasteczek
        marketingowych ułatwia koordynację i integrację działań reklamowych
        prowadzonych w różnych kanałach, takich jak wyszukiwarki, media
        społecznościowe czy strony partnerskie.
      </ListItem>
      <ListItem>
        5. Testowanie i optymalizacja treści reklamowych: Ciasteczka
        marketingowe są wykorzystywane do przeprowadzania testów A/B oraz innych
        metod analizy, które pozwalają na ocenę, jakie przekazy, formaty czy
        kreatywności reklamowe są najbardziej skuteczne i angażujące dla
        użytkowników.
      </ListItem>
      <li style={{ color: "grey" }}>
        * w tożsamy sposób korzystamy z localStorage.
      </li>
    </LegalContainer>
  );
};

export default PrivacyPolicy;
