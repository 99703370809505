import { useEffect, useState } from "react";
import { TarotRequest } from "../../tarot/api/useCheckFreeTarot";
import { getUserTarots } from "../../session/api/userApi";

const useUserTarots = (userMail: string) => {
  const [tarots, setTarots] = useState<TarotRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchTarots = async () => {
      setLoading(true);
      try {
        const fetchedTarots = await getUserTarots(userMail);
        setTarots(fetchedTarots);
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        } else {
          setError(new Error("Unknown error occurred while fetching tarots"));
        }
      } finally {
        setLoading(false);
      }
    };

    if (userMail) {
      fetchTarots();
    }
  }, [userMail]);

  return { tarots, loading, error };
};

export default useUserTarots;
