import { User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonSecondary, H4 } from "../../../App.style";

import { copyToClipboard } from "@elastic/eui";
import { useTranslation } from "react-i18next";
import {
  StyledBack,
  StyledCard,
  StyledFront,
  StyledImage,
  TarotCardContainer,
  TarotCardNameContainer,
  TarotDetaieldLoadingWord,
  TarotDetailedButtonsContainer,
  TarotDetailedCardsContainer,
  TarotDetailedContainer,
  TarotDetailedContentContainer,
  TarotDetailedSentenceContainer,
  TarotDetailedTitleContainer,
} from "./TarotDetailed.style";
import useTarotById from "./useTarotById";
import tarotBack from "../../../assets/tarot_card_images/tarot_card_back.png";
import { TarotCard, useTarotCards } from "./useTarotCards";

interface TarotDetailedProps {
  currentUser: User | null;
}

const TarotDetailed: React.FC<TarotDetailedProps> = ({ currentUser }) => {
  const { t } = useTranslation();
  const { docId } = useParams();
  const navigate = useNavigate();

  const handleNoDocument = () => {
    navigate("/");
  };

  const { tarot, loading, error } = useTarotById(docId || "", handleNoDocument);

  const { getTarotCardsById } = useTarotCards();

  const handleButtonClick = () => {
    if (currentUser) {
      navigate("/fortunes");
    } else {
      navigate("/");
    }
  };

  const [wrozbaCopied, setWrozbaCopied] = useState<boolean>(false);

  const handleCopyWrozba = () => {
    const copyLink = `https://mysticer.com/tarot/${docId}`;
    copyToClipboard(copyLink);

    setWrozbaCopied(true);

    let interval = setInterval(() => {
      setWrozbaCopied(false);
      clearInterval(interval); // Clear the interval here
    }, 3000);
  };

  //text animation

  const [isTextAnimated, setIsTextAnimated] = useState(true);

  const lines = tarot?.ai_response.split("\n");
  let cumulativeWordCount = 0;

  useEffect(() => {
    if (tarot) {
      const tarotCardsIds = tarot?.user_form_data.tarotCards.map(
        (tarot) => tarot.id
      );

      const tarotCards = getTarotCardsById(tarotCardsIds);

      setCards(tarotCards);
    }
  }, [tarot]); // eslint-disable-line react-hooks/exhaustive-deps
  const [cards, setCards] = useState<TarotCard[]>();

  const [flippedCardsIndexes, setFlippedCardsIndexes] = useState<number[]>([]);

  const flipCardSequentially = () => {
    if (showFakeBack) {
      setShowFakeBack(false);
      setFlippedCardsIndexes([]);

      cards?.forEach((_, index) => {
        setTimeout(() => {
          setFlippedCardsIndexes((prev) => [...prev, index]);
        }, index * 500); // 500ms delay for each card
      });
    }
  };

  const [showFakeBack, setShowFakeBack] = useState<boolean>(true);

  if (loading) {
    return <div>{t("fortunesList.loading")}</div>;
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <TarotDetailedContainer>
      <TarotDetailedTitleContainer>
        {showFakeBack
          ? t("homepage.tarotDetailed.before")
          : t("homepage.tarotDetailed.after")}
      </TarotDetailedTitleContainer>

      <TarotDetailedCardsContainer onClick={flipCardSequentially}>
        {cards &&
          cards.map((card, index) => {
            return (
              <TarotCardContainer key={`card-${index}`}>
                <StyledCard $isFlipped={flippedCardsIndexes.includes(index)}>
                  {showFakeBack && (
                    <StyledImage
                      style={{
                        position: "absolute",
                        zIndex: 200,
                        background: "black",
                      }}
                      src={tarotBack}
                    />
                  )}
                  <StyledBack>
                    <StyledImage src={tarotBack} />
                  </StyledBack>
                  <StyledFront>
                    <StyledImage src={card.src} />
                  </StyledFront>
                </StyledCard>
                <TarotCardNameContainer>
                  {flippedCardsIndexes.includes(index) && !showFakeBack && (
                    <H4>{card.text}</H4>
                  )}
                </TarotCardNameContainer>
              </TarotCardContainer>
            );
          })}
      </TarotDetailedCardsContainer>

      {/* jesli jest tyle samo indeksow co liczby kart odpala sie animacja */}
      {flippedCardsIndexes.includes(
        Number(tarot?.user_form_data.cardsCount) - 1
      ) && (
        <TarotDetailedContentContainer onClick={() => setIsTextAnimated(false)}>
          {lines?.map((line, lineIndex) => (
            <TarotDetailedSentenceContainer key={`line-${lineIndex}`}>
              {line.split(" ").map((word, wordIndex) => {
                const animationDelay = cumulativeWordCount * 0.1;
                cumulativeWordCount++; // Increment the count for each word
                return (
                  <TarotDetaieldLoadingWord
                    key={`word-${lineIndex}-${wordIndex}`}
                    style={{
                      animationDelay: isTextAnimated
                        ? `${animationDelay}s`
                        : "0s",
                    }}
                  >
                    {word}
                  </TarotDetaieldLoadingWord>
                );
              })}
            </TarotDetailedSentenceContainer>
          ))}
        </TarotDetailedContentContainer>
      )}

      <TarotDetailedButtonsContainer>
        <ButtonSecondary
          onClick={handleCopyWrozba}
          onMouseLeave={() => setWrozbaCopied(false)}
        >
          {wrozbaCopied
            ? t("fortuneDetailed.buttons.copied")
            : t("fortuneDetailed.buttons.copy")}
        </ButtonSecondary>
        <Button onClick={handleButtonClick}>
          {currentUser
            ? t("fortuneDetailed.buttons.myFortunes")
            : t("fortuneDetailed.buttons.homepage")}
        </Button>
      </TarotDetailedButtonsContainer>
    </TarotDetailedContainer>
  );
};

export default TarotDetailed;
