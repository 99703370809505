import React, { useState } from "react";
import { FAQContainer, FAQContentContainer } from "./FAQ.style";
import { H2 } from "../../../../App.style";
import { TitleContainer } from "../../Homepage.style";
import { useInView } from "react-intersection-observer";
import FAQItem, { FAQQuestion } from "./FAQItem";
import { useTranslation } from "react-i18next";
import { User } from "firebase/auth";

interface FAQProps {
  openLoginModal: () => void;
  openContactModal: () => void;
  currentUser: User | null;
}

const FAQ: React.FC<FAQProps> = ({
  openLoginModal,
  openContactModal,
  currentUser,
}) => {
  const { t } = useTranslation();
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const handleToggle = (index: number) => {
    setOpenIndexes((prevOpenIndexes) => {
      const currentIndex = prevOpenIndexes.indexOf(index);
      if (currentIndex > -1) {
        return prevOpenIndexes.filter((i) => i !== index);
      } else {
        return [...prevOpenIndexes, index];
      }
    });
  };

  const titleInView = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const questions: FAQQuestion[] = [
    {
      title: t("homepage.faq.question1.title"),
      text: t("homepage.faq.question1.text"),
    },
    {
      title: t("homepage.faq.question2.title"),
      text: t("homepage.faq.question2.text"),
    },
    {
      title: t("homepage.faq.question3.title"),
      text: t("homepage.faq.question3.text"),
      ...(!currentUser && {
        button: {
          text: t("navbar.login"),
          onClick: openLoginModal,
        },
      }),
    },
    {
      title: t("homepage.faq.question4.title"),
      text: t("homepage.faq.question4.text"),
    },
    {
      title: t("homepage.faq.question5.title"),
      text: t("homepage.faq.question5.text"),
      button: {
        text: t("homepage.faq.question5.buttonText"),
        onClick: openContactModal,
      },
    },
    {
      title: t("homepage.faq.question6.title"),
      text: t("homepage.faq.question6.text"),
      button: {
        text: t("homepage.faq.question5.buttonText"),
        onClick: openContactModal,
      },
    },
  ];

  return (
    <FAQContainer>
      <TitleContainer ref={titleInView.ref} $isVisible={titleInView.inView}>
        <H2>{t("homepage.faq.title")}</H2>
      </TitleContainer>
      <FAQContentContainer>
        {questions.map((question, index) => {
          const isOpen = openIndexes.includes(index);
          return (
            <FAQItem
              key={`faq-question-${index}`}
              isOpen={isOpen}
              question={question}
              index={index}
              handleToggle={handleToggle}
            />
          );
        })}
      </FAQContentContainer>
    </FAQContainer>
  );
};

export default FAQ;
