export const translationsPl = {
  homepage: {
    hero: {
      moreFortunes: "Zobacz inne dostepne wróżby",
      title: {
        part1: "Gotowy poznać przyszłość?",
        part2: "czeka, aby ci ją objawić.",
      },
      subtitle: {
        part1:
          "Czytanie z tarota, personalizowane przepowiednie, interpretacje snów.",
        part2: "Przyszłość jest bliżej, niż myślisz!",
      },
      buttonText: "ODKRYJ PRZYSZŁOŚĆ",
      buttonEmptyText: "JAK TO DZIAŁA?",
    },
    tarotDetailed: {
      before: "Odkryj swoje karty",
      after: "Mysticer zajrzał w karty, ma ci coś do powiedzenia",
    },
    wrozbaSelector: {
      title: "Która wróżbę dzisiaj wybierzesz?",
      prophecy: "Przepowiednia",
      dream: "Interpretacja snu",
      coin: "Rzut monetą",
      next: "Dalej",
      tarotDescription:
        "Interpretacja wybranej liczby kart tarota z uwzględnieniem wybranego tematu.",
      prophecyDescription:
        "Spersonalizowana przepowiednia, dotycząca wybranego tematu i okresu czasu.",
      dreamDescription:
        "Szczegółowa interpretacja snu, na podstawie jego opisu.",
      coinDescription: "Darmowy rzut monetą, który pomoże podjąć ważne decyzje",
      form: {
        cardCount: "Liczba kart",
        stack: "Wybierz stos",
        selectcard1: "Wybierz 1 kartę",
        selectcard2: "Wybierz 2 karty",
        selectcard3: "Wybierz 3 karty",
        selectcard5: "Wybierz 5 kart",
        needsLogin: "Wymaga logowania",
        card1: {
          title: "1 Karta",
          price: "Darmowa",
          description:
            "Zwięzły wgląd i podpowiedzi w wybranej przez ciebie kategorii. Dostępna raz na 24h.",
        },
        card2: {
          title: "2 Karty",
          price: "4.99zł",
          description:
            "Rozbudowaną analiza i głębsze zrozumienie Twojej sytuacji.",
        },
        card3: {
          title: "3 Karty",
          price: "4.99zł",
          description:
            "Szczegółowa analiza i dokładny wgląd w Twoją przyszłość. Zapewnia ogólne wskazówki.",
        },
        card5: {
          title: "5 Kart",
          price: "9.99zł",
          description:
            "Bardzo szczegółowy i wszechstronny przekaz dotyczacy twojej przyszłości. Przekazuje wartościowe rady i wskazówki.",
        },
      },
    },
    introduction: {
      title:
        "Już tylko jedno kliknięcie dzieli cię od doświadczenia pierwszego w swoim rodzaju wróżenia AI.",
      subtitle: {
        part1:
          "Z nami przyszłość staje się bardziej fascynująca, a każda chwila przewidywań staje się wyjątkowym wydarzeniem.",
        part2:
          "Witaj w erze, w której sztuczna inteligencja staje się Twoim osobistym wróżbitą!",
      },
    },
    howItWorks: {
      title: "Trzy kroki do wróżby",
      step1: {
        title: "Kliknij i wejdź do świata wróżb",
        line1: `Zacznij swoją przygodę z wróżbami AI, klikając przycisk "Wróżba" w pasku nawigacyjnym, lub kliknij `,
        line2:
          "Po kliknięciu, wypełnij krótki formularz, aby otrzymać jak najlepiej spersonalizowaną wróżbę z tarota.",
        here: "tutaj",
      },
      step2: {
        title: `Odbierz wróżbę`,
        text: "Po wypełnieniu formularza, twoja wróżba zostanie wygenerowana przez nasz system AI i dostarczona bezpośrednio do Ciebie.",
      },
    },
    faq: {
      title: "FAQ - często zadawane pytania",
      question1: {
        title: "Jak działa wróżbiarstwo AI?",
        text: "Nasze wróżby AI korzystają z zaawansowanych algorytmów sztucznej inteligencji, aby analizować Twoje zapytania i dostarczać spersonalizowanych, przemyślanych odpowiedzi. Ten proces łączy w sobie elementy wróżbiarstwa i sztucznej intuicji, tworząc unikalne doświadczenie przepowiadania przyszłości.",
      },
      question2: {
        title: "Czy moje dane są bezpieczne?",
        text: "Tak, Twoje dane są całkowicie bezpieczne. Wszystkie płatności są obsługiwane przez Stripe, lidera w branży płatności cyfrowych, zapewniającego wysoki poziom bezpieczeństwa i ochrony danych.",
      },
      question3: {
        title: "Czy muszę się zarejestrować, aby korzystać z usługi?",
        text: "Korzystanie z naszych usług nie wymaga rejestracji. Jednakże, jeśli zdecydujesz się utworzyć konto, uzyskasz dostęp do dodatkowych funkcji. Oprócz możliwości przeglądania historii swoich wróżb na dedykowanej stronie, co ułatwi Ci późniejsze śledzenie Twoich przepowiedni, po zalogowaniu otrzymasz również dostęp do specjalnej opcji: wróżenia z jednej karty raz na 24 godziny zupełnie za darmo. To ekskluzywne udogodnienie jest dostępne tylko dla zarejestrowanych użytkowników.",
      },
      question4: {
        title: "Czy wróżby AI są zawsze pozytywne?",
        text: "Staramy się, aby nasze wróżby były zbalansowane i autentyczne. Oznacza to, że nie zawsze są one wyłącznie pozytywne, ale zawsze mają na celu dostarczenie inspiracji i perspektywy.",
      },
      question5: {
        title: "Co zrobić jeśli mam problem techniczny z serwisem?",
        text: `W razie napotkania problemów technicznych z naszym serwisem, skorzystaj z poniższego przycisku lub opcji "Kontakt" dostępnej w pasku nawigacyjnym. Otworzy się formularz kontaktowy, gdzie będziesz mógł opisać swój problem, a my postaramy się jak najszybciej go rozwiązać.`,
        buttonText: "Kontakt",
      },
      question6: {
        title: "Jak mogę udzielić opinii lub sugerować ulepszenia?",
        text: "Twoje opinie i sugestie są dla nas niezwykle cenne. Jeśli masz jakiekolwiek uwagi lub pomysły na ulepszenia naszego serwisu, zachęcamy do skorzystania z formularza kontaktowego. Aby do niego przejść, możesz użyć przycisku umieszczonego poniżej tej wiadomości lub wybrać opcję 'Kontakt' dostępną w pasku nawigacyjnym. Twoje spostrzeżenia pomogą nam w dalszym doskonaleniu naszych usług.",
      },
    },
    socials: {
      title: "Znajdziesz nas na",
    },
  },
  fortuneModal: {
    requiredError: "To pole jest wymagane",
    select: "Wybierz",
    step1: {
      title: "Przepowiednia",
      name: "Imię",
      age: "Wiek",
      gender: "Płeć",
      profession: "Zawód",
      saveData: "Zapisz dane do przyszłych wróżb",
      proffesionPlaceholder: "Znajdź swój zawód",
    },
    step2: {
      title: "Jakie tajemnice przyszłości chciałbyś odkryć?",
      fortuneTopic: "Temat wróżby",
      time: "Czas",
      mood: "Twój obecny nastrój",
      relationship_status: "Status związku",
    },
    step3: {
      title: "Upewnij się, że wszystko się zgadza",
      price: "Cena",
      price2: "4.99zł",
      terms: {
        accept: "Akceptuje ",
        terms: "Regulamin",
        and: " i ",
        privacy: "Politykę prywatności",
        error: "Aby przejść dalej, musisz zaakceptować regulamin.",
      },
    },

    buttons: {
      close: "ZAMKNIJ",
      back: "WRÓĆ",
      continue: "DALEJ",
      payment: "DO PŁATNOŚCI",
    },
  },
  fortunesList: {
    title: "Moje wróżby",
    tarot: "Tarot",
    prophecy: "Przepowiednia",
    noFortunes: {
      row1: "Wygląda na to, że nie masz jeszcze żadnych wróżb",
      row2: "Aby to zmienić, kliknij przycisk",
      buttonText: "WRÓŻBA",
    },
    loading: "Ładowanie...",
    seeMore: "ZOBACZ WIĘCEJ",
  },
  fortuneDetailed: {
    title: "Mysticer zajrzał w twoją przyszłośc i ma ci coś do powiedzenia",
    buttons: {
      copied: "SKOPIOWANO",
      copy: "KOPIUJ LINK",
      myFortunes: "MOJE WRÓŻBY",
      homepage: "STRONA GŁÓWNA",
    },
  },
  dream: {
    detailed: {
      title: ", oto twoja interpretacja snu",
    },
    form: {
      title: "Interpretacja snu",
      name: "Imie",
      description: "Opis snu",
      errorLess: "Opis snu musi zawierać co najmniej 20 znaków.",
      errorMore: "Opis snu nie może przekraczać 300 znaków.",
      price: "Cena",
      loadingTitle:
        "W tajemniczych zakamarkach czasu Mysticer odkrywa znaczenie twojego snu.",
      loadingSubtitle: "Odpocznij chwile - interpretacja jest juz blisko.",
      info1:
        "Uwaga: Prosimy o klarowny i zrozumiały opis snu. Dzięki temu dokładniej zinterpretujemy Twoje doświadczenie.",
      info2: "Nie odpowiadamy za nieprzemyślane treści wpisane w formularzu.",
    },
  },

  navbar: {
    homepage: "Strona główna",
    myFortunes: "Moje wróżby",
    fortune: "Wróżba",
    howItWorks: "Jak to działa?",
    help: "Pomoc",
    login: "Zaloguj",
    contact: "Kontakt",
    popover: {
      account: "Konto",
      settings: "Ustawienia",
      logout: "Wyloguj",
    },
  },
  helpModal: {
    form: {
      title: "Kontakt",
      subLine1:
        "Użyj tego formularza, aby przekazać nam swoje uwagi lub sugestie.",
      subLine2:
        "Jeśli napotkałeś problem, prosimy o zaznaczenie opcji poniżej - pomoże nam to w szybszym działaniu.",
      problem: "Zgłaszam problem",
      messageTitle: "Tytuł",
      fieldRequired: "To pole jest wymagane",
      message: "Wiadomość",
      buttons: {
        close: "ZAMKNIJ",
        send: "WYŚLIJ",
      },
    },
    success: {
      line1: "Dziękujemy za przesłanie formularza.",
      line2: "Wszelkie uwagi i sugestie są dla nas cenne.",
      line3:
        "Jeśli zgłosiłeś problem, zajmiemy się nim priorytetowo. Oczekuj odpowiedzi e-mailowej wkrótce.",
    },
    error: {
      line1:
        "Nie udało się wysłać Twojego zgłoszenia z powodu technicznych trudności.",
      line2:
        "Prosimy, sprawdź swoje połączenie internetowe lub spróbuj ponownie za chwilę.",
    },
  },

  wrozbaResult: {
    loading: {
      title:
        "W tajemniczych zakamarkach czasu Mysticer odkrywa Twoją przyszłość.",
      subtitle: "Odpocznij chwilę - wróżba jest już blisko.",
      sentences: {
        1: "Rozkładanie kart przyszłości na cyberprzestrzeni...",
        2: "Sortowanie gwiazd w kosmicznej poczekalni...",
        3: "Odkurzanie kryształowej kuli...",
        4: "Mieszanie magicznych składników w kotle wróżebnymi...",
        5: "Przerwa kawowa...",
        6: "Mysticer przegląda zbiory danych wszechświata...",
        7: "Zamykanie portalu do innej rzeczywistości...",
        8: "Liczenie konstelacji...",
        9: "Rozrzedzanie mistycznej mgły...",
        10: "Przeglądanie możliwości...",
        11: "Łapanie meteorytów...",
        12: "Odkrywanie nowych planet...",
        13: "Badanie syntaksy przyszłości...",
        14: "Zapisywanie jedynek i zer...",
      },
    },
    error: {
      title: "Coś poszło nie tak przy generowaniu wróżby.",
      subLine1: "Proces zwrotu środków został przekazany do Stripe.",
      subLine2:
        "Jeśli środki nie zostaną zwrócone do 5 dni, prosimy o kontakt przez nasz formularz zgłoszeniowy.",
      subLine3: "Przepraszamy za utrudnienia.",
      homepage: "Strona główna",
    },
  },

  loginModal: {
    login: {
      google: "ZALOGUJ SIĘ Z GOOGLE",
      error: "Wystąpił błąd. Spróbuj ponownie.",
      wrongEmail: "Błędny adres e-mail",
      password: "Hasło",
      forgotPassword: "Zapomniałem hasła",
      login: "Zaloguj się",
      dontHaveAcc: "Nie masz konta?",
      register: "Zarejestruj się",
    },
    register: {
      title: "Stwórz swoje konto",
      password: "Hasło",
      rePassword: "Powtórz hasło",
      signUp: "Załóż konto",
      haveAnAccount: "Posiadasz już konto?",
      error: {
        wrongEmail: "Błędny adres e-mail",
        tooShort: "Hasło musi mieć minimum 8 znaków",
        same: "Hasła muszą być takie same",
        alreadyInUse: "Adres e-mail jest już używany.",
        wtf: "Wystąpił błąd. Spróbuj ponownie.",
      },
    },
    passwordReset: {
      title: "Przypomnij hasło",
      remind: "Przypomnij hasło",
      backToLogin: "Wróć do ekranu logowania",
      sent: {
        line1: "Link do resetowania hasła został wysłany na Twój adres email.",
        line2:
          "Sprawdź swoją skrzynkę odbiorczą oraz folder spam, aby kontynuować proces zmiany hasła.",
      },
    },
    or: "albo",
  },

  settings: {
    title: "Ustawienia",
    userData: {
      myData: "Moje dane",
      name: "Imię",
      age: "Wiek",
      profession: "Zawód",
      required: "To pole jest wymagane",
      dataSaved: "Twoje dane zostały zapisane!",
      updateButton: "Zaaktualizuj dane",
    },
    passwordChange: {
      title: "Zmiany hasła",
      current: "Aktualne",
      password: "Hasło",
      rePassword: "Powtórz hasło",
      saveData: "Zaaktualizuj hasło",
      savedPassword: "Hasło zostało pomyślnie zmienione!",
      error: {
        lengthError: "Hasło musi mieć minimum 8 znaków",
        notTheSame: "Hasła nie są takie same!",
        wrongPassword: "Złe hasło",
        something: "Coś poszło nie tak, spróbuj jeszcze raz.",
      },
    },
    deleteAccount: {
      title: "Usuń konto",
      subtitle1:
        "Po usunięciu konta nie ma możliwości cofnięcia tej operacji. ",
      subtitle2: "Wpisz swój email, aby potwierdzić usunięcie konta.",
      email: "Email",
      buttonText: "Usuń konto",
      deleted: "Twoje konto zostanie usunięte do 48h.",
    },
    button: {
      close: "ZAMKNIJ",
    },
  },
  languageSelector: {
    title: "Wybierz język",
  },
  serviceWork: {
    title: "Prace techniczne",
    subtitle: "Wrócimy o {{backTime}} GMPT+1",
  },

  footer: {
    legal: {
      terms: "Regulamin",
      privacy: "Prywatnośc",
    },
  },

  tarot: {
    title: "Czytanie z kart tarota",
    card1: {
      name: "Głupiec",
      description: "",
    },
    card2: {
      name: "Mag",
      description: "",
    },
    card3: {
      name: "Kapłanka",
      description: "",
    },
    card4: {
      name: "Cesarzowa",
      description: "",
    },
    card5: {
      name: "Cesarz",
      description: "",
    },
    card6: {
      name: "Kapłan",
      description: "",
    },
    card7: {
      name: "Kochankowie",
      description: "",
    },
    card8: {
      name: "Rydwan",
      description: "",
    },
    card9: {
      name: "Siła",
      description: "",
    },
    card10: {
      name: "Pustelnik",
      description: "",
    },
    card11: {
      name: "Koło Fortuny",
      description: "",
    },
    card12: {
      name: "Sprawiedliwość",
      description: "",
    },
    card13: {
      name: "Wisielec",
      description: "",
    },
    card14: {
      name: "Śmierć",
      description: "",
    },
    card15: {
      name: "Umiarkowanie",
      description: "",
    },
    card16: {
      name: "Diabeł",
      description: "",
    },
    card17: {
      name: "Wieża",
      description: "",
    },
    card18: {
      name: "Gwiazda",
      description: "",
    },
    card19: {
      name: "Księżyc",
      description: "",
    },
    card20: {
      name: "Słońce",
      description: "",
    },
    card21: {
      name: "Sąd Ostateczny",
      description: "",
    },
    card22: {
      name: "Świat",
      description: "",
    },

    //cups
    card23: {
      name: "As Kielichów",
      description: "",
    },
    card24: {
      name: "Dwa Kielichy",
      description: "",
    },
    card25: {
      name: "Trzy Kielichy",
      description: "",
    },
    card26: {
      name: "Cztery Kielichy",
      description: "",
    },
    card27: {
      name: "Pięć Kielichów",
      description: "",
    },
    card28: {
      name: "Sześć Kielichów",
      description: "",
    },
    card29: {
      name: "Siedem Kielichów",
      description: "",
    },
    card30: {
      name: "Osiem Kielichów",
      description: "",
    },
    card31: {
      name: "Dziewięć Kielichów",
      description: "",
    },
    card32: {
      name: "Dziesięć Kielichów",
      description: "",
    },
    card33: {
      name: "Paź Kielichów",
      description: "",
    },
    card34: {
      name: "Rycerz Kielichów",
      description: "",
    },
    card35: {
      name: "Królowa Kielichów",
      description: "",
    },
    card36: {
      name: "Król Kielichów",
      description: "",
    },

    //cups
    card37: {
      name: "As Mieczy",
      description: "",
    },
    card38: {
      name: "Dwa Miecze",
      description: "",
    },
    card39: {
      name: "Trzy Miecze",
      description: "",
    },
    card40: {
      name: "Cztery Miecze",
      description: "",
    },
    card41: {
      name: "Pięć Mieczy",
      description: "",
    },
    card42: {
      name: "Sześć Mieczy",
      description: "",
    },
    card43: {
      name: "Siedem Mieczy",
      description: "",
    },
    card44: {
      name: "Osiem Mieczy",
      description: "",
    },
    card45: {
      name: "Dziewięć Mieczy",
      description: "",
    },
    card46: {
      name: "Dziesięć Mieczy",
      description: "",
    },
    card47: {
      name: "Paź Mieczy",
      description: "",
    },
    card48: {
      name: "Rycerz Mieczy",
      description: "",
    },
    card49: {
      name: "Królowa Mieczy",
      description: "",
    },
    card50: {
      name: "Król Mieczy",
      description: "",
    },

    //cups
    card51: {
      name: "As Buław",
      description: "",
    },
    card52: {
      name: "Dwie Buławy",
      description: "",
    },
    card53: {
      name: "Trzy Buławy",
      description: "",
    },
    card54: {
      name: "Cztery Buławy",
      description: "",
    },
    card55: {
      name: "Pięć Buław",
      description: "",
    },
    card56: {
      name: "Sześć Buław",
      description: "",
    },
    card57: {
      name: "Siedem Buław",
      description: "",
    },
    card58: {
      name: "Osiem Buław",
      description: "",
    },
    card59: {
      name: "Dziewięć Buław",
      description: "",
    },
    card60: {
      name: "Dziesięć Buław",
      description: "",
    },
    card61: {
      name: "Paź Buław",
      description: "",
    },
    card62: {
      name: "Rycerz Buław",
      description: "",
    },
    card63: {
      name: "Królowa Buław",
      description: "",
    },
    card64: {
      name: "Król Buław",
      description: "",
    },

    //denary
    card65: {
      name: "As Denarów",
      description: "",
    },
    card66: {
      name: "Dwa Denary",
      description: "",
    },
    card67: {
      name: "Trzy Denary",
      description: "",
    },
    card68: {
      name: "Cztery Denary",
      description: "",
    },
    card69: {
      name: "Pięć Denarów",
      description: "",
    },
    card70: {
      name: "Sześć Denarów",
      description: "",
    },
    card71: {
      name: "Siedem Denarów",
      description: "",
    },
    card72: {
      name: "Osiem Denarów",
      description: "",
    },
    card73: {
      name: "Dziewięć Denarów",
      description: "",
    },
    card74: {
      name: "Dziesięć Denarów",
      description: "",
    },
    card75: {
      name: "Paź Denarów",
      description: "",
    },
    card76: {
      name: "Rycerz Denarów",
      description: "",
    },
    card77: {
      name: "Królowa Denarów",
      description: "",
    },
    card78: {
      name: "Król Denarów",
      description: "",
    },
  },
};
