import React, { forwardRef } from "react";
import styled from "styled-components";

const Wrapper = styled.section`
  width: 100%;
  color: white;

  display: flex;
  justify-content: center;
  position: relative;

  /* border-top: 2px solid #3e3547; */

  padding: 20px 10px;

  @media (min-width: 320px) {
    padding: 50px 20px;
  }

  @media (min-width: 600px) {
    padding: 60px 40px;
  }

  @media (min-width: 768px) {
    padding: 100px 50px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 2px;
    background: linear-gradient(to bottom, #5e439b, #a55fbe);
    animation: glow 2s infinite ease-in-out;
    border-radius: 2px; // Optional: to give a slightly rounded effect to the lightsaber beam
    z-index: 1;
  }

  @keyframes glow {
    0%,
    100% {
      box-shadow: 0 0 10px #5e439b, 0 0 20px #a55fbe, 0 0 30px #a55fbe,
        0 0 40px #cc88ff, 0 0 70px #cc88ff, 0 0 80px #cc88ff, 0 0 100px #cc88ff,
        0 0 150px #cc88ff;
    }
    50% {
      box-shadow: 0 0 20px #5e439b, 0 0 30px #a55fbe, 0 0 40px #a55fbe,
        0 0 50px #cc88ff, 0 0 80px #cc88ff, 0 0 90px #cc88ff, 0 0 110px #cc88ff,
        0 0 160px #cc88ff;
    }
  }
`;

interface SectionProps {
  id: string;
  children?: React.ReactNode; // to include children
}

export const Section = forwardRef<HTMLElement, SectionProps>(
  ({ id, children }, ref) => {
    return (
      <Wrapper id={id} ref={ref}>
        {children}
      </Wrapper>
    );
  }
);

export default Section;
