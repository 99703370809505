import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  deleteDoc,
  doc,
} from "@firebase/firestore";
import { db } from "../../../../config/firebase";
import { FortuneRequest } from "../../wrozba/result/useCheckPayment";
import { Timestamp } from "@firebase/firestore";
import { ContactRequest } from "../../contact/useAddContactDocument";
import { TarotRequest } from "../../tarot/api/useCheckFreeTarot";

export async function getAdminFortunes(
  status?: "pending" | "success" | "error",
  selectedDate?: Date
): Promise<FortuneRequest[]> {
  const fortunesRef = collection(db, "Fortunes");

  let q;
  if (status && selectedDate) {
    q = query(
      fortunesRef,
      where("status", "==", status),
      where("created_at", ">=", Timestamp.fromDate(selectedDate)),
      orderBy("created_at", "desc")
    );
  } else if (status) {
    q = query(
      fortunesRef,
      where("status", "==", status),
      orderBy("created_at", "desc")
    );
  } else if (selectedDate) {
    q = query(
      fortunesRef,
      where("created_at", ">=", Timestamp.fromDate(selectedDate)),
      orderBy("created_at", "desc")
    );
  } else {
    q = query(fortunesRef, orderBy("created_at", "desc"));
  }

  const querySnapshot = await getDocs(q);

  const meetings: FortuneRequest[] = querySnapshot.docs.map((doc) => {
    const data = doc.data() as Omit<FortuneRequest, "id">;
    return {
      id: doc.id,
      ...data,
    };
  });

  return meetings;
}

export async function getAdminContacts(
  type?: "message" | "error",
  status?: "pending" | "resolved"
): Promise<ContactRequest[]> {
  const contactsRef = collection(db, "Contacts");

  let q;

  if (type === "message") {
    q = query(contactsRef, where("error", "==", false));
  } else if (type === "error") {
    q = query(contactsRef, where("error", "==", true));
  } else {
    q = query(contactsRef);
  }

  if (status) {
    q = query(q, where("status", "==", status));
  }

  q = query(q, orderBy("created_at", "desc"));

  const querySnapshot = await getDocs(q);

  const contacts: ContactRequest[] = querySnapshot.docs.map((doc) => {
    const data = doc.data() as Omit<ContactRequest, "id">;
    return {
      id: doc.id,
      ...data,
    };
  });

  return contacts;
}

export async function deleteFortunesByIds(docIds: string[]) {
  try {
    const deletePromises = docIds.map((id) => {
      const docRef = doc(db, "Fortunes", id);
      return deleteDoc(docRef);
    });

    await Promise.all(deletePromises);
    console.log("Documents successfully deleted");
  } catch (error) {
    console.error("Error deleting documents: ", error);
  }
}

//tarots
export async function getAdminTarots(
  status?: "pending" | "success" | "error",
  selectedDate?: Date,
  cardsCount?: string // Assuming this is a string representation of a number
): Promise<TarotRequest[]> {
  const tarotsRef = collection(db, "Tarots"); // replace with your collection name

  let q;

  const conditions = [];
  if (status) {
    conditions.push(where("status", "==", status));
  }
  if (selectedDate) {
    conditions.push(
      where("created_at", ">=", Timestamp.fromDate(selectedDate))
    );
  }
  if (cardsCount) {
    conditions.push(where("user_form_data.cardsCount", "==", cardsCount)); // This will perform a string comparison, not numerical
  }

  conditions.push(orderBy("created_at", "desc"));

  q = query(tarotsRef, ...conditions);

  const querySnapshot = await getDocs(q);

  const documents: TarotRequest[] = querySnapshot.docs.map((doc) => {
    const data = doc.data() as Omit<TarotRequest, "id">;
    return {
      id: doc.id,
      ...data,
    };
  });

  return documents;
}

export async function deleteTarotsByIds(docIds: string[]) {
  try {
    const deletePromises = docIds.map((id) => {
      const docRef = doc(db, "Tarots", id);
      return deleteDoc(docRef);
    });

    await Promise.all(deletePromises);
    console.log("Documents successfully deleted");
  } catch (error) {
    console.error("Error deleting documents: ", error);
  }
}
