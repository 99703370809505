import { styled } from "styled-components";
import { FadeInProps, fadeInAnimation } from "../../Homepage.style";

export const FAQContainer = styled.div`
  width: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FAQContentContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 800px;

  @media (min-width: 768px) {
    gap: 30px;
  }
`;

export const QuestionContainer = styled.div<FadeInProps>`
  text-align: left;
  display: flex;

  cursor: pointer;

  opacity: 0;
  transform: translateY(40px);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, visibility 0.6s;
  ${({ $isVisible }) => $isVisible && fadeInAnimation}
`;

export const QuestionTitleContainer = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

export const AnswerContainer = styled.div<{ $isOpen: boolean }>`
  margin-top: 15px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;

  width: 90%;
  max-height: ${({ $isOpen }) => ($isOpen ? "250px" : "0")};
`;

export const ChevronIcon = styled.img<{ $isOpen: boolean }>`
  width: 22px;
  height: 22px;
  transform: ${({ $isOpen }) => ($isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.6s ease;
`;
