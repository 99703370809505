import React, { useState } from "react";
import { AccountDeleteContainer } from "../SettingsModal.style";
import { Button, ButtonSecondary, H3, P } from "../../../App.style";
import { useTranslation } from "react-i18next";
import { User } from "firebase/auth";
import { EuiFieldText, EuiFormRow } from "@elastic/eui";
import { WrozbaInputTitle } from "../../wrozba/Wrozba.style";
import { deleteUser } from "../../session/api/userApi";
import { signOutUser } from "../../session/api/sessionApi";

interface DeleteAccountProps {
  currentUser: User;
}

const DeleteAccount: React.FC<DeleteAccountProps> = ({ currentUser }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>("");

  const [accountDeleted, setAccountDeleted] = useState<boolean>(false);

  const handleDeleteAccount = async () => {
    await deleteUser(email).then(() => {
      setAccountDeleted(true);
    });
  };

  const [isAccountDeleteVisible, setIsAccountDeleteVisible] =
    useState<boolean>(false);

  const correctEmail = email === currentUser.email;

  return (
    <AccountDeleteContainer>
      {!isAccountDeleteVisible ? (
        <div onClick={() => setIsAccountDeleteVisible(true)}>
          <ButtonSecondary style={{ color: "#AC2929" }}>
            {t("settings.deleteAccount.buttonText")}
          </ButtonSecondary>
        </div>
      ) : accountDeleted ? (
        <>
          <P>{t("settings.deleteAccount.deleted")}</P>
          <div style={{ marginTop: 5 }}>
            <Button onClick={signOutUser}>{t("navbar.popover.logout")}</Button>
          </div>
        </>
      ) : (
        <>
          <H3 style={{ marginBottom: 10, color: "#AC2929" }}>
            {t("settings.deleteAccount.title")}
          </H3>

          <P>{t("settings.deleteAccount.subtitle1")}</P>
          <P>{t("settings.deleteAccount.subtitle2")}</P>

          <EuiFormRow
            style={{ marginTop: 10 }}
            label={
              <WrozbaInputTitle>
                {t("settings.deleteAccount.email")}
              </WrozbaInputTitle>
            }
            labelType="legend"
          >
            <EuiFieldText
              maxLength={40}
              style={{
                background: "#0D0D0D",
                border: "none",
                outline: "none",
              }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              compressed
            />
          </EuiFormRow>

          {correctEmail && (
            <div>
              <ButtonSecondary
                style={{ color: "#AC2929" }}
                onClick={handleDeleteAccount}
              >
                {t("settings.deleteAccount.buttonText")}
              </ButtonSecondary>
            </div>
          )}
        </>
      )}
    </AccountDeleteContainer>
  );
};

export default DeleteAccount;
