import React from "react";
import {
  CustomSettingsModal,
  SettingsContainer,
  SettingsTitle,
} from "./SettingsModal.style";
import { EuiModalBody, EuiModalFooter } from "@elastic/eui";
import { User } from "firebase/auth";
import { H2, P, Button } from "../../App.style";
import { FirestoreUser } from "../session/api/userApi";
import FortuneData from "./components/FortuneData";
import PasswordChange from "./components/PasswordChange";
import DeleteAccount from "./components/DeleteAccount";
import { useTranslation } from "react-i18next";

interface SettingsModalProps {
  closeModal: () => void;
  currentUser: User;
  firestoreUser: FirestoreUser;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
  closeModal,
  currentUser,
  firestoreUser,
}) => {
  const { t } = useTranslation();
  const providers = currentUser?.providerData.map(
    (provider) => provider.providerId
  );
  // Sprawdź, czy użytkownik zalogował się za pomocą e-maila i hasła
  const isEmailPasswordUser = providers?.includes("password");

  return (
    <CustomSettingsModal onClose={closeModal} initialFocus="[name=popswitch]">
      <SettingsTitle>
        <H2>{t("settings.title")}</H2>
        <P style={{ marginTop: 20 }}>{currentUser?.email}</P>
      </SettingsTitle>

      <EuiModalBody>
        <SettingsContainer>
          <FortuneData
            currentUser={currentUser}
            firestoreUser={firestoreUser}
          />

          {isEmailPasswordUser && <PasswordChange currentUser={currentUser} />}

          <DeleteAccount currentUser={currentUser} />
        </SettingsContainer>
      </EuiModalBody>

      <EuiModalFooter>
        <Button type="button" form="form" onClick={closeModal}>
          {t("settings.button.close")}
        </Button>
      </EuiModalFooter>
    </CustomSettingsModal>
  );
};

export default SettingsModal;
