import React, { useEffect, useRef, useState } from "react";
import { TarotFormContainer, TarotTitle, TarotTitleText } from "./Tarot.style";
import { Button, H2, P } from "../../App.style";
import { EuiButtonEmpty } from "@elastic/eui";
import { useTranslation } from "react-i18next";
import {
  FirestoreUser,
  UserTarotFormData,
  updateUser,
} from "../session/api/userApi";
import { useNavigate } from "react-router-dom";
import { User } from "firebase/auth";
import TarotFormStep1 from "./form/TarotFormStep1";
import TarotStackSelector from "./form/TarotStackSelector";
import TarotCardsSelector from "./form/TarotCardsSelector";
import { useCheckFreeTarot } from "./api/useCheckFreeTarot";
import { serverTimestamp } from "@firebase/firestore";
import TarotFreeLoading from "./form/TarotFreeLoading";
import { useTarotCards } from "./detailed/useTarotCards";
import moment from "moment";

interface TarotFormProps {
  currentUser: User | null;
  firestoreUser: FirestoreUser | null;
  openLoginModal: () => void;
}

const TarotForm: React.FC<TarotFormProps> = ({
  currentUser,
  firestoreUser,
  openLoginModal,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(1);

  const [tarot, setTarot] = useState<UserTarotFormData>({
    name: firestoreUser?.name ?? "",
    fortune: "",
    cardsCount: "",
    relationship_status: "",
    tarotCards: [],
  });

  useEffect(() => {
    if (firestoreUser?.name) {
      setTarot((prevTarot) => ({
        ...prevTarot,
        name: firestoreUser.name,
      }));
    }
  }, [firestoreUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (firestoreUser && !firestoreUser.lastFreeTarot) {
      setTarot({ ...tarot, cardsCount: "1" });
    }
  }, [firestoreUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRandomTarotCards } = useTarotCards();

  useEffect(() => {
    if (currentStep === 2) {
      const tarotCardsRandomized = getRandomTarotCards(
        Number(tarot.cardsCount)
      );
      const tarotCards = tarotCardsRandomized.map((card) => {
        return { name: card.text, id: card.id };
      });

      setTarot({ ...tarot, tarotCards: tarotCards });
    }
  }, [currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedCardIndexes, setSelectedCardIndexes] = useState<number[]>([]);

  const goToPreviousStep = () => {
    setCurrentStep((prev) => prev - 1);
    setSelectedCardIndexes([]);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const { checkFreeTarot, response, error } = useCheckFreeTarot();

  // const addTarot = async (userData: UserTarotFormData): Promise<string> => {
  //   const tarotsRef = collection(db, "Tarots");

  //   const tarot_payload: TarotRequest = {
  //     status: "pending",
  //     user_form_data: userData,
  //     ai_response: "",
  //     user_id: currentUser?.uid ?? null,
  //     created_at: serverTimestamp(),
  //     lang: i18n.language === "pl-PL" ? "PL" : "EN",
  //   };

  //   try {
  //     const docRef = await addDoc(tarotsRef, tarot_payload);

  //     localStorage.removeItem("apiResponseTarot");
  //     localStorage.setItem("tarotDocId", docRef.id);
  //     localStorage.setItem("paymentStatusTarotCheckNeeded", "true");
  //     return docRef.id;
  //   } catch (error) {
  //     throw new Error("Failed to add new tarot"); // Throw an error to be caught by the caller
  //   }
  // };

  const handleSubmitTarot = async () => {
    if (firestoreUser) {
      setCurrentStep((prev) => prev + 1);
      window.scrollTo(0, 0);

      const data = {
        user_form_data: tarot,
        lang: i18n.language === "pl-PL" ? "PL" : "EN",
        created_at: serverTimestamp(),
        user_id: currentUser?.uid ?? null, // using null for no user
      };
      const currentTime = moment().format("DD.MM.YYYY, HH:mm");

      await updateUser(firestoreUser.id, { lastFreeTarot: currentTime });

      checkFreeTarot(data.user_form_data, data.lang, data.user_id);
    } else {
      setCurrentStep((prev) => prev + 1);
      window.scrollTo(0, 0);

      const data = {
        user_form_data: tarot,
        lang: i18n.language === "pl-PL" ? "PL" : "EN",
        created_at: serverTimestamp(),
        user_id: currentUser?.uid ?? null, // using null for no user
      };

      checkFreeTarot(data.user_form_data, data.lang, data.user_id);
    }
  };

  useEffect(() => {
    if (response) {
      navigate(`/tarot/${response}`);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (error) {
      navigate("/#hero");
      window.location.reload();
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCardsTitle = (step: number) => {
    switch (step) {
      case 1:
        return t("homepage.wrozbaSelector.form.selectcard1");
      case 2:
        return t("homepage.wrozbaSelector.form.selectcard2");
      case 3:
        return t("homepage.wrozbaSelector.form.selectcard3");
      case 5:
        return t("homepage.wrozbaSelector.form.selectcard5");
      default:
        return "error";
    }
  };

  const renderFormTitle = (step: number): string => {
    switch (step) {
      case 1:
        return t("tarot.title");

      case 2:
        return t("homepage.wrozbaSelector.form.stack");
      case 3:
        const title3 = renderCardsTitle(Number(tarot.cardsCount));
        return title3;
      default:
        return "Error";
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <TarotFormStep1
            tarot={tarot}
            setTarot={setTarot}
            setCurrentStep={setCurrentStep}
            currentUser={currentUser}
            firestoreUser={firestoreUser}
            openLoginModal={openLoginModal}
          />
        );
      case 2:
        return <TarotStackSelector setCurrentStep={setCurrentStep} />;
      case 3:
        return (
          <TarotCardsSelector
            tarot={tarot}
            selectedCardIndexes={selectedCardIndexes}
            setSelectedCardIndexes={setSelectedCardIndexes}
          />
        );
      case 4:
        return <TarotFreeLoading />;
      default:
        return null;
    }
  };

  const targetDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      selectedCardIndexes.length === Number(tarot.cardsCount) &&
      targetDivRef.current
    ) {
      const divPosition =
        targetDivRef.current.getBoundingClientRect().top + window.pageYOffset;
      const offset = 100; // Distance from bottom of the viewport
      const viewportHeight = window.innerHeight;
      const scrollToPosition = divPosition - viewportHeight + offset;

      window.scrollTo({ top: scrollToPosition, behavior: "smooth" });
    }
  }, [selectedCardIndexes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TarotFormContainer>
      {currentStep !== 4 && (
        <TarotTitle>
          <H2>{renderFormTitle(currentStep)}</H2>
          {/* {currentStep === 1 && (
            <TarotTitleText onClick={() => navigate("/#selector")}>
              <P style={{ fontSize: 16 }}>{t("homepage.hero.moreFortunes")}</P>
            </TarotTitleText>
          )} */}
        </TarotTitle>
      )}

      {renderStepContent()}

      {currentStep !== 1 && currentStep !== 4 && (
        <div
          style={{ marginTop: 30, display: "flex", gap: 20 }}
          ref={targetDivRef}
        >
          <EuiButtonEmpty onClick={goToPreviousStep} style={{ color: "white" }}>
            {currentStep === 1
              ? t("fortuneModal.buttons.close")
              : t("fortuneModal.buttons.back")}
          </EuiButtonEmpty>

          {selectedCardIndexes.length === Number(tarot.cardsCount) && (
            <Button type="submit" form="form" onClick={handleSubmitTarot}>
              {t("fortuneModal.buttons.continue")}
            </Button>
          )}
        </div>
      )}
    </TarotFormContainer>
  );
};

export default TarotForm;
