import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AppContainer } from "./App.style";
import Homepage from "./features/home/Homepage";

import useAuth from "../config/useAuth";
import Navbar from "./features/navbar/Navbar";
import LoginModal from "./features/session/login/LoginModal";
import { ParticleComponent } from "./features/ParticlesComponent";
import WrozbaResult from "./features/wrozba/result/WrozbaResult";
import "@elastic/eui/dist/eui_theme_dark.css";
import { EuiProvider } from "@elastic/eui";
import Loading from "./features/session/Loading";
import Footer from "./features/footer/Footer";
import { I18nextProvider, useTranslation } from "react-i18next";
import WrozbaList from "./features/wrozba/list/WrozbaList";
import WrozbaDetailed from "./features/wrozba/detailed/WrozbaDetailed";
import heroImage from "./assets/hero-kula-2.png";

import SettingsModal from "./features/settings/SettingsModal";
import AdminPanel from "./features/admin/AdminPanel";
import ContactFormModal from "./features/contact/ContactFormModal";
import AdminPassword from "./features/admin/AdminPassword";

import LanguageFirstSelector from "./features/settings/languageSelector/LanguageFirstSelector";
import PrivacyPolicy from "./features/legal/PrivacyPolicy";
import ScrollToTop from "./features/session/ScrollToTop";
import Terms from "./features/legal/Terms";
import TarotDetailed from "./features/tarot/detailed/TarotDetailed";
import TarotResult from "./features/tarot/result/TarotResult";
import useCheckService from "./features/session/api/useCheckService";
import ServiceWorkScreen from "./features/session/service/ServiceWorkScreen";
import DreamDetailed from "./features/dreams/detailed/DreamDetailed";
import DreamResult from "./features/dreams/result/DreamResult";

function App() {
  const { i18n } = useTranslation();
  const { currentUser, firestoreUser, loading } = useAuth();

  const isAdmin = localStorage.getItem("serviceWorkPass");

  const { service } = useCheckService();
  const isServiceWork = service?.running === false && isAdmin !== "mozesz";

  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const openLoginModal = () => setIsLoginModalOpen(true);
  const closeLoginModal = () => setIsLoginModalOpen(false);

  useEffect(() => {
    if (currentUser && !isServiceWork) {
      window.scrollTo(0, 0);
      setIsLoginModalOpen(false);
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoginModalVisible = isLoginModalOpen && !currentUser;

  const [isSettingsModalOpen, setIsSettingsModalOpen] =
    useState<boolean>(false);
  const openSettingsModal = () => setIsSettingsModalOpen(true);
  const closeSettingsModal = () => setIsSettingsModalOpen(false);

  const isSettingsModalVisible =
    isSettingsModalOpen && currentUser && firestoreUser;

  const primaryOverrides = {
    colors: {
      DARK: {
        primary: "#3f286f", // Your primary color for dark mode
      },
    },
  };

  const [realLoading, setRealLoading] = useState(true);

  useEffect(() => {
    if (!loading && isKulaLoaded) {
      setTimeout(() => {
        setRealLoading(false);
      }, 1000);
    }
  });

  const [isKulaLoaded, setIsKulaLoaded] = useState(false);

  useEffect(() => {
    const loadImage = (src: string) => {
      const image = new Image();
      image.onload = () => setIsKulaLoaded(true);
      image.src = src;
    };

    loadImage(heroImage);
  }, []);

  const [isContactModalVisible, setIsContactModalVisible] =
    useState<boolean>(false);
  const openContactModal = () => setIsContactModalVisible(true);
  const closeContactModal = () => setIsContactModalVisible(false);

  let contactModal;
  if (isContactModalVisible) {
    contactModal = (
      <ContactFormModal
        closeModal={closeContactModal}
        currentUser={currentUser}
      />
    );
  }

  const hasAdminPass = localStorage.getItem("adminPass") === "lecimy";

  const [languageSelected, setLanguageSelected] = useState(true);

  useEffect(() => {
    const currentLanguage = localStorage.getItem("preferredLanguage");
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
      setLanguageSelected(true);
    } else {
      // Sprawdź preferencje językowe przeglądarki
      const browserLanguage = navigator.language;
      if (browserLanguage.includes("pl")) {
        i18n.changeLanguage("pl-PL");
        localStorage.setItem("preferredLanguage", "pl-PL");
        setLanguageSelected(true);
      } else {
        setLanguageSelected(false);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLanguageChange = (language: "pl-PL" | "en-GB") => {
    localStorage.setItem("preferredLanguage", language);
    i18n.changeLanguage(language);
    setLanguageSelected(true);
  };

  //service info

  if (realLoading) {
    return <Loading />;
  }

  return (
    <I18nextProvider i18n={i18n}>
      {/* lang selector */}
      {!languageSelected && (
        <LanguageFirstSelector handleLanguageChange={handleLanguageChange} />
      )}

      <EuiProvider colorMode="dark" modify={primaryOverrides}>
        {contactModal}
        {isServiceWork ? (
          <ServiceWorkScreen service={service || ""} />
        ) : (
          <BrowserRouter>
            <ScrollToTop />
            {isLoginModalVisible && <LoginModal onClose={closeLoginModal} />}

            {/* settings modal */}
            {isSettingsModalVisible && (
              <SettingsModal
                closeModal={closeSettingsModal}
                currentUser={currentUser}
                firestoreUser={firestoreUser}
              />
            )}
            <AppContainer>
              <ParticleComponent />
              <Navbar
                openLoginModal={openLoginModal}
                openContactModal={openContactModal}
                openSettingsModal={openSettingsModal}
              />
              <Routes>
                <Route path="*" element={<Navigate replace to="/" />} />
                <Route
                  path="/"
                  element={
                    <Homepage
                      currentUser={currentUser}
                      openContactModal={openContactModal}
                      openLoginModal={openLoginModal}
                      firestoreUser={firestoreUser}
                    />
                  }
                />
                {/* wrozba */}
                <Route
                  path="/fortune/check/:docId"
                  element={<WrozbaResult currentUser={currentUser} />}
                />
                <Route
                  path="/fortune/:docId"
                  element={<WrozbaDetailed currentUser={currentUser} />}
                />
                {/* tarot */}
                <Route
                  path="/tarot/:docId"
                  element={<TarotDetailed currentUser={currentUser} />}
                />
                <Route
                  path="/tarot/check/:checkout_session_id"
                  element={<TarotResult />}
                />
                {/* admin */}
                <Route path="/admin-haslo" element={<AdminPassword />} />
                {hasAdminPass && (
                  <Route path="/admin-panel" element={<AdminPanel />} />
                )}
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<Terms />} />
                {currentUser && (
                  <Route
                    path="/fortunes"
                    element={<WrozbaList currentUser={currentUser} />}
                  />
                )}
                <Route
                  path="/dream/:docId"
                  element={<DreamDetailed currentUser={currentUser} />}
                />
                <Route
                  path="/dream/check/:checkout_session_id"
                  element={<DreamResult />}
                />
              </Routes>
              <Footer />
            </AppContainer>
          </BrowserRouter>
        )}
      </EuiProvider>
    </I18nextProvider>
  );
}

export default App;
