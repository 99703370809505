import React, { useState } from "react";
import {
  CheckboxContainer,
  CheckboxLabel,
  HiddenCheckbox,
  StyledCheckbox,
  WrozbaInputTitle,
} from "../wrozba/Wrozba.style";
import {
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiTextArea,
} from "@elastic/eui";
import { H2, Button, P, H3 } from "../../App.style";
import {
  AfterModalMessageContainer,
  CustomHelpModal,
  HelpForm,
  HelpTitle,
} from "./ContactModal";
import { User } from "firebase/auth";

import { useTranslation } from "react-i18next";
import { ContactRequest, useAddContactDocument } from "./useAddContactDocument";

interface ContactFormModalProps {
  closeModal: () => void;
  currentUser: User | null;
}

const ContactFormModal: React.FC<ContactFormModalProps> = ({
  closeModal,
  currentUser,
}) => {
  const { t, i18n } = useTranslation();
  const { addContactDocument, isLoading, error, success } =
    useAddContactDocument();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    await addContactDocument(contactForm);
  };

  const [contactForm, setContactForm] = useState<ContactRequest>({
    email: currentUser?.email ? currentUser.email : "",
    title: "",
    message: "",
    error: false,
    lang: i18n.language === "pl-PL" ? "PL" : "EN",
  });

  const [isFieldValid, setIsFieldValid] = useState({
    email: true,
    title: true,
    message: true,
  });

  const validateForm = () => {
    const newValidationState = {
      email: !!contactForm.email,
      title: !!contactForm.title,
      message: !!contactForm.message,
    };

    setIsFieldValid(newValidationState);

    return Object.values(newValidationState).every(Boolean);
  };

  return (
    <CustomHelpModal onClose={closeModal} initialFocus="[name=popswitch]">
      {success ? (
        <AfterModalMessageContainer>
          <H2>{t("helpModal.success.line1")}</H2>
          <H3>{t("helpModal.success.line2")}</H3>
          <H3>{t("helpModal.success.line3")}</H3>
        </AfterModalMessageContainer>
      ) : error ? (
        <AfterModalMessageContainer>
          <H2>{t("helpModal.error.line1")}</H2>
          <H3>{t("helpModal.error.line2")}</H3>
        </AfterModalMessageContainer>
      ) : (
        <>
          <EuiModalHeader
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HelpTitle>
              <H2>{t("helpModal.form.title")}</H2>
              <P style={{ marginTop: 20, marginBottom: 10 }}>
                {t("helpModal.form.subLine1")}
              </P>
              <P>{t("helpModal.form.subLine2")}</P>
            </HelpTitle>
          </EuiModalHeader>

          <EuiModalBody
            style={{
              display: "grid",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HelpForm id="form" onSubmit={handleSubmit}>
              <div
                style={{
                  marginBottom: 20,
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  gap: 8,
                  cursor: "pointer",
                }}
                onClick={() =>
                  setContactForm((prevState) => ({
                    ...prevState,
                    error: !prevState.error,
                  }))
                }
              >
                <CheckboxContainer>
                  <HiddenCheckbox checked={contactForm.error} readOnly />
                  <StyledCheckbox checked={contactForm.error} />
                  <CheckboxLabel />
                </CheckboxContainer>

                <WrozbaInputTitle>
                  {t("helpModal.form.problem")}
                </WrozbaInputTitle>
              </div>

              <EuiFormRow
                label={<WrozbaInputTitle>Email</WrozbaInputTitle>}
                labelType="legend"
                isInvalid={!isFieldValid.email}
                error={t("helpModal.form.fieldRequired")}
              >
                <EuiFieldText
                  disabled={isLoading}
                  maxLength={30}
                  style={{ background: "#0D0D0D" }}
                  value={contactForm.email}
                  onChange={(e) => {
                    setContactForm({ ...contactForm, email: e.target.value });
                    setIsFieldValid({
                      ...isFieldValid,
                      email: !!e.target.value,
                    });
                  }}
                  compressed
                />
              </EuiFormRow>

              <EuiFormRow
                label={
                  <WrozbaInputTitle>
                    {t("helpModal.form.messageTitle")}
                  </WrozbaInputTitle>
                }
                labelType="legend"
                isInvalid={!isFieldValid.title}
                error={t("helpModal.form.fieldRequired")}
              >
                <EuiFieldText
                  disabled={isLoading}
                  maxLength={50}
                  style={{ background: "#0D0D0D" }}
                  value={contactForm.title}
                  onChange={(e) => {
                    setContactForm({ ...contactForm, title: e.target.value });
                    setIsFieldValid({
                      ...isFieldValid,
                      title: !!e.target.value,
                    });
                  }}
                  compressed
                />
              </EuiFormRow>

              <EuiFormRow
                label={
                  <WrozbaInputTitle>
                    {t("helpModal.form.message")}
                  </WrozbaInputTitle>
                }
                labelType="legend"
                isInvalid={!isFieldValid.message}
                error={t("helpModal.form.fieldRequired")}
              >
                <EuiTextArea
                  disabled={isLoading}
                  maxLength={400}
                  style={{ background: "#0D0D0D", minHeight: 50 }}
                  value={contactForm.message}
                  onChange={(e) => {
                    setContactForm({ ...contactForm, message: e.target.value });
                    setIsFieldValid({
                      ...isFieldValid,
                      message: !!e.target.value,
                    });
                  }}
                  compressed
                />
              </EuiFormRow>
            </HelpForm>
          </EuiModalBody>
        </>
      )}

      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal} style={{ color: "white" }}>
          {t("helpModal.form.buttons.close")}
        </EuiButtonEmpty>

        {!error && !success && (
          <Button type="submit" form="form" disabled={isLoading}>
            {t("helpModal.form.buttons.send")}
          </Button>
        )}
      </EuiModalFooter>
    </CustomHelpModal>
  );
};

export default ContactFormModal;
