import { EuiFormRow, EuiFieldText, EuiFieldPassword } from "@elastic/eui";
import React, { useState } from "react";
import { Button, H5 } from "../../../App.style";
import { WrozbaInputTitle } from "../../wrozba/Wrozba.style";
import { registerUser } from "../api/sessionApi";
import { LoginFormContainer, RegisterContainer } from "./LoginModal.style";
import { useTranslation } from "react-i18next";

interface RegisterFormProps {
  onClose: () => void;
  handleLoginClick: () => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({
  onClose,
  handleLoginClick,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const [emailError, setEmailError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();

  const isEmailValid = () => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);

  const isPasswordStrong = () => password.length >= 8; // Możesz dodać więcej warunków

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!isEmailValid()) {
      setEmailError(t("loginModal.register.error.wrongEmail"));
    } else if (!isPasswordStrong()) {
      setPasswordError(t("loginModal.register.error.tooShort"));
    } else if (password !== rePassword) {
      setPasswordError(t("loginModal.register.error.same"));
    } else {
      try {
        await registerUser(email, password);
        onClose();
      } catch (error: any) {
        if (error.code === "auth/email-already-in-use") {
          setEmailError(t("loginModal.register.error.alreadyInUse"));
        } else {
          setEmailError(t("loginModal.register.error.wtf"));
        }
      }
    }
  };

  return (
    <LoginFormContainer onSubmit={handleSubmit}>
      <EuiFormRow
        label={<WrozbaInputTitle>Email</WrozbaInputTitle>}
        labelType="legend"
        isInvalid={emailError ? true : false}
        error={emailError}
      >
        <EuiFieldText
          isInvalid={emailError ? true : false}
          maxLength={40}
          style={{ background: "#0D0D0D" }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError(undefined);
          }}
          compressed
        />
      </EuiFormRow>

      <EuiFormRow
        style={{ marginTop: 0 }}
        label={
          <WrozbaInputTitle>
            {t("loginModal.register.password")}
          </WrozbaInputTitle>
        }
        labelType="legend"
        isInvalid={passwordError ? true : false}
        error={passwordError}
      >
        <EuiFieldPassword
          isInvalid={passwordError ? true : false}
          type="dual"
          maxLength={40}
          style={{
            background: "#0D0D0D",
            border: "none",
            outline: "none",
          }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError(undefined);
          }}
          compressed
        />
      </EuiFormRow>

      <EuiFormRow
        style={{ marginTop: 0, marginBottom: 20 }}
        label={
          <WrozbaInputTitle>
            {t("loginModal.register.rePassword")}
          </WrozbaInputTitle>
        }
        labelType="legend"
        isInvalid={passwordError ? true : false}
      >
        <EuiFieldPassword
          isInvalid={passwordError ? true : false}
          type="dual"
          maxLength={40}
          style={{
            background: "#0D0D0D",
            border: "none",
            outline: "none",
          }}
          value={rePassword}
          onChange={(e) => {
            setRePassword(e.target.value);
            setPasswordError(undefined);
          }}
          compressed
        />
      </EuiFormRow>

      <Button type="submit">{t("loginModal.register.signUp")}</Button>

      <RegisterContainer>
        <H5>{t("loginModal.register.haveAnAccount")}</H5>{" "}
        <H5
          className="register_link"
          style={{ opacity: 0.6 }}
          onClick={handleLoginClick}
        >
          {t("loginModal.login.login")}
        </H5>
      </RegisterContainer>
    </LoginFormContainer>
  );
};

export default RegisterForm;
