import React from "react";
import {
  SocialsContainer,
  SocialsContentContainer,
  SocialIcon,
} from "./Socials.style";
// import tiktokIcon from "../../../../assets/socials/tiktok.png";
// import xIcon from "../../../../assets/socials/x.png";
import instaIcon from "../../../../assets/socials/insta.png";
import { H2 } from "../../../../App.style";
import { useInView } from "react-intersection-observer";
import { TitleContainer } from "../../Homepage.style";
import { useTranslation } from "react-i18next";

const Socials = () => {
  const { t } = useTranslation();
  const titleInView = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const iconsInView = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  return (
    <SocialsContainer>
      <TitleContainer ref={titleInView.ref} $isVisible={titleInView.inView}>
        <H2>{t("homepage.socials.title")}</H2>
      </TitleContainer>
      <SocialsContentContainer
        ref={iconsInView.ref}
        $isVisible={iconsInView.inView}
      >
        <a
          href="https://www.instagram.com/mysticer.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SocialIcon src={instaIcon} alt={"insta-icon"} />
        </a>

        {/* <a
          href="https://www.x.com/@MysticerAI"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SocialIcon src={xIcon} alt={"x-icon"} />
        </a> */}

        {/* <a
          href="https://www.tiktok.com/@mysticer.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SocialIcon src={tiktokIcon} alt={"tiktok-icon"} />
        </a> */}
      </SocialsContentContainer>
    </SocialsContainer>
  );
};

export default Socials;
