import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";

import { db } from "../../../../config/firebase";
import { TarotRequest } from "../api/useCheckFreeTarot";

function useTarotById(documentId: string, onNoDocument: () => void) {
  const [tarot, setTarot] = useState<TarotRequest | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    async function fetchTarot() {
      setLoading(true);
      setError(false);

      try {
        const fortuneRef = doc(db, "Tarots", documentId);
        const docSnapshot = await getDoc(fortuneRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data() as Omit<TarotRequest, "id">;

          setTarot(data);
        } else {
          setTarot(null);
          onNoDocument();
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    if (documentId) {
      fetchTarot();
    }
  }, [documentId]);

  return { tarot, loading, error };
}

export default useTarotById;
