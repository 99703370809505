import styled, { keyframes } from "styled-components";
import { FONT_WHITE, H4 } from "../../../App.style";

export const TarotDetailedContainer = styled.div`
  padding-top: 80px;
  width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 80px 15px;

  @media (min-width: 350px) {
    padding: 80px 20px;
  }

  @media (min-width: 768px) {
    padding: 80px 30px;
  }
`;

export const TarotDetailedTitleContainer = styled.div`
  text-align: center;
  max-width: 800px;

  margin-top: 20px;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    margin-top: 40px;
    margin-bottom: 50px;
    max-width: 500px;
  }

  font-family: "Roboto", sans-serif;
  font-size: clamp(25px, 4vw + 1rem, 38px);
  font-style: normal;
  font-weight: 500;

  letter-spacing: 2px;

  color: ${FONT_WHITE};
`;

export const TarotDetailedCardsContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;
  gap: 10px;

  @media (min-width: 400px) {
    gap: 20px;
  }
`;

//test
export const TarotCardContainer = styled.div`
  width: 150px;
  height: 300px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  align-items: center;
  cursor: pointer;
  z-index: 2;

  position: relative;

  &:hover {
    transform: scale(1.02);
  }

  @media (min-width: 768px) {
    width: 200px;
    height: 380px;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const TarotCardNameContainer = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;

  height: 50px;
  bottom: 0px;
`;

//test
export const StyledCard = styled.div<{ $isFlipped?: boolean }>`
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  cursor: pointer;

  border-radius: 5px;
  transform: ${(props) => props.$isFlipped && "rotateY(-180deg)"};
`;

export const StyledFigure = styled.figure`
  margin: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 5px;
`;

export const StyledImage = styled.img`
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px;
`;

export const StyledBack = styled(StyledFigure)`
  transform: rotateY(0deg);
`;

export const StyledFront = styled(StyledFigure)`
  transform: rotateY(180deg);
`;

export const TarotDetailedContentContainer = styled.div`
  background: black;
  border-radius: 20px;
  opacity: 0.8;
  text-align: center;
  position: relative;
  z-index: 1;

  padding: 20px 0px;
  margin-bottom: 20px;

  @media (min-width: 300px) {
    padding: 20px 0px;
    max-width: 550px;
  }

  @media (min-width: 768px) {
    padding: 30px 0px;
    max-width: 700px;
  }

  @media (min-width: 1000px) {
    padding: 40px 30px;
    max-width: 1100px;
  }

  p {
    font-size: 16px;
    letter-spacing: 1.71px;

    text-shadow: 0px 8px 24px #000;

    @media (min-width: 768px) {
      font-size: 19px;
    }
  }
`;

//loading text animation lol
const fadeIn = keyframes`
  from {
    opacity: 0;
    filter: blur(4px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
`;

export const TarotDetaieldLoadingWord = styled.span`
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
  animation: ${fadeIn} 1.5s forwards;
  animation-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  margin-right: 10px;
`;

export const TarotDetailedSentenceContainer = styled(H4)`
  text-align: center;
  transform: scale(0.94);
  animation: scale 1s forwards cubic-bezier(0.5, 1, 0.89, 1);
  letter-spacing: 0.2px;
`;

export const TarotDetailedButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-top: 20px;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`;
