import React, { useState } from "react";
import { TarotStacksContainer, TarotStackImage } from "../Tarot.style";
import stack_image from "../../../assets/card_stack.png";

interface TarotStackSelectorProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

const TarotStackSelector: React.FC<TarotStackSelectorProps> = ({
  setCurrentStep,
}) => {
  const [selectedStackIndex, setSelectedStackIndex] = useState<number>();

  const handleStackClick = (
    index: number,
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    setSelectedStackIndex(index);
    const card = event.currentTarget as HTMLElement;
    const container = card.parentNode as HTMLElement;

    const cardRect = card.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    // Calculate the distance to move to the top left corner of the container
    const translateX = -cardRect.left + containerRect.left + 50;
    const translateY = -cardRect.top + containerRect.top + 20;

    // Apply a dynamic style to the card to move it to the top left corner
    card.style.transform = `translate(${translateX}px, ${translateY}px)`;
    card.style.transition = "transform 0.5s ease";
    card.style.boxShadow = `none`;

    // Wait for the animation to complete before changing the step
    setTimeout(() => {
      setCurrentStep(3);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500); // Adjust this duration to match your animation duration
  };
  return (
    <TarotStacksContainer>
      {new Array(8).fill(null).map((_, index) => (
        <TarotStackImage
          key={index}
          src={stack_image}
          alt="tarot stack"
          onClick={(e) => !selectedStackIndex && handleStackClick(index, e)}
          $isNotSelected={
            selectedStackIndex !== undefined && selectedStackIndex !== index
          }
        />
      ))}
    </TarotStacksContainer>
  );
};

export default TarotStackSelector;
