import React from "react";
import { styled } from "styled-components";
import { COLOUR_VIOLET, P } from "../../../App.style";
import { useTranslation } from "react-i18next";

export const TarotFormOptionText = styled.span`
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  user-select: none;
  color: white;
`;

export const TarotFormOptionContainer = styled.div<{
  $isSelected: boolean;
  $width?: number;
  $disabled?: boolean;
}>`
  width: ${({ $width }) => ($width ? `${$width}%` : "100%")};
  /* height: 120px; */
  box-shadow: 0px 2px 4px 0px #00000040;
  min-width: 112px;

  border: ${({ $isSelected }) =>
    $isSelected ? `2px solid ${COLOUR_VIOLET}` : "2px solid #262626"};

  background: #0d0d0d;
  margin-bottom: 16px;
  position: relative;

  opacity: ${({ $disabled }) => $disabled && "0.6"};

  padding: 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  text-align: center;

  box-sizing: border-box;
  cursor: pointer;

  @media (min-width: 768px) {
    &:hover {
      transform: ${({ $disabled }) => ($disabled ? "none" : "scale(1.01)")};
      border: ${({ $disabled }) => !$disabled && `2px solid ${COLOUR_VIOLET}`};
    }
  }
`;

interface TarotFormOptionProps {
  isSelected: boolean;
  onClick: () => void;
  text: string;
  imgSrc?: string;
  width?: number;
  description?: string;
  price?: string;
  needsLog?: boolean;
  iconSize?: number;
  disabled?: boolean;
}

const TarotFormOption: React.FC<TarotFormOptionProps> = ({
  isSelected,
  onClick,
  imgSrc,
  text,
  width,
  description,
  price,
  needsLog,
  iconSize,
  disabled,
}) => {
  const { t } = useTranslation();
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    <TarotFormOptionContainer
      $isSelected={isSelected}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role={"button"}
      tabIndex={0}
      aria-pressed={isSelected}
      $width={width}
      $disabled={disabled}
    >
      {imgSrc && (
        <img
          style={{ width: iconSize ? iconSize : 55 }}
          src={imgSrc}
          alt={text}
        />
      )}
      <TarotFormOptionText>{text}</TarotFormOptionText>
      {description && (
        <TarotFormOptionText style={{ fontWeight: 300, fontStyle: "italic" }}>
          {description}
        </TarotFormOptionText>
      )}
      {price && (
        <P style={{ position: "absolute", right: 10, top: 10 }}>{price}</P>
      )}

      {needsLog && (
        <P style={{ marginTop: 10 }}>
          {t("homepage.wrozbaSelector.form.needsLogin")}
        </P>
      )}
    </TarotFormOptionContainer>
  );
};

export default TarotFormOption;
