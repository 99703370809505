import { EuiDatePicker, EuiSelect } from "@elastic/eui";
import React, { useState } from "react";
import { getAdminTarots } from "../api/adminApi";
import { Button, H2 } from "../../../App.style";
import { Moment } from "moment";
import { FortunesListContainer } from "../AdminPanel.style";
import { TarotRequest } from "../../tarot/api/useCheckFreeTarot";
import TarotsTable from "./TarotsTable";

const TarotsList = () => {
  const [tarots, setTarots] = useState<TarotRequest[]>([]);

  const [selectedStatus, setSelectedStatus] = useState<
    "pending" | "success" | "error"
  >();
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);

  const [cardsCount, setCardsCount] = useState<string>();

  const handleButtonClick = async () => {
    try {
      const date = selectedDate ? selectedDate.toDate() : undefined;

      const fetchedTarots = await getAdminTarots(
        selectedStatus,
        date,
        cardsCount
      );
      setTarots(fetchedTarots);
    } catch (error) {
      console.log(error);
      alert("cos nie tak mordo");
    }
  };

  const optionsTexts = ["All", "pending", "success", "error"];
  const selectOptions = optionsTexts.map((text) => {
    return { value: text === "All" ? "" : text, text };
  });

  const cardsCountSelectOptions = ["All", "1", "3", "5"].map((text) => {
    return { value: text === "All" ? "" : text, text };
  });

  return (
    <FortunesListContainer>
      <div
        style={{
          display: "flex",
          gap: 15,
          marginBottom: 20,
        }}
      >
        <H2>Status:</H2>

        <div style={{ width: 120 }}>
          <EuiSelect
            value={selectedStatus}
            options={selectOptions}
            onChange={(e) =>
              setSelectedStatus(
                e.target.value !== ""
                  ? (e.target.value as "pending" | "success" | "error")
                  : undefined
              )
            }
          />
        </div>

        <H2>Data:</H2>

        <div style={{ width: 140 }}>
          <EuiDatePicker selected={selectedDate} onChange={setSelectedDate} />
        </div>

        <H2>Ilosc</H2>

        <div style={{ width: 80 }}>
          <EuiSelect
            options={cardsCountSelectOptions}
            value={cardsCount}
            onChange={(e) => setCardsCount(e.target.value)}
          />
        </div>

        <div style={{ marginLeft: 30 }}>
          <Button onClick={handleButtonClick}>Poka</Button>
        </div>
      </div>

      <TarotsTable tarotsList={tarots} />
    </FortunesListContainer>
  );
};

export default TarotsList;
