import React from "react";
import { LegalContainer, Paragraph, Title } from "./Legal.style";

const Terms = () => {
  return (
    <LegalContainer>
      <Title>REGULAMIN STRONY INTERNETOWEJ MYSTICER z 29.12.2023</Title>

      <Paragraph style={{ fontSize: 26, fontWeight: 500 }}>
        I. Definicje
      </Paragraph>
      <Paragraph>Użyte w Regulaminie pojęcia oznaczają:</Paragraph>
      <Paragraph>
        1.Klient– osoba fizyczna, osoba prawna lub jednostka organizacyjna nie
        będącą osobą prawną, której przepisy szczególne przyznają zdolność
        prawną, która dokonuje Zamówienia w ramach Strony internetowej
      </Paragraph>
      <Paragraph terms>
        2.Kodeks Cywilny– ustawa z dnia 23 kwietnia 1964 r. (Dz. U. Nr 16, poz.
        93 ze zm.);
      </Paragraph>
      <Paragraph terms>
        3.Regulamin– niniejszy Regulamin świadczenia usług drogą elektroniczną w
        ramach strony internetowej Mysticer
      </Paragraph>
      <Paragraph terms>
        4.Strona internetowa – serwis internetowy dostępny pod mysticer.com za
        pośrednictwem którego Klient może w szczególności składać Zamówienia;
      </Paragraph>
      <Paragraph terms>
        5.Towar– produkty prezentowane w Sklepie Internetowym;
      </Paragraph>
      <Paragraph terms>
        6.Umowa sprzedaży– umowa sprzedaży Towarów w rozumieniu Kodeksu
        Cywilnego, zawarta pomiędzy [podmiotem prowadzącym stronę internetową] a
        Klientem, zawierana z wykorzystaniem serwisu internetowego.
      </Paragraph>
      <Paragraph terms>
        7.Ustawa o szczególnych warunkach sprzedaży konsumenckiej– ustawa z dnia
        27 lipca 2002 r. o szczególnych warunkach sprzedaży konsumenckiej oraz o
        zmianie Kodeksu Cywilnego (Dz. U. Nr 141, poz. 1176 ze zm.);
      </Paragraph>
      <Paragraph terms>
        8.Ustawa o świadczeniu usług drogą elektroniczną– ustawa z dnia 18 lipca
        2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. Nr 144, poz.
        1204 ze zm.);
      </Paragraph>
      <Paragraph terms>
        9.Zamówienie– oświadczenie woli Klienta, zmierzające bezpośrednio do
        zawarcia Umowy sprzedaży, określające w szczególności rodzaj i liczbę
        Towaru.
      </Paragraph>

      <Paragraph style={{ fontSize: 26, fontWeight: 500 }}>
        II.Postanowienia ogólne
      </Paragraph>
      <Paragraph>
        2.1. Niniejszy Regulamin określa zasady korzystania ze strony
        internetowej dostępnej pod mysticer.com
      </Paragraph>
      <Paragraph>
        2.2. Niniejszy Regulamin jest regulaminem, o którym mowa w art. 8 Ustawy
        o świadczeniu usług drogą elektroniczną.
      </Paragraph>
      <Paragraph>
        2.3. Sklep internetowy, działający pod mysticer.com, prowadzony jest
        przez Jakub Kracla
      </Paragraph>
      <Paragraph>
        2.4. Niniejszy Regulamin określa w szczególności [w zależności od
        dostępnych możliwości]:
      </Paragraph>
      <Paragraph>
        a.) za pośrednictwem strony usługodawca świadczy usługi płatne.
        Płatności uiszczane są poprzez stronę Stripe
      </Paragraph>
      <Paragraph>
        b.) warunki i zasady dokonywania elektronicznego zakupu dostępnego w
        ramach strony internetowej
      </Paragraph>
      <Paragraph>
        c.) warunki i zasady składania drogą elektroniczną Zamówień w ramach
        strony internetowej
      </Paragraph>
      <Paragraph>
        d.) zasady zawierania Umów sprzedaży z wykorzystaniem usług świadczonych
        w ramach strony internetowej
      </Paragraph>
      <Paragraph>
        2.5. Korzystanie ze strony internetowej jest możliwe pod warunkiem
        spełnienia przez system teleinformatyczny, z którego korzysta Klient
        następujących minimalnych wymagań technicznych:
      </Paragraph>
      <Paragraph>a.) przeglądarka internetowa</Paragraph>
      <Paragraph>
        2.6. W celu korzystania ze strony internetowej Klient powinien we
        własnym zakresie uzyskać dostęp do stanowiska komputerowego lub
        urządzenia końcowego, z dostępem do Internetu.
      </Paragraph>
      <Paragraph>
        2.7. Klienci mogą uzyskać dostęp do niniejszego Regulaminu w każdym
        czasie za pośrednictwem odsyłacza zamieszczonego na stronie głównej
        serwisu mysteric.com oraz pobrać go i sporządzić jego wydruk.
      </Paragraph>
      <Paragraph>
        2.8. Informacje o Towarach podane na stronie w szczególności ich opis i
        cena, stanowią zaproszenie do zawarcia umowy, w rozumieniu art. 71
        Kodeksu Cywilnego.
      </Paragraph>

      <Paragraph style={{ fontSize: 26, fontWeight: 500 }}>
        III. Zasady korzystania ze Sklepu Internetowego
      </Paragraph>
      <Paragraph>
        3.1. Warunkiem rozpoczęcia korzystania z usługi strony internetowej jest
        zaakceptowanie jej regulaminu oraz podania informacji: wiek, imię, płeć,
        zawód w formularzu Wróżby, następnie podanie tematu wróżby, czasu wróżby
        oraz nastroju. Przy dokonywaniu płatności podane adresu e mail jest
        obowiązkowe.
      </Paragraph>
      <Paragraph>3.2. Klient zobowiązany jest w szczególności do:</Paragraph>
      <Paragraph>
        a.) korzystania ze Sklepu internetowego w sposób nie zakłócający jego
        funkcjonowania, w szczególności poprzez użycie określonego
        oprogramowania lub urządzeń,
      </Paragraph>
      <Paragraph>
        b.) korzystania z wszelkich treści zamieszczonych w ramach Sklepu
        internetowego jedynie w zakresie własnego użytku osobistego,
      </Paragraph>

      <Paragraph>
        c.) korzystania ze Strony internetowej w sposób zgodny z przepisami
        obowiązującego na terytorium Rzeczypospolitej Polskiej prawa,
        postanowieniami Regulaminu, a także z ogólnymi zasadami korzystania z
        sieci Internet.
      </Paragraph>

      <Paragraph style={{ fontSize: 26, fontWeight: 500 }}>
        IV. Procedura zawarcia Umowy sprzedaży
      </Paragraph>
      <Paragraph>
        4.1. W celu zawarcia Umowy sprzedaży za pośrednictwem Sklepu
        internetowego należy wejść na stronę internetową Mysticer uzupełnić
        formularz podejmując kolejne czynności techniczne w oparciu o
        wyświetlane Klientowi komunikaty oraz informacje dostępne na stronie.
      </Paragraph>
      <Paragraph>
        4.2. W trakcie składania Zamówienia – do momentu naciśnięcia przycisku
        „DO PŁATNOŚCI” – Klient ma możliwość modyfikacji wprowadzonych danych
        oraz w zakresie wyboru Towaru. W tym celu należy kierować się
        wyświetlanymi Klientowi komunikatami oraz informacjami dostępnymi na
        stronie.
      </Paragraph>

      <Paragraph>
        4.3. Po podaniu przez Klienta korzystającego ze Strony internetowej
        wszystkich niezbędnych danych, wyświetlone zostanie podsumowanie
        złożonego Zamówienia. Podsumowanie złożonego Zamówienia będzie zawierać
        informacje dotyczące:
      </Paragraph>
      <Paragraph>a.) przedmiotu zamówienia</Paragraph>
      <Paragraph>
        4.4. W celu wysłania Zamówienia konieczne jest dokonanie akceptacji
        treści Regulaminu, podanie danych osobowych oznaczonych jako obowiązkowe
        oraz naciśnięcie przycisku „DO PŁATNOŚCI”
      </Paragraph>
      <Paragraph>
        4.5. Wysłanie przez Klienta Zamówienia stanowi oświadczenie woli
        zawarcia z prowadzącym stronę Umowy sprzedaży, zgodnie z treścią
        Regulaminu.
      </Paragraph>
      <Paragraph>
        4.6. Umowa sprzedaży zawierana jest w języku polskim, o treści zgodnej z
        Regulaminem.
      </Paragraph>
      <Paragraph style={{ fontSize: 26, fontWeight: 500 }}>
        V. Dostawa
      </Paragraph>
      <Paragraph>
        5.1. towar wyświetla się od razu po dokonaniu zakupu
      </Paragraph>

      <Paragraph style={{ fontSize: 26, fontWeight: 500 }}>
        XI. Reklamacje w zakresie świadczenia usług drogą elektroniczną
      </Paragraph>
      <Paragraph>
        6.1. Jakub Kracla podejmuje działania w celu zapewnienia w pełni
        poprawnego działania Strony w takim zakresie, jaki wynika z aktualnej
        wiedzy technicznej i zobowiązuje się usunąć w rozsądnym terminie
        wszelkie nieprawidłowości zgłoszone przez Klientów.
      </Paragraph>
      <Paragraph>
        6.2. Klient zobowiązany jest niezwłocznie powiadomić [podmiot prowadzący
        sklep internetowy] o wszelkich nieprawidłowościach lub przerwach w
        funkcjonowaniu serwisu Sklepu Internetowego.
      </Paragraph>
      <Paragraph>
        6.3. Nieprawidłowości związane z funkcjonowaniem Sklepu Klient może
        zgłaszać mailowo pod adres contact@mysticer.com
      </Paragraph>
      <Paragraph>
        6.4. W reklamacji Klient powinien podać swoje dane udostępnione w
        formularzu , rodzaj i datę wystąpienia nieprawidłowości związanej z
        funkcjonowaniem Strony.
      </Paragraph>
      <Paragraph>
        6.5. Podmiot prowadzący sklep internetowy zobowiązuje się do
        rozpatrzenia każdej reklamacji w terminie do [14] dni, a gdyby to nie
        było możliwe, do poinformowania w tym okresie Klienta, kiedy reklamacja
        zostanie rozpatrzona.
      </Paragraph>
      <Paragraph style={{ fontSize: 26, fontWeight: 500 }}>
        VII. Postanowienia końcowe
      </Paragraph>
      <Paragraph>
        7.1. Rozstrzyganie ewentualnych sporów powstałych pomiędzy podmiotem
        prowadzącym stronę internetową a Klientem, który jest konsumentem w
        rozumieniu art. 221 Kodeksu Cywilnego, zostaje poddane sądom właściwym
        zgodnie z postanowieniami właściwych przepisów Kodeksu postępowania
        cywilnego.
      </Paragraph>

      <Paragraph>
        7.2. Rozstrzyganie ewentualnych sporów powstałych pomiędzy podmiotem
        prowadzącym sklep internetowy a Klientem, który nie jest konsumentem w
        rozumieniu art. 221 Kodeksu Cywilnego Kodeksu Cywilnego, zostaje poddane
        sądowi właściwemu ze względu na siedzibę podmiotu prowadzącego sklep
        internetowy.
      </Paragraph>
      <Paragraph>
        7.3. W sprawach nieuregulowanych w niniejszym Regulaminie mają
        zastosowanie przepisy Kodeksu cywilnego, przepisy Ustawy o świadczeniu
        usług drogą elektroniczną oraz inne właściwe przepisy prawa polskiego.
      </Paragraph>
    </LegalContainer>
  );
};

export default Terms;
