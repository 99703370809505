import React from "react";
import { ButtonSecondary, H3, P } from "../../../../App.style";
import {
  QuestionContainer,
  QuestionTitleContainer,
  AnswerContainer,
  ChevronIcon,
} from "./FAQ.style";
import { useInView } from "react-intersection-observer";
import chevronDown from "./chevron.svg";

export interface FAQQuestion {
  title: string;
  text: string;
  text2?: string;
  button?: {
    text: string;
    onClick: () => void;
  };
}

interface FAQItemProps {
  question: FAQQuestion;
  index: number;
  isOpen: boolean;
  handleToggle: (index: number) => void;
}

const FAQItem: React.FC<FAQItemProps> = ({
  question,
  index,
  isOpen,
  handleToggle,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const handleHereClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (question.button) {
      const { onClick } = question.button;

      onClick();
    }
  };
  return (
    <QuestionContainer
      ref={ref}
      $isVisible={inView}
      key={index}
      onClick={() => handleToggle(index)}
    >
      <div style={{ marginRight: 5 }}>
        <H3>{index + 1}. </H3>
      </div>
      <div>
        <QuestionTitleContainer>
          <H3>{question.title}</H3>
          <ChevronIcon src={chevronDown} alt={"faq-arrow"} $isOpen={isOpen} />
        </QuestionTitleContainer>

        <AnswerContainer $isOpen={isOpen}>
          <P>{question.text}</P>

          {question.button && (
            <ButtonSecondary
              style={{ marginTop: 15, marginLeft: 5 }}
              onClick={handleHereClick}
            >
              {question.button.text}
            </ButtonSecondary>
          )}
        </AnswerContainer>
      </div>
    </QuestionContainer>
  );
};

export default FAQItem;
