import { styled } from "styled-components";
import { H2 } from "../../App.style";

export const AdminPanelContainer = styled.div`
  width: 100%;
  height: 100vh;
  margin-top: 100px;

  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
`;

export const AdminPanelTopBar = styled.div`
  width: 100%;

  display: flex;
  gap: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`;

export const AdminPanelBarButton = styled(H2)<{ selected: boolean }>`
  cursor: pointer;
  text-decoration: ${({ selected }) => selected && "underline"};
  &:hover {
    text-decoration: underline;
  }
`;

export const AdminPanelContent = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 90%;
  height: calc(100% - 200px);
  overflow: auto;

  /* overflow: auto; */
`;

//
export const FortunesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  height: calc(100vh - 250px);
`;
