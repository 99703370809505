// useAuth.ts
import { useState, useEffect } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from "./firebase";
import {
  FirestoreUser,
  getFirestoreUser,
} from "../App/features/session/api/userApi";

const useAuth = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [firestoreUser, setFirestoreUser] = useState<FirestoreUser | null>(
    null
  );
  const [loading, setLoading] = useState(true);

  const fetchUserDataWithRetry = async (uid: string, attempts: number = 5) => {
    for (let i = 0; i < attempts; i++) {
      try {
        const userData = await getFirestoreUser(uid);
        if (userData) {
          return userData; // Successfully fetched user data
        }
      } catch (error) {
        console.error(
          `Attempt ${i + 1}: Failed to fetch firestore user`,
          error
        );
        // Wait for a short period before trying again
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
    throw new Error("User data not found after multiple attempts");
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        // Fetch firestore user data when the auth user state changes and user is not null
        fetchUserDataWithRetry(user.uid)
          .then((userData) => {
            setFirestoreUser(userData);
            setLoading(false);
          })
          .catch((error) => {
            window.location.reload();
            console.error("Something went wrong:", error);
            setLoading(false);
          });
      } else {
        setFirestoreUser(null);
        setLoading(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return { currentUser, firestoreUser, loading };
};

export default useAuth;
