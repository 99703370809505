import React from "react";
import { styled } from "styled-components";

export const LoadingScreen = styled.div`
  width: 100%;
  height: 100svh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = styled.div`
  width: 120px;
  height: 22px;
  border-radius: 20px;
  color: #514b82;
  border: 2px solid;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    margin: 2px;
    inset: 0 100% 0 0;
    border-radius: inherit;
    background: currentColor;
    animation: l6 2s infinite;
  }

  @keyframes l6 {
    100% {
      inset: 0;
    }
  }
`;

const Loading = () => {
  return (
    <LoadingScreen>
      <Loader />
    </LoadingScreen>
  );
};

export default Loading;
