import styled from "styled-components";
import { COLOUR_VIOLET } from "../../../App.style";

export const SelectorContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

export const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

export const UserImage = styled.img`
  width: 30px;

  border-radius: 50%;

  @media (min-width: 768px) {
    width: 35px;
  }
`;

export const OptionsContainer = styled.div<{ $top?: boolean }>`
  position: absolute;
  background: #313131;

  top: ${(props) => (props.$top ? "" : "110%")};
  bottom: ${(props) => (props.$top ? "130%" : "")};

  left: 0px;

  border-radius: 5px;

  z-index: 1;
`;

export const PopoverOption = styled.div<{ selected?: boolean }>`
  padding: 8px 10px;

  background: ${(props) => (props.selected ? COLOUR_VIOLET : "313131")};
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;
